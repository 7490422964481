import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { Button } from '../../component/Button';
import { InputComponent } from '../../component/InputComponent';
import { VkpApi } from '../../api/VkpApi';
import { Loader } from '../../component/Loader';
import { priceToStringWithDecimal } from '../../util/utils';
import { SwitchComponent } from '../../component/SwitchComponent';
import { InvoicePrintModal } from './component/InvoicePrintModal';
import { errorAlert } from '../../util/ErrorHandler';

export const InvoiceDetail = ({setDialog}) => {
  const vkpApi = new VkpApi();
  const { id } = useParams();
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [loading, setLoading] = useState(false);

  const [searchParam] = useState({
    invoiceNumber: id,
  });
  const [invoiceDetail, setInvoiceDetail] = useState({
    invoiceNumber: id,
    customerNumber: '',
    invoiceDate: '',
    amountSub: 0,
    amountGst: 0,
    amountInvoice: 0,
    amountBalance: 0,
    amountBased: 0,
    amountInterest: 0,
    dsMemo: '',
    dueDate: '',
    salesNumber: '',
    salesPersonKrName: '',
    isWaive: '',
    appliedYn: '',
    customerDto: {
      customerNumber: '',
      bizCode: '',
      customerBizEnName: '',
      customerBizKrName: '',

      customerKrName: '',
      customerLastName: '',
      customerFirstName: '',
      country: '',
      province: '',
      city: '',
      address: '',
      postalCode: '',
      phoneNumber: '',
      phoneExtNumber: '',
      cellPhoneNumber: '',
      faxNumber: '',
      email: '',
      sinNumber: '',
      isCredit: false,

      customerKrName2: '',
      customerLastName2: '',
      customerFirstName2: '',
      country2: '',
      province2: '',
      city2: '',
      address2: '',
      postalCode2: '',
      phoneNumber2: '',
      phoneExtNumber2: '',
      cellPhoneNumber2: '',
      faxNumber2: '',
      email2: '',
      sinNumber2: '',
      isCredit2: false,

      website: '',
      paymentType: '',
      noCredit: '',
      expireDate: '',
      cardOwnerName: '',
      printType: '',
      isEnd: false,
      isInvoice: false,
    },
    contractList: [{
      contractNumber: '',
      issuedDay: '',
      methodType: '',
      freqType: '',
      startDate: '',
      endDate: '',
      itemId: '',
      itemDisplayName: '',
      times: '',
      salesNumber: '',
      salesPersonKrName: '',
      rateAmount: 0,
      discount: 0,
      totalAmount: 0,
    }],
    statementList: [{
      rowNum: 1,
      dseq: 0,
      seq: 0,
      contractNumber: '',
      customerNumber: '',
      issuedDay: '',
      issuedDate: '',
      invoiceNumber: '',
      itemId: '',
      itemDisplayName: '',
      content: '',
      salesNumber: '',
      forwardYn: '',
      interestType: '',
      itemRateAmount: 0,
      itemDiscount: 0,
      itemSubAmount: 0,
      itemGstAmount: 0,
      itemTotalAmount: 0,
    }],
    interestList: [{
      invoiceNumber: '',
      invoiceDate: '',
      dueDate: '',
      interestType: '',
      isApplied: '',
      isWaive: '',
      invoiceAmount: 0,
      basedAmount: 0,
      interestAmount: 0,
    }],
  });

  // == state 변경 ---------------------------------------------

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }


  const handleSearch = () => {
    setLoading(true);
    vkpApi.fetchInvoiceId(
      searchParam.invoiceNumber,
      (data) => {
        setInvoiceDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handlePrintOnClick = () => {
    handleShowModal(invoiceDetail.invoiceNumber);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            {link:"/",name:"VKP 관리", isLink: false},
            {link:`/vkp/invoice`, name:"인보이스 목록", isLink: true},
            {link:`/vkp/invoice/${invoiceDetail?.invoiceNumber}`, name:`${invoiceDetail?.invoiceNumber}`, isLink: true}
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'프린트'} onClick={()=> handlePrintOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'Invoice Date'}
            id={'invoiceDate'}
            type={'text'} checkType={''}
            value={invoiceDetail?.invoiceDate}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Invoice No.'}
            id={'invoiceNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.invoiceNumber}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Due Date'}
            id={'dueDate'}
            type={'text'} checkType={''}
            value={invoiceDetail?.dueDate}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Customer #'}
            id={'customerNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerNumber}
            placeholder='' maxLength={255}
            style={{width: '8.6rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Biz Name (Eng)'}
            id={'customerBizEnName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.customerBizEnName}
            placeholder='' maxLength={255}
            style={{width: '25rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Biz Name (Kor)'}
            id={'customerBizKrName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.customerBizKrName}
            placeholder='' maxLength={255}
            style={{width: '25rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Sales Person'}
            id={'salesPersonKrName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.salesPersonKrName}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
        </InputGroup>

        <InputGroupTitle>Contact Person #1</InputGroupTitle>
        <InputGroup>
          <InputComponent
            label={'Name'}
            id={'customerKrName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.customerKrName}
            placeholder='' maxLength={255}
            style={{width: '15rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Last Name'}
            id={'customerLastName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.customerLastName}
            placeholder='' maxLength={255}
            style={{width: '15rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Fist Name'}
            id={'customerFirstName'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.customerFirstName}
            placeholder='' maxLength={255}
            style={{width: '15rem'}}
            disabled={true}
          />
          <InputComponent
            label={'SIN'}
            id={'sinNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.sinNumber}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SwitchComponent
            label={'CreditCheck'}
            id={'creditYn'}
            value={invoiceDetail?.customerDto?.creditYn}
            checkedValue={true}
            style={{width: '9.4rem'}}
          />
          <InputComponent
            label={'Country'}
            id={'country'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.country}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Province'}
            id={'province'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.province}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'City'}
            id={'city'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.city}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Address'}
            id={'address'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.address}
            placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Postal Code'}
            id={'postalCode'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.postalCode}
            placeholder='' maxLength={255}
            style={{width: '8.7rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Email'}
            id={'email'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.email}
            placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Phone'}
            id={'phoneNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.phoneNumber}
            placeholder='' maxLength={255}
            style={{width: '12rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Ext.'}
            id={'phoneExtNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.phoneExtNumber}
            placeholder='' maxLength={255}
            style={{width: '5rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Fax'}
            id={'faxNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.faxNumber}
            placeholder='' maxLength={255}
            style={{width: '12rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Cell'}
            id={'cellPhoneNumber'}
            type={'text'} checkType={''}
            value={invoiceDetail?.customerDto?.cellPhoneNumber}
            placeholder='' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
        </InputGroup>

        <InputGroupTitle>Contract Detail</InputGroupTitle>
        <OrderDetailListSection style={{height: '15rem'}}>
          <table>
            <thead>
              <tr>
                <TableTh width={100}>Contract No.</TableTh>
                <TableTh width={100}>Issued Day</TableTh>
                <TableTh width={80}>Method</TableTh>
                <TableTh width={80}>Freq.</TableTh>
                <TableTh width={80}>Start Date</TableTh>
                <TableTh width={200}>Size</TableTh>
                <TableTh width={50}>Times</TableTh>
                <TableTh width={100}>Rate</TableTh>
                <TableTh width={100}>Disc%</TableTh>
                <TableTh width={100}>Amount</TableTh>
                <TableTh width={80}>End Date</TableTh>
                <TableTh width={80}>Sales Rep.</TableTh>
              </tr>
            </thead>
            <tbody>
              {invoiceDetail?.contractList?.map((row) => (
                <tr key={row.contractNumber}>
                  <td>{row.contractNumber}</td>
                  <td>{row.issuedDay}</td>
                  <td>{row.methodType}</td>
                  <td>{row.freqType}</td>
                  <td>{row.startDate}</td>
                  <td>{row.itemDisplayName}</td>
                  <td>{row.times}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.rateAmount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.discount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.totalAmount)}</td>
                  <td>{row.endDate}</td>
                  <td>{row.salesPersonKrName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>

        <InputGroupTitle>Contract Detail</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>Seq</TableTh>
                <TableTh width={50}>Issued Day</TableTh>
                <TableTh width={100}>Issued Date</TableTh>
                <TableTh width={200}>Size</TableTh>
                <TableTh width={200}>Content</TableTh>
                <TableTh width={80}>Rate</TableTh>
                <TableTh width={80}>D/C</TableTh>
                <TableTh width={80}>Sub</TableTh>
                <TableTh width={80}>Tax</TableTh>
                <TableTh width={80}>Amount</TableTh>
              </tr>
            </thead>
            <tbody>
              {invoiceDetail?.statementList?.map((row) => (
                <tr key={row.rowNum}>
                  <td>{row.rowNum}</td>
                  <td>{row.issuedDay}</td>
                  <td>{row.issuedDate}</td>
                  <td>{row.itemDisplayName}</td>
                  <td>{row.content}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemRateAmount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemDiscount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemSubAmount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemGstAmount)}</td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemTotalAmount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>

        <InputGroupTitle>Interest</InputGroupTitle>
        <OrderDetailListSection style={{height: '10rem'}}>
          <table>
            <thead>
              <tr>
                <TableTh width={100}>Invoice No.</TableTh>
                <TableTh width={80}>Invoice Date</TableTh>
                <TableTh width={100}>Invoice Amount</TableTh>
                <TableTh width={80}>Due Date</TableTh>
                <TableTh width={200}>Option</TableTh>
                <TableTh width={100}>Based Amount</TableTh>
                <TableTh width={100}>Interest</TableTh>
                <TableTh width={100}>Waive</TableTh>
              </tr>
            </thead>
            <tbody>
              {invoiceDetail?.interestList?.map((row) => (
                <tr key={row.invoiceNumber}>
                  <td>{row.invoiceNumber}</td>
                  <td>{row.invoiceDate}</td>
                  <td style={{textAlign: 'right'}}>
                    {priceToStringWithDecimal(row.invoiceAmount)}
                  </td>
                  <td>{row.dueDate}</td>
                  <td>{row.interestType}</td>
                  <td style={{textAlign: 'right'}}>
                    {priceToStringWithDecimal(row.basedAmount)}
                  </td>
                  <td style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.interestAmount)}</td>
                  <td>{row.isWaive ? 'Y' : 'N'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
        <InputGroup>
          <InputComponent
            label={'Memo'}
            id={'dsMemo'}
            type={'text'} checkType={''}
            value={invoiceDetail?.dsMemo}
            placeholder='' maxLength={255}
            style={{width: '46.7rem'}}
            disabled={true}
          />
          <InputComponent
            label={'Sub'}
            id={'amountSub'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountSub)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
          <InputComponent
            label={'Gst'}
            id={'amountGst'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountGst)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
          <InputComponent
            label={'Invoice'}
            id={'amountInvoice'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountInvoice)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
          <InputComponent
            label={'Balance'}
            id={'amountBalance'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountBalance)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
          <InputComponent
            label={'Based'}
            id={'amountBased'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountBased)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
          <InputComponent
            label={'Interest'}
            id={'amountInterest'}
            type={'text'} checkType={''}
            value={priceToStringWithDecimal(invoiceDetail?.amountInterest)}
            placeholder='' maxLength={255}
            style={{width: '10rem', textAlign: 'right'}}
            disabled={true}
          />
        </InputGroup>
      </Section>
      <Loader loading={loading} />
      <InvoicePrintModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal}
        setDialog={setDialog}/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  padding: 1rem 0;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0rem 1.1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1.2rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 17rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
