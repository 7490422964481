import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { RentApi } from '../../../api/RentApi';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { CheckBoxComponent } from '../../../component/CheckBoxComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  ContactTypes,
  Premium,
  RealEstateCities,
  RealEstateProvinces,
  RentDetailedOptions,
  RentLeaseTerms,
  RentMovingTypes,
  RentPaymentFrequency,
  RentRoomTypes,
  RentTypes,
} from '../../../__mocks__/PostData';

export const RentDetail = ({setDialog}) => {
  const rentApi = new RentApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 5,
  });
  const [rentDetail, setRentDetail] = useState({
    id: id,
    boardId: 5,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeRentDetailData = (name, value) => {
    setRentDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeRentDetailImages = (index, imageData) => {
    setRentDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteRentDetailImages = (index) => {
    setRentDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    rentApi.fetchRentById(
      searchParam.id,
      (data) => {
        setLoading(false);
        setRentDetail(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': rentDetail.id,
      'boardId': rentDetail.boardId,
      'active': rentDetail.active,
      'password': rentDetail.password,
      'memberId': rentDetail.memberId,
      'type': rentDetail.type,
      'name': rentDetail.name,
      'email': rentDetail.email,
      'phone': rentDetail.phone,
      'contactCode': rentDetail.contactCode,
      'title': rentDetail.title,
      'description': editorRef.current.getContent(),
      'price': rentDetail.price,
      'hitCount': rentDetail.hitCount,
      'isMainDisplay': rentDetail.isMainDisplay,
      'premium': rentDetail.premium,
      'premiumStart': rentDetail.premiumStart,
      'premiumEnd': rentDetail.premiumEnd,
      'link': rentDetail.link,
      'summary': rentDetail.summary,
      'isSoldOut': rentDetail.isSoldOut,
      'locationId': rentDetail.locationId,
      'locationSubId': rentDetail.locationSubId,
      'address': rentDetail.address,
      'postal': rentDetail.postal,
      'roomType': rentDetail.roomType,
      'bedroomCount': rentDetail.bedroomCount,
      'bathroomCount': rentDetail.bathroomCount,
      'size': rentDetail.size,
      'builtIn': rentDetail.builtIn,
      'paymentFrequency': rentDetail.paymentFrequency,
      'deposit': rentDetail.deposit,
      'moveInType': rentDetail.moveInType,
      'moveInDate': rentDetail.moveInDate,
      'leaseTerm': rentDetail.leaseTerm,
      'detailedOptions': rentDetail.detailedOptions,
    };
    setLoading(true);
    rentApi.saveRent(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(rentDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', rentDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      rentApi.createRentImageFile(
        rentDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeRentDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(rentDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': rentDetail.id,
      });

      rentApi.deleteRentImageFile(
        rentDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteRentDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeRentDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeRentDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeRentDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeRentDetailData(key, true);
    else
      changeRentDetailData(key, false);
  }

  const handleMemberNumberChange = (value) => {
    changeRentDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeRentDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeRentDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeRentDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeRentDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeRentDetailData('contactCode', value);
  }

  const handleTypeOnChange = ({target}) => {
    changeRentDetailData('type', target.value);
  }

  const handleRoomTypeOnChange = ({target}) => {
    changeRentDetailData('roomType', target.value);
  }

  const handlePriceOnChange = (value) => {
    changeRentDetailData('price', value);
  }

  const handleDepositOnChange = (value) => {
    changeRentDetailData('deposit', value);
  }

  const handleSummaryOnChange = (value) => {
    changeRentDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeRentDetailData('link', value.trim())
  }

  const handleLocationIdOnChange = ({target}) => {
    changeRentDetailData('locationId', target.value);
  }

  const handleLocationSubIdOnChange = ({target}) => {
    changeRentDetailData('locationSubId', target.value);
  }

  const handleAddressOnChange = (value) => {
    changeRentDetailData('address', value.trim());
  }

  const handlePostalOnChange = (value) => {
    changeRentDetailData('postal', value.trim());
  }

  const handleBedroomCountOnChange = (value) => {
    changeRentDetailData('bedroomCount', value);
  }

  const handleBathroomCountOnChange = (value) => {
    changeRentDetailData('bathroomCount', value);
  }

  const handleBuiltInOnChange = (value) => {
    changeRentDetailData('builtIn', value);
  }

  const handleSizeOnChange = (value) => {
    changeRentDetailData('size', value);
  }

  const handlePaymentFrequencyOnChange = (value) => {
    changeRentDetailData('paymentFrequency', value)
  }

  const handleMoveInTypeOnChange = ({target}) => {
    if("moveIn-date" !== target.value) {
      changeRentDetailData('moveInDate', null);
    }
    changeRentDetailData('moveInType', target.value);
  }

  const handleMoveInDateOnChange = (value) => {
    changeRentDetailData('moveInType', "moveIn-date");
    changeRentDetailData('moveInDate', value);
  }

  const handleLeaseTermOnChange = ({target}) => {
    changeRentDetailData('leaseTerm', target.value);
  }

  const handleSoldOutOnChange = (key, value) => {
    if(value)
      changeRentDetailData(key, true);
    else
      changeRentDetailData(key, false);
  }

  const handleDetailedOptionsOnChange = (value) => {
    changeRentDetailData('detailedOptions', value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeRentDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeRentDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeRentDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeRentDetailData('title', value.trim());
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/rent`, name: "렌트", isLink: true },
            { link: `/market/rent/${searchParam?.id}`, name: `${searchParam?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={rentDetail.id} placeholder='입력' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={rentDetail?.active}
            objectData={ActiveTypeList}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={rentDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={rentDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={rentDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={rentDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={rentDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={rentDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={rentDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={rentDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={rentDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'임대인'}
            id={'name'}
            type={'text'} checkType={''}
            value={rentDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={rentDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={rentDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={rentDetail?.contactCode}
            objectData={ContactTypes}
            // infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
            onChange={handleContactCodeOnChange}
          />
          <SelectComponentWithObject
            label={'주'}
            id={'locationId'}
            value={rentDetail?.locationId}
            objectData={RealEstateProvinces}
            onChange={handleLocationIdOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'도시'}
            id={'locationSubId'}
            value={rentDetail?.locationSubId}
            objectData={RealEstateCities}
            onChange={handleLocationSubIdOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'나머지 주소'}
            id={'address'}
            type={'text'}
            checkType={''}
            value={rentDetail.address}
            placeholder='예) 331A-4501 North Road' maxLength={255}
            onChange={handleAddressOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'우편번호'}
            id={'postal'}
            type={'text'}
            checkType={''}
            value={rentDetail.postal}
            placeholder='예) V3N 4R7' maxLength={10}
            onChange={handlePostalOnChange}
            style={{width: '10rem'}}
          />
          <SelectComponentWithObject
            label={'렌트유형'}
            id={'type'}
            value={rentDetail?.type}
            objectData={RentTypes}
            onChange={handleTypeOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'룸유형'}
            id={'roomType'}
            value={rentDetail?.roomType}
            objectData={RentRoomTypes}
            onChange={handleRoomTypeOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'침실(수)'}
            id={'bedroomCount'}
            type={'number'}
            checkType={''}
            value={rentDetail.bedroomCount}
            placeholder='예) 침실 2개 -> 2' maxLength={10}
            onChange={handleBedroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'화장실(수)'}
            id={'bathroomCount'}
            type={'number'}
            checkType={''}
            value={rentDetail.bathroomCount}
            placeholder='예) 화장실 2개 -> 2' maxLength={10}
            onChange={handleBathroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'건축년도'}
            id={'builtIn'}
            type={'number'}
            checkType={''}
            value={rentDetail.builtIn}
            placeholder='년' maxLength={10}
            onChange={handleBuiltInOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'전용면적'}
            id={'size'}
            type={'number'}
            checkType={''}
            value={rentDetail.size}
            placeholder='명' maxLength={10}
            onChange={handleSizeOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <RadioComponents
            label={'지불기준'}
            id={'paymentFrequency'}
            value={rentDetail?.paymentFrequency}
            objectData={RentPaymentFrequency}
            onChange={handlePaymentFrequencyOnChange}
          />
          <InputComponent
            label={'가격'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={rentDetail.price} placeholder='입력' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'디파짓'}
            id={'deposit'}
            type={'number'}
            checkType={'positiveNumber'}
            value={rentDetail.deposit} placeholder='' maxLength={20}
            onChange={handleDepositOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />

          <SelectComponentWithObject
            label={'입주유형'}
            id={'moveInType'}
            value={rentDetail?.moveInType}
            objectData={RentMovingTypes}
            onChange={handleMoveInTypeOnChange}
            style={{width: '12rem'}}
          />
          <CalendarComponent
            label={'입주날짜 '}
            id={'moveInDate'}
            dateFormat={'yyyy-MM-dd'}
            placeholderText={'yyyy-MM-dd'}
            value={rentDetail.moveInDate}
            onChange={handleMoveInDateOnChange}
            isClearable={true}
            isShowTimeSelect={false}
            width={'13rem'}
          />
          <SelectComponentWithObject
            label={'계약기간'}
            id={'leaseTerm'}
            value={rentDetail?.leaseTerm}
            objectData={RentLeaseTerms}
            onChange={handleLeaseTermOnChange}
            style={{width: '12rem'}}
          />
          <SwitchComponent
            label={'Sold Out'}
            id={'isSoldOut'}
            value={rentDetail?.isSoldOut}
            checkedValue={true}
            onChange={handleSoldOutOnChange}
            style={{width: '9.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <CheckBoxComponent
            label={'상세옵션'}
            id='detailedOptions'
            value={rentDetail?.detailedOptions}
            delimiter={''}
            objectData={RentDetailedOptions}
            onChange={handleDetailedOptionsOnChange}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'상품명'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={rentDetail.title}
            placeholder='입력' maxLength={255}
            style={{width: '90rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={rentDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={rentDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
