import React from 'react';
import ReactLoading from 'react-loading'
import styled from 'styled-components';

export const Loader = ({loading}) => {
  const LOADING_MESSAGE = 'Loading...'
  return (
    <>
      { loading &&
        (
          <LoaderWrapper>
            <LoaderBox>
              <div className='message'>{LOADING_MESSAGE}</div>
              <ReactLoading type={"spin"} color={"red"} height={'80%'} width={'100%'} />
            </LoaderBox>
          </LoaderWrapper>
        )
      }
    </>
  )
};

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
`;
const LoaderBox = styled.div`
  position: fixed;
  width: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & div.message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1em;
    color: #ddd;
    text-shadow: 3px 3px 6px #000;
  }
`;