import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { UserApi } from '../../api/UserApi';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { SelectComponentWithObject } from '../../component/SelectComponentWithObject';
import { Pagination } from '../../component/Pagination';
import { Button } from '../../component/Button';
import { Loader } from '../../component/Loader';
import { BlockIPModal } from './component/BlockIPModal';
import { errorAlert } from '../../util/ErrorHandler';
import {
  BlockIPType,
  ListSize,
} from '../../__mocks__/PostData';

export const BlockIPList = () => {
  const SAVED_SEARCH_NAME = "blockIPList_search";
  const userApi = new UserApi();
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(()=>{
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          ip: '',
          memo: '',
          type: 'news',
          page: 1,
          listSize: 100,
          perPageSize: 15,
          totalCount: 0,
          totalPageCount: 0,
        }
      }
    }
  });
  const [result, setResult] = useState(null);

  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      ip: '',
      memo: '',
      type: 'news',
      page: 1,
      listSize: 100,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam('page', 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    userApi.fetchBlockIPList(
      searchParam,
      (data) => {
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleCreateOnClick = () => {
    handleShowModal(0);
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIpOnChange = (value) => {
    changeSearchParam?.('ip', value);
  }
  const handleMemoOnChange = (value) => {
    changeSearchParam?.('memo', value);
  }
  const handleTypeOnChange = ({target}) => {
    changeSearchParam("type" , target.value);
  }
  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }
  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleDetailOnClick = (id) => {
    saveSearchData();
    handleShowModal(id);
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleModalClose = () => {
    handleSearch();
    setModalInfo({
      id: '',
      show: false
    });
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }

  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "회원 관리", isLink: false },
            { link: "/block-ip", name: "차단 IP관리", isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'신규'} onClick={()=> handleCreateOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'IP'}
              id={'ip'}
              type={'text'} checkType={''}
              value={searchParam.ip}
              placeholder='IP 검색' maxLength={255}
              onChange={handleIpOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'Memo'}
              id={'memo'}
              type={'text'} checkType={''}
              value={searchParam.memo}
              placeholder='메모 검색' maxLength={255}
              onChange={handleMemoOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '35rem'}}
            />
            <SelectComponentWithObject label={'Type'}
              id={'type'}
              value={searchParam.type}
              objectData={BlockIPType}
              onChange={handleTypeOnChange}
              style={{width: '15rem'}}
              />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={ListSize}
              onChange={handleListSizeOnChange}
              style={{width: '10rem'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>

      <UserListSection>
        <table>
          <thead>
            <tr>
              <th style={{width: '8rem'}}>#</th>
              <th style={{width: '9rem'}}>ID</th>
              <th style={{width: '12rem'}}>Type</th>
              <th style={{width: '15rem'}}>IP</th>
              <th>메모</th>
              <th style={{width: '14rem'}}>
                등록일시
              </th>
            </tr>
          </thead>
          <tbody>
          {0 < result?.resultList?.length ? (
            <>
              {result?.resultList.map((row) => (
                <tr key={row.id} onDoubleClick={() => handleDetailOnClick(row.id)}>
                  <td >{row.rowNum}</td>
                  <td >{row.id}</td>
                  <td>
                    {BlockIPType[row.type]?.label}
                  </td>
                  <td>
                    <input
                      id={`ip${row.ip}`}
                      type={'text'}
                      value={row.ip}
                      disabled
                      style={{paddingLeft: '0.2rem', width: '98%', height: '2.3rem', border: 0}}
                    />
                  </td>
                  <td>
                    <input
                      id={`memo${row.id}`}
                      type={'text'}
                      value={row.memo}
                      disabled
                      style={{width: '98%', height: '2.3rem', border: 0}}
                    />
                  </td>
                  <td>
                    {row.registrationDate}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='6'>
                데이터가 없습니다.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </UserListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <BlockIPModal
        id={modalInfo.id}
        type={searchParam.type}
        show={modalInfo.show}
        onClose={handleModalClose} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const UserListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        /* height: 3.4rem; */
        cursor: pointer;
        & td {
          padding: 0 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
