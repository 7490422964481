import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { menuData } from '../__mocks__/PostData';

export const Menu = ({toggleMenu, show}) => {
  const navigate = useNavigate();
  const [menus] = useState(menuData);
  const [subMenu, setSubMenu] = useState({
    'menu':{isOpen: false},
    'member':{isOpen: false},
    'ad': {isOpen: false},
    'news-content': {isOpen: false},
    'market-content': {isOpen: false},
    'realestate-content': {isOpen: false},
    'community-content': {isOpen: false},
    'static': {isOpen: false},
    'sales': {isOpen: false},
    'vkp': {isOpen: false},
  });

  const changeSubMenu = (id, name, value) => {
    setSubMenu((subMenu) => {
      const updated = {...subMenu};
      updated[id][name] = value;
      return updated;
    });
  };

  const handleToggleMenu = () => {
    toggleMenu?.();
    Object.keys(subMenu).map((sub)=> changeSubMenu(sub,"isOpen", false));
  }

  const handleOnSubMenuClick = (id, value) => {
    if(!show) {
      toggleMenu?.();
    }
    changeSubMenu(id, "isOpen", !value);
  }

  const handlePageMoveOnClick = (url) => {
    navigate(url);
  }

  return (
    <>
      <MenuOverlay className={`${show ? "show" : null}`}>
        <NavSection>
          <div className="nav-header">
            <div className="nav-title">Navigation</div>
            <div className="menu-btn" onClick={()=> handleToggleMenu()}>
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
          </div>
          {Object.keys(menus).map((key) => (
            <div key={key} className={`${show ? "nav-category show" : "nav-category"}`}>
              <div className={`category-lv1`} onClick={()=>handleOnSubMenuClick(key, subMenu[key].isOpen)}>
                <MenuIcon className={key}/>
                <div className={`name`}>{menus[key].name}</div>
                <i className={`${subMenu[key].isOpen? "upArrow": "downArrow"}`} />
              </div>
              <ul className={`${subMenu[key].isOpen? "category-lv2 sub-active": "category-lv2"}`}>
                {menus[key].subMenu?.map((sub) => (
                  <li key={sub.id}>
                    <div onClick={() => handlePageMoveOnClick(sub.link)}>{sub.name}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </NavSection>
      </MenuOverlay>
    </>
  );
};

const MenuOverlay = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  height: 100vh;
  width: 55px;
  background-color: #ddd;
  transition: all 500ms ease-in-out;
  overflow: none;
  z-index: 1;
  & div.nav-header {
    display: flex;
    & div.nav-title {
      display: none;
      width: 85%;
    }
    & div.menu-btn {
      margin: 7px;
      width: 40px;
      cursor: pointer;
      & div.line {
        width: 28px;
        height: 4px;
        margin: 5px;
        background-color: #676868;
      }
    }
  }

  &.show {
    width: 300px;
    background-color: #fff;
    transform: translateX(0%);
    transition: all 500ms ease-in-out;
    & div.nav-header {
      transition: all 500ms ease-in-out;
      & div.nav-title {
        display: block;
      }
    }
  }
`;

const NavSection = styled.section`
  margin: auto;
  border-top: 3px solid #b92324;
  & div.nav-title {
    padding: 10px 20px;
    background-color: #f8f8f8;
  }
  & div.nav-search {
    position: relative;
    background-color: #f8f8f8;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    height: 41px;
    padding: 6px 0px 0px 5px;

    background: #fff;
    box-sizing: border-box;
    & input {
      width: 100%;
      border: 0;
      font-size: 1em;
      border-bottom: 5px solid #b92324;
      border-radius: 5px;
    }
    & input:focus {
      background-color: #ddd;
      outline: none;
    }
    & button {
      width: 26px;
      height: 26px;
      background: url(//www.vanchosun.com/m/images/btn_search.png) no-repeat 0 0;
      background-position: center;
      text-indent: -999em;
      white-space: nowrap;
      border: 0;
      position: absolute;
      right: 0px;
      top: 9px
    }
  }
  & a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  & div.nav-category {
    position: relative;
    padding: 5px 10px;
    & div.category-lv1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      & div.name {
        display: none;
        margin: 0px 10px;
        width: 195px;
        font-size: 1.8rem;
        color: #000;
        animation: animateright 0.4s;
      }
      & i {
        display: none;
      }
    }
    & ul {
      display: none;
      margin: auto;
      padding: 10px 45px;
      background: #f5f5f5;
      list-style-type: none;
      & li {
        margin: 0;
        width: 225px;
        & div {
          display: block;
          padding: 9px 0;
          cursor: pointer;
        }
      }
    }
  }

  & div.show {
    & div.category-lv1 {
      & div.name {
        display: block;
        animation: animateleft 0.4s;
      }
      & i {
        display: block;
      }
    }
  }

  & ul.sub-active {
    display: block !important;
    transition: all 500ms ease-in-out;
  }
`;

const MenuIcon = styled.div`
  display: inline-block;
  height: 30px;
  width: 30px;
  background-color: #ddd;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  cursor: pointer;
  background-image: url('/icon/menu-icons.png');
  background-repeat: no-repeat;
  background-position: -121px -159px;
  background-size: 200px;
  &.member {
    background-position: -85px -49px;
  }
  &.ad {
    background-position: -48px -12px;
  }
  &.community {
    background-position: -48px -85px;
  }
  &.static {
    background-position: -157px -159px;
  }
  &.sales {
    background-position: -85px -12px;
  }
`;