import React from 'react';
import styled from 'styled-components';

export const Button = ({type, value, onClick, width, disabled=false, style}) => {
  return (
    <ButtonStyle type={type} onClick={onClick} width={width} style={style} disabled={disabled}>
      {value}
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  height: 3.4rem;
  width:  ${({ width }) => width?width:'12rem'};
  border: 0rem solid ${({ theme }) => theme.colors.borderFocus};
  border-radius: 0.6rem;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;