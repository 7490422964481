import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { UserApi } from '../../api/UserApi';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { SelectComponentWithObject } from '../../component/SelectComponentWithObject';
import { Pagination } from '../../component/Pagination';
import { Loader } from '../../component/Loader';
import { Button } from '../../component/Button';
import { ExpertProfileModal } from './component/ExpertProfileModal';
import { errorAlert } from '../../util/ErrorHandler';
import {
  ActiveTypeList,
  ExportCategories,
  ExportCategoryList,
  ListSize,
} from '../../__mocks__/PostData';

export const ExpertProfileList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "expertProfileList_search";
  const userApi = new UserApi();
  const [selectListSize] = useState(ListSize);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(()=>{
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          id: '',
          name: '',
          company: '',
          title: '',
          email: '',
          type: '',
          active: 1,
          page: 1,
          listSize: 50,
          perPageSize: 15,
          totalCount: 0,
          totalPageCount: 0,
        }
      }
    }
  });
  const [result, setResult] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      id: '',
      name: '',
      company: '',
      title: '',
      email: '',
      type: '',
      active: 1,
      page: 1,
      listSize: 100,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam("page", 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    userApi.fetchExpertList(
      searchParam,
      (data) => {
        setLoading(false);
        setResult(null);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }
  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }
  const handleNameOnChange = (value) => {
    changeSearchParam?.('name', value);
  }
  const handleCompanyOnChange = (value) => {
    changeSearchParam?.('company', value);
  }
  const handleEmailOnChange = (value) => {
    changeSearchParam?.('email', value);
  }
  const handleTitleOnChange = (value) => {
    changeSearchParam?.('title', value);
  }
  const handleTypeOnChange = (e) => {
    changeSearchParam("type" , e.target.value);
  }
  const handleActiveOnChange = (e) => {
    changeSearchParam("active" , e.target.value);
  }
  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }
  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleUserOnClick = (profileId) => {
    saveSearchData();
    handleShowModal(profileId);
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  const handleShowModal = (profileId) => {
    setModalInfo({
      id: profileId,
      show: true
    });
  }

  const handleUserModalClose = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            {link:"/",name:"회원 관리",isLink:false},
            {link:"/expert",name:"전문가",isLink:true}
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'#'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam.id}
              placeholder='# 검색' maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent label={'Name'}
              id={'name'}
              type={'text'} checkType={''}
              value={searchParam.name}
              placeholder='이름 검색' maxLength={255}
              onChange={handleNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'Company'}
              id={'company'}
              type={'text'} checkType={''}
              value={searchParam.company}
              placeholder='소속 검색' maxLength={255}
              onChange={handleCompanyOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'Title'}
              id={'title'}
              type={'text'} checkType={''}
              value={searchParam.title}
              placeholder='Title 검색' maxLength={255}
              onChange={handleTitleOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent label={'E-Mail'}
              id={'email'}
              type={'text'} checkType={''}
              value={searchParam.email}
              placeholder='Email 검색' maxLength={255}
              onChange={handleEmailOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <SelectComponentWithObject label={'Type'}
              id={'type'}
              value={searchParam?.type}
              objectData={[{ value: '', boardId: '', label: '전체' }, ...ExportCategoryList]}
              onChange={handleTypeOnChange}
              style={{width: '10rem'}}
            />
            <SelectComponentWithObject label={'Active'}
              id={'active'}
              value={searchParam.active}
              objectData={[{ value: '', label: '전체' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={selectListSize}
              onChange={handleListSizeOnChange}
              style={{width: '100px'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <UserListSection>
        <table>
          <thead>
            <tr>
              <th style={{width: '10rem'}}>#</th>
              <th style={{width: '8rem'}}>사진</th>
              <th style={{width: '15rem'}}>전문영역</th>
              <th>Title</th>
              <th>이름</th>
              <th>회원정보</th>
              <th style={{width: '22rem'}}>소속</th>
              <th style={{width: '14rem'}}>답변수</th>
            </tr>
          </thead>
          <tbody>
          {0 < result?.resultList?.length ? (
            <>
              {result?.resultList.map((row) => (
                <tr key={row.id} onDoubleClick={() => handleUserOnClick(row.id)}>
                  <td>
                    <input id={"id"}
                      type={'text'}
                      value={row?.id}
                      style={{width: '98%', height: '2.3rem', textAlign: 'center', border: 0, backgroundColor: 'transparent'}}
                      disabled
                    />
                  </td>
                  <td style={{padding: 0}}>
                    {row?.imageFile1 && (
                      <ImageBox>
                        <img src={`${row.imageFile1}`} alt={row.title} loading="lazy"/>
                      </ImageBox>
                    )}
                  </td>
                  <td>{ExportCategories[row.type]?.label}</td>
                  <td>
                    {row.subTitle}
                  </td>
                  <td style={{textAlign: 'left'}}>
                    User: {row.name}
                    <br/>
                    {row?.title && `Profile: ${row?.title}`}
                  </td>
                  <td>
                    <input id={"memberId"}
                      type={'text'}
                      value={row?.memberId}
                      style={{width: '98%', height: '2.3rem', textAlign: 'center', border: 0, backgroundColor: 'transparent'}}
                      disabled
                    />
                    <input id={"memberSignId"}
                      type={'text'}
                      value={row?.memberSignId}
                      style={{width: '98%', height: '2.3rem', textAlign: 'center', border: 0, backgroundColor: 'transparent'}}
                      disabled
                    />
                    <br/>
                    {row?.memberName}
                  </td>
                  <td style={{textAlign: 'left'}}>
                    {row.company}
                  </td>
                  <td style={{paddingLeft: '1rem', textAlign: 'left'}}>
                    최근 일주일 답변수: 0
                    <br/>
                    전체 답변수: 0
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='8'>
                데이터가 없습니다.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </UserListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <ExpertProfileModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleUserModalClose}
      />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const UserListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const ImageBox = styled.div`
  width: 8rem;
  height: 8rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;