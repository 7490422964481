import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const EditorComponent = ({editorRef, description, width='100%', height='300px', disabled}) => {
  return (
    <>
      <Editor tinymceScriptSrc={process.env.PUBLIC_URL +'/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={description}
        disabled={disabled}
        init={{
          width: width,
          height: height,
          menubar: false,
          plugins: 'preview paste importcss searchreplace autolink visualblocks visualchars fullscreen media image link table charmap hr pagebreak nonbreaking insertdatetime advlist lists code wordcount textpattern noneditable help charmap emoticons',
          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile media image table link | fullscreen preview help |',
          content_style: 'body { font-family: Helvetica,Arial,sans-serif; font-size: 14px }',
        }}
      />
    </>
  );
}
