import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { AdApi } from '../../api/AdApi';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { Button } from '../../component/Button';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { CalendarComponent } from '../../component/CalendarComponent';
import { Pagination } from '../../component/Pagination';
import { Loader } from '../../component/Loader';
import { numberToString } from '../../util/utils';
import { errorAlert } from '../../util/ErrorHandler';
import { CommunityAdModal } from './component/CommunityAdModal';
import {
  ActiveTypeList,
  ListSize,
  CommunityAdTypeList,
  CommunityAdPositionLV1List,
  CommunityAdPositionLV2List,
  CommunityAdPositionLV1,
  CommunityAdPositionLV2,
  CommunityAdType,
} from '../../__mocks__/PostData';

export const CommunityAdList = ({setDialog}) => {
  const adApi = new AdApi();

  const [selectListSize] = useState(ListSize);
  const [loading, setLoading] = useState(false);
  const initSearchParamObject = {
    id: '',
    activeCode: '',
    activeName: '',
    type: 'community',
    positionLv1: '',
    positionLv2: '',
    positionLv3: '',
    name: '',
    title: '',
    adStartDate: '',
    adEndDate: '',
    page: 1,
    listSize: 100,
    perPageSize: 15,
    totalCount: 0,
    totalPageCount: 0,
  }
  const [searchParam, setSearchParam] = useState({
    ...initSearchParamObject
  });
  const [result, setResult] = useState({resultList:[]});
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      ...initSearchParamObject
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam("page", 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    adApi.fetchCommunityAdList(
      searchParam,
      (data) => {
        setLoading(false);
        setResult(null);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleCreateOnClick = () => {
    handleShowModal(0);
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }
  const handleTypeOnChange = (e) => {
    changeSearchParam?.('type', e.target.value);
  }
  const handlePositionLv1OnChange = (e) => {
    changeSearchParam?.('positionLv1', e.target.value);
  }
  const handlePositionLv2OnChange = (e) => {
    changeSearchParam?.('positionLv2', e.target.value);
  }
  const handlePositionLv3OnChange = (value) => {
    changeSearchParam?.('positionLv3', value);
  }
  const handleNameOnChange = (value) => {
    changeSearchParam?.('name', value);
  }
  const handleTitleOnChange = (value) => {
    changeSearchParam?.('title', value);
  }
  const handleActiveOnChange = (e) => {
    changeSearchParam?.('activeCode', e.target.value);
  }
  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }
  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }
  const handleAdEndDateOnChange = (value) => {
    changeSearchParam?.('adEndDate', value);
  }

  const handleRowOnClick = (id) => {
    handleShowModal(id);
  }
  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  useEffect(() => {
    handleSearch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "광고 관리", isLink: false },
            { link: "/ad-community", name: "커뮤니티 AD", isLink: false }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'신규'} onClick={()=> handleCreateOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'ID'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam.id}
              placeholder='ID 입력' min={0} maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'종류'}
              id={'type'}
              value={searchParam.type}
              objectData={CommunityAdTypeList}
              onChange={handleTypeOnChange}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'lv1'}
              id={'lv1'}
              value={searchParam.positionLv1}
              objectData={[{ value: '', label: '전체'}, ...CommunityAdPositionLV1List]}
              onChange={handlePositionLv1OnChange}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'lv2'}
              id={'lv2'}
              value={searchParam.positionLv2}
              objectData={[{ value: '', label: '전체'}, ...CommunityAdPositionLV2List]}
              onChange={handlePositionLv2OnChange}
              style={{width: '10rem'}}
            />
            <InputComponent
              label={'lv3'}
              id={'lv3'}
              type={'number'} checkType={''}
              value={searchParam.positionLv3}
              placeholder={''} maxLength={255}
              onChange={handlePositionLv3OnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent
              label={'광고주 이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={searchParam.name}
              placeholder={''} maxLength={255}
              onChange={handleNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent
              label={'광고 제목'}
              id={'title'}
              type={'text'} checkType={''}
              value={searchParam.title}
              placeholder={''} maxLength={255}
              onChange={handleTitleOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <CalendarComponent label={'광고종료'}
              id={'adEndDate'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={searchParam?.adEndDate}
              onChange={handleAdEndDateOnChange}
              isClearable={true}
            />
            <SelectComponents label={'광고 상태'}
              id={'activeCode'}
              value={searchParam?.activeCode}
              objectData={[{value: '', label: '전체'}, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={selectListSize}
              onChange={handleListSizeOnChange}
              style={{width: '8rem'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <UserListSection>
        <table>
          <thead>
            <tr>
              <th>광고번호</th>
              <th>이미지</th>
              <th>제목</th>
              <th>Type</th>
              <th>LV1</th>
              <th>LV2</th>
              <th>이름</th>
              <th>링크</th>
              <th>클릭수</th>
              <th>상태</th>
              <th style={{'width': '16rem'}}>시작/종료/등록일</th>
            </tr>
          </thead>
          <tbody>
          {0 < result?.resultList?.length ? (
            <>
              {result?.resultList.map((row) =>(
                <tr key={row.id}
                  className={
                    (new Date(row.endDate) > new Date()) && row.active
                    ? 'lived'
                    : ''
                  }
                  onDoubleClick={() => handleRowOnClick(row.id)}
                >
                  <td>{row.id}</td>
                  <td>
                    {row.imageFileUrl1 && (
                      <ImageBox>
                        <div>
                          <img src={`${row.imageFileUrl1}`} alt={row.title} loading="lazy"/>
                        </div>
                      </ImageBox>
                    )}
                  </td>
                  <td>{row.title}</td>
                  <td>
                    {CommunityAdType[row?.type]?.label}
                  </td>
                  <td>
                    {CommunityAdPositionLV1[row?.positionLv1]?.label}
                  </td>
                  <td>
                    {CommunityAdPositionLV2[row?.positionLv2]?.label}
                  </td>
                  <td>{row?.name}</td>
                  <td>{row?.link?'외부링크':''}</td>
                  <td>{numberToString(row?.hit)}</td>
                  <td>{row?.activeName}</td>
                  <td>
                    {row?.startDate && `${row.startDate?.substr(0, 10)} ${row.startDate?.substr(11, 8)}`}<br/>
                    {row?.endDate && `${row.endDate?.substr(0, 10)} ${row.endDate?.substr(11, 8)}`}<br/>
                    {row?.postDate && `${row.postDate?.substr(0, 10)} ${row.postDate?.substr(11, 8)}`}<br/>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='11'>
                데이터가 없습니다.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </UserListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <CommunityAdModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal}
        setDialog={setDialog}/>
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const UserListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr.lived {
        color: #FFAB2D;
        background-color: #fff;
      }
    }
  }
`;

const ImageBox = styled.div`
  max-width: 100px;
  & div {
    max-height: 80px;
    border: 1px solid green;
    & img {
      width: 100%;
      max-height: 80px;
      object-fit: contain;
    }
  }
  & div:nth-child(2) {
    border: 1px solid red;
  }
`;
