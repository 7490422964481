import React from 'react';
import styled from 'styled-components';

export const TextareaComponent = ({ label, id, value, defaultValue='', placeholder='', rows=1, cols=10, infoMessage, maxLength, onChange, readOnly, disabled
  , width, height, style
}) => {

  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <TextareaGroup width={width} height={height} style={style}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        onChange={(e) => handleOnChange(e.target.value)}
        autoComplete="off"
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
      />
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </TextareaGroup>
  );
}

const TextareaGroup = styled.div`
  position: relative;
  margin: 0;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  width: ${({ width }) => width ? width : '10rem'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  & label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
  & textarea {
    display: block;
    padding: 0rem 0.5rem;
    width: 100%;
    height: ${({ height }) => height ? height : '10rem'};
    font-size: 1.6rem;
    border: 0rem solid #ddd;
    border-radius: 0.5rem;
    box-sizing: border-box;
  }
  & textarea:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
`;

const InfoMessage = styled.div`
  margin: 0.1rem 0.5rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;
