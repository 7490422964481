import React from 'react';
import styled from 'styled-components';

export const SelectComponentsForCategory = ({ label, id, value, objectData, onChange, disabled, style}) => {

  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <SelectGroup>
      <label htmlFor={id}>{label}</label>
      <select className="form-select"
        id={id}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        style={style}
      >
        { objectData?.map((key) =>
          <option key={key.id} value={key.id}>{key.name}</option>
        )}
      </select>
    </SelectGroup>
  );
}

const SelectGroup = styled.div`
  position: relative;
  margin: 0.7rem 0.7rem;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  & label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
  & select {
    position: relative;
    padding: 0rem 0.5rem;
    height: 3rem;
    width: 100%;
    border: 0.1rem solid #ddd;
    border-radius: 0.5rem;
    font-size: 0.9em;
    border: 0rem solid #ddd;
    border-radius: 0.5rem;
    box-sizing: border-box;

    & option {
      font-size: 1.2em;
    }
  }
  & select:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
`;
