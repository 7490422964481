import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { PrivateRoute } from "./routes/PrivateRoute";
import { theme } from "./theme";
import { Header } from "./common/Header";
import { Menu } from "./common/Menu";
import { Dialog } from "./common/Dialog";
import { Modal } from "./component/Modal";
import { User } from "./domain/user/User";
import { Home } from "./domain/Home";
import { UserList } from "./domain/user/UserList";
import { RealtorList } from "./domain/user/RealtorList";
import { CarDealerList } from "./domain/user/CarDealerList";
import { NewsAdList } from "./domain/ad/NewsAdList";
import { MarketAdList } from "./domain/ad/MarketAdList";
import { EduAdList } from "./domain/ad/EduAdList";
import { SignIn } from "./domain/SignIn";
import { OrderList } from "./domain/order/OrderList";
import { BuysellList } from "./domain/market/buysell/BuysellList";
import { BuysellDetail } from "./domain/market/buysell/BuysellDetail";
import { JobList } from "./domain/market/job/JobList";
import { JobDetail } from "./domain/market/job/JobDetail";
import { TutorList } from "./domain/market/tutor/TutorList";
import { TutorDetail } from "./domain/market/tutor/TutorDetail";
import { HomestayList } from "./domain/market/homestay/HomestayList";
import { HomestayDetail } from "./domain/market/homestay/HomestayDetail";
import { RentList } from "./domain/market/rent/RentList";
import { RentDetail } from "./domain/market/rent/RentDetail";
import { CarList } from "./domain/market/car/CarList";
import { CarDetail } from "./domain/market/car/CarDetail";
import { DwellingList } from "./domain/market/dwelling/DwellingList";
import { DwellingDetail } from "./domain/market/dwelling/DwellingDetail";
import { CommercialList } from "./domain/market/commercial/CommercialList";
import { CommercialDetail } from "./domain/market/commercial/CommercialDetail";
import { OpenhouseList } from "./domain/market/openhouse/OpenhouseList";
import { OpenhouseDetail } from "./domain/market/openhouse/OpenhouseDetail";
import { OrderForWebList } from "./domain/order/OrderForWebList";
import { InvoiceList } from "./domain/vkp/InvoiceList";
import { InvoiceDetail } from "./domain/vkp/InvoiceDetail";
import { BlockIPList } from "./domain/user/BlockIPList";
import { ExpertProfileList } from "./domain/user/ExpertProfileList";
import { CommunityAdList } from "./domain/ad/CommunityAdList";
import { YellowpageAdList } from "./domain/ad/YellowpageAdList";
import { NewsCategoryList } from "./domain/news/NewsCategoryList";
import { NewsSubCategoryList } from "./domain/news/NewsSubCategoryList";
import { CommunityBoardList } from "./domain/community/CommunityBoardList";

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [dialog, setDialog] = useState({isOpen: false, message: "", type: "alert"});
  const [showModal, setShowModal] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const toggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  }

  const closeProfileMenu = () => {
    setOpenProfileMenu(false);
  }

  const handleDialogClose = () => {
    setDialog((values) => {
      const updated = {...values};
      updated['isOpen'] = false;
      return updated;
    });
    return 0;
  }

  // const handleShowModal = () => {
  //   setShowModal(true);
  // }

  const handleModalClose = () => {
    setShowModal(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RootContainer>
          <Header openProfileMenu={openProfileMenu} toggleProfileMenu={toggleProfileMenu} closeProfileMenu={closeProfileMenu} />
          <Menu toggleMenu={toggleMenu} show={showMenu} />
          <Dialog
            isOpen={dialog.isOpen}
            handleClose={handleDialogClose}
            message={dialog.message}
            type={dialog.type} />
          <Main showMenu={showMenu}>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/signIn"
                element={
                  <SignIn setDialog={setDialog}/>
                } restricted={false}
              />
              <Route path="/user" element={
                <PrivateRoute>
                  <UserList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/user/:id" element={
                <PrivateRoute>
                  <User setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/realtor" element={
                <PrivateRoute>
                  <RealtorList setDialog={setDialog}/>
                </PrivateRoute>}
                exact
              />
              <Route path="/carDealer" element={
                <PrivateRoute>
                  <CarDealerList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/expert" element={
                <PrivateRoute>
                  <ExpertProfileList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/block-ip" element={
                <PrivateRoute>
                  <BlockIPList setDialog={setDialog}/>
                </PrivateRoute>}
                exact
              />
              <Route path="/ad-news" element={
                <PrivateRoute>
                  <NewsAdList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/ad-market" element={
                <PrivateRoute>
                  <MarketAdList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/ad-edu" element={
                <PrivateRoute>
                  <EduAdList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/ad-community" element={
                <PrivateRoute>
                  <CommunityAdList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/ad-yellowpage" element={
                <PrivateRoute>
                  <YellowpageAdList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/news/category" element={
                <PrivateRoute>
                  <NewsCategoryList />
                </PrivateRoute>}
              />
              <Route path="/news/sub-category" element={
                <PrivateRoute>
                  <NewsSubCategoryList />
                </PrivateRoute>}
              />
              <Route path="/community/community/board" element={
                <PrivateRoute>
                  <CommunityBoardList />
                </PrivateRoute>}
              />
              <Route path="/market/buysell" element={
                <PrivateRoute>
                  <BuysellList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/buysell/:id" element={
                <PrivateRoute>
                  <BuysellDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/job" element={
                <PrivateRoute>
                  <JobList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/job/:id" element={
                <PrivateRoute>
                  <JobDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/tutor" element={
                <PrivateRoute>
                  <TutorList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/tutor/:id" element={
                <PrivateRoute>
                  <TutorDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/car" element={
                <PrivateRoute>
                  <CarList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/car/:id" element={
                <PrivateRoute>
                  <CarDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/homestay" element={
                <PrivateRoute>
                  <HomestayList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/homestay/:id" element={
                <PrivateRoute>
                  <HomestayDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/rent" element={
                <PrivateRoute>
                  <RentList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/rent/:id" element={
                <PrivateRoute>
                  <RentDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/dwelling" element={
                <PrivateRoute>
                  <DwellingList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/dwelling/:id" element={
                <PrivateRoute>
                  <DwellingDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/commercial" element={
                <PrivateRoute>
                  <CommercialList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/commercial/:id" element={
                <PrivateRoute>
                  <CommercialDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/openhouse" element={
                <PrivateRoute>
                  <OpenhouseList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/market/openhouse/:id" element={
                <PrivateRoute>
                  <OpenhouseDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/sales/order" element={
                <PrivateRoute>
                  <OrderList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/sales/order-old" element={
                <PrivateRoute>
                  <OrderForWebList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/vkp/invoice" element={
                <PrivateRoute>
                  <InvoiceList setDialog={setDialog}/>
                </PrivateRoute>}
              />
              <Route path="/vkp/invoice/:id" element={
                <PrivateRoute>
                  <InvoiceDetail setDialog={setDialog}/>
                </PrivateRoute>}
              />
            </Routes>
          </Main>
          <Modal show={showModal} onClose={handleModalClose} />
        </RootContainer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const RootContainer = styled.div`
  position: relative;
`;

const Main = styled.main`
  margin-left: ${props => props.showMenu ? '300px' : '55px'};
  min-width: 100rem;
  transition: all 500ms ease-in-out;
`;

export default App;
