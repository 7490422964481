import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { OpenhouseApi } from '../../../api/OpenhouseApi';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  ContactTypes,
  OpenhouseSpaceUnits,
  OpenhouseTypes,
  Premium,
  RealEstateCities,
  RealEstateProvinces,
} from '../../../__mocks__/PostData';

export const OpenhouseDetail = ({setDialog}) => {
  const openhouseApi = new OpenhouseApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 13,
  });
  const [openhouseDetail, setOpenhouseDetail] = useState({
    id: id,
    boardId: 13,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeOpenhouseDetailData = (name, value) => {
    setOpenhouseDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeOpenhouseDetailImages = (index, imageData) => {
    setOpenhouseDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteOpenhouseDetailImages = (index) => {
    setOpenhouseDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // == handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    openhouseApi.fetchOpenhouseById(
      searchParam.id,
      (data) => {
        setLoading(false);
        setOpenhouseDetail(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': openhouseDetail.id,
      'boardId': openhouseDetail.boardId,
      'active': openhouseDetail.active,
      'password': openhouseDetail.password,
      'memberId': openhouseDetail.memberId,
      'type': openhouseDetail.type,
      'name': openhouseDetail.name,
      'email': openhouseDetail.email,
      'phone': openhouseDetail.phone,
      'contactCode': openhouseDetail.contactCode,
      'title': openhouseDetail.title,
      'description': editorRef.current.getContent(),
      'price': openhouseDetail.price,
      'hitCount': openhouseDetail.hitCount,
      'isMainDisplay': openhouseDetail.isMainDisplay,
      'premium': openhouseDetail.premium,
      'premiumStart': openhouseDetail.premiumStart,
      'premiumEnd': openhouseDetail.premiumEnd,
      'link': openhouseDetail.link,
      'summary': openhouseDetail.summary,
      'isSoldOut': openhouseDetail.isSoldOut,
      'locationId': openhouseDetail.locationId,
      'locationSubId': openhouseDetail.locationSubId,
      'address': openhouseDetail.address,
      'postal': openhouseDetail.postal,

      'flagType': openhouseDetail.flagType,
      'bedroomCount': openhouseDetail.bedroomCount,
      'bathroomCount': openhouseDetail.bathroomCount,
      'builtIn': openhouseDetail.builtIn,

      'indoorSpaceUnit': openhouseDetail.indoorSpaceUnit,
      'indoorSpace': openhouseDetail.indoorSpace,
      'landSpaceUnit': openhouseDetail.landSpaceUnit,
      'landSpace': openhouseDetail.landSpace,

      'movieLink': openhouseDetail.movieLink,
    };
    setLoading(true);
    openhouseApi.saveOpenhouse(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(openhouseDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', openhouseDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      openhouseApi.createOpenhouseImageFile(
        openhouseDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeOpenhouseDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(openhouseDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': openhouseDetail.id,
      });

      openhouseApi.deleteOpenhouseImageFile(
        openhouseDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteOpenhouseDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeOpenhouseDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeOpenhouseDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeOpenhouseDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeOpenhouseDetailData(key, true);
    else
      changeOpenhouseDetailData(key, false);
  }

  const handleMemberNumberChange = (value) => {
    changeOpenhouseDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeOpenhouseDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeOpenhouseDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeOpenhouseDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeOpenhouseDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeOpenhouseDetailData('contactCode', value);
  }

  const handleTypeOnChange = (value) => {
    changeOpenhouseDetailData('type', value);
  }

  const handlePriceOnChange = (value) => {
    changeOpenhouseDetailData('price', value);
  }

  const handleSummaryOnChange = (value) => {
    changeOpenhouseDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeOpenhouseDetailData('link', value.trim())
  }

  const handleLocationIdOnChange = ({target}) => {
    changeOpenhouseDetailData('locationId', target.value);
  }

  const handleLocationSubIdOnChange = ({target}) => {
    changeOpenhouseDetailData('locationSubId', target.value);
  }

  const handleAddressOnChange = (value) => {
    changeOpenhouseDetailData('address', value.trim());
  }

  const handlePostalOnChange = (value) => {
    changeOpenhouseDetailData('postal', value.trim());
  }

  const handleBedroomCountOnChange = (value) => {
    changeOpenhouseDetailData('bedroomCount', value);
  }

  const handleBathroomCountOnChange = (value) => {
    changeOpenhouseDetailData('bathroomCount', value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeOpenhouseDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeOpenhouseDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeOpenhouseDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeOpenhouseDetailData('title', value.trim());
  }

  const handleBuiltInOnChange = (value) => {
    changeOpenhouseDetailData('builtIn', value);
  }

  const handleIndoorSpaceOnChange = (value) => {
    changeOpenhouseDetailData('indoorSpace', value);
  }

  const handleIndoorSpaceUnitOnChange = ({target}) => {
    changeOpenhouseDetailData('indoorSpaceUnit', target.value);
  }

  const handleLandSpaceOnChange = (value) => {
    changeOpenhouseDetailData('landSpace', value);
  }

  const handleLandSpaceUnitOnChange = ({target}) => {
    changeOpenhouseDetailData('landSpaceUnit', target.value);
  }

  const handleMovieLinkOnChange = (value) => {
    changeOpenhouseDetailData('movieLink', value.trim());
  }

  const handleSoldOutOnChange = (key, value) => {
    if(value)
      changeOpenhouseDetailData(key, true);
    else
      changeOpenhouseDetailData(key, false);
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/openhouse`, name: "오픈하우스", isLink: true },
            { link: `/market/openhouse/${searchParam?.id}`, name: `${searchParam?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={openhouseDetail.id} placeholder='입력' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={openhouseDetail?.active}
            objectData={ActiveTypeList}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={openhouseDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={openhouseDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={openhouseDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={openhouseDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={openhouseDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={openhouseDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={openhouseDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={openhouseDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={openhouseDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'임대인'}
            id={'name'}
            type={'text'} checkType={''}
            value={openhouseDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={openhouseDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={openhouseDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={openhouseDetail?.contactCode}
            objectData={ContactTypes}
            onChange={handleContactCodeOnChange}
          />
          <SelectComponentWithObject
            label={'주'}
            id={'locationId'}
            value={openhouseDetail?.locationId}
            objectData={RealEstateProvinces}
            onChange={handleLocationIdOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'도시'}
            id={'locationSubId'}
            value={openhouseDetail?.locationSubId}
            objectData={RealEstateCities}
            onChange={handleLocationSubIdOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'나머지 주소'}
            id={'address'}
            type={'text'}
            checkType={''}
            value={openhouseDetail.address}
            placeholder='예) 331A-4501 North Road' maxLength={255}
            onChange={handleAddressOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'우편번호'}
            id={'postal'}
            type={'text'}
            checkType={''}
            value={openhouseDetail.postal}
            placeholder='예) V3N 4R7' maxLength={10}
            onChange={handlePostalOnChange}
            style={{width: '10rem'}}
          />
          <RadioComponents
            label={'건물타입'}
            id={'type'}
            value={openhouseDetail?.type||''}
            objectData={OpenhouseTypes}
            onChange={handleTypeOnChange}
          />
          <InputComponent
            label={'매매가'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail?.price||0}
            placeholder={'예) 1000달러 -> 1000'} maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'방(수)'}
            id={'bedroomCount'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail.bedroomCount}
            placeholder={'예) 방 2개 -> 2'} maxLength={10}
            onChange={handleBedroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'욕실(수)'}
            id={'bathroomCount'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail.bathroomCount}
            placeholder={'예) 욕실 2개 -> 2'} maxLength={10}
            onChange={handleBathroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'완공연도'}
            id={'builtIn'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail.builtIn}
            placeholder={'년'} maxLength={10}
            onChange={handleBuiltInOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'실내 면적'}
            id={'indoorSpace'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail.indoorSpace}
            placeholder={'예) 1000'} maxLength={10}
            onChange={handleIndoorSpaceOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <SelectComponentWithObject
            label={'실내 면적 단위'}
            id={'indoorSpaceUnit'}
            value={openhouseDetail?.indoorSpaceUnit}
            objectData={OpenhouseSpaceUnits}
            onChange={handleIndoorSpaceUnitOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'대지 면적'}
            id={'landSpace'}
            type={'number'}
            checkType={'positiveNumber'}
            value={openhouseDetail.landSpace}
            placeholder={'예) 1000'} maxLength={10}
            onChange={handleLandSpaceOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <SelectComponentWithObject
            label={'대지 면적 단위'}
            id={'landSpaceUnit'}
            value={openhouseDetail?.landSpaceUnit}
            objectData={OpenhouseSpaceUnits}
            onChange={handleLandSpaceUnitOnChange}
            style={{width: '12rem'}}
          />
          <SwitchComponent
            label={'Sold Out'}
            id={'isSoldOut'}
            value={openhouseDetail?.isSoldOut}
            checkedValue={true}
            onChange={handleSoldOutOnChange}
            style={{width: '9.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'오픈하우스 동영상'}
            id={'movieLink'}
            type={'text'}
            checkType={''}
            value={openhouseDetail?.movieLink||''}
            placeholder={'입력'} maxLength={20}
            onChange={handleMovieLinkOnChange}
            style={{width: '40rem'}}
          />
          <div style={{fontSize: '12px'}}>
            * 예, http://www.youtube.com/watch?v=<span style={{color: 'red'}}>gCa2JaKBvjE</span>
            <br/>
            * 유부트에서 동영상 URL을 가져오신 뒤 위의 빨간색 코드 부분만 입력해주세요.
          </div>
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={openhouseDetail.title}
            placeholder={'입력'} maxLength={255}
            style={{width: '90rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={openhouseDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={openhouseDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
