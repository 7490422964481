import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { AuthApi } from '../api/AuthApi';
import { useEffect } from 'react';
import { useRef } from 'react';

export const Header = ({openProfileMenu, toggleProfileMenu, closeProfileMenu}) => {
  const modalRef = useRef(null);

  const authApi = new AuthApi();
  const navigate = useNavigate();

  const handleLogoutOnClick = () => {
    authApi.signOut();
    toggleProfileMenu?.();
    navigate("/signIn");
  }

  const handleMenuOnClick = (url) => {
    toggleProfileMenu?.();
    navigate(url);
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeProfileMenu();
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [closeProfileMenu, modalRef]);


  return (
    <Wrapper ref={modalRef}>
      <div className="logo" onClick={()=>navigate('/')}>
        <img src="/img/logo_top.png" alt="Vanchosun Admin" />
      </div>
      <div className="userBox">
        <div className="avatar"></div>
        <div className="name">name</div>
        <i className="downArrow" onClick={()=> toggleProfileMenu()} />
      </div>
      {
        openProfileMenu && (
          <ProfileMenu>
            {authApi.isSignIn() ? (
              <>
              <div onClick={() => handleMenuOnClick('/')}>My Profile</div>
              <div onClick={() => handleLogoutOnClick()}>Logout</div>
              </>
            )
            : (
              <>
              <div onClick={() => handleMenuOnClick('/signIn')}>Sign in</div>
              <div onClick={() => alert('Sing up!')}>Sing up</div>
              </>
            )}
          </ProfileMenu>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.header`
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  min-width: 800px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ddd;
  background: #fff;
  z-index: 1;
  & div.logo {
    display: block;
    padding: 10px 20px;
    width: 170px;
    height: 100%;
    & img {
      width: 162px;
      height: 32px;
    }
  }

  & div.userBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-left: 1px solid #ddd;
    & div.avatar {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ddd;
    }
    & div.name {
      margin: 10px;
    }
  }
`;

const ProfileMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 0px;
  width: 236px;
  & div {
    padding: 12px;
    display: block;
    color: black;
    background-color: #eee;
    text-decoration: none;
    box-shadow:
      0.1rem 0 0 0 #ddd,
      0 0.1rem 0 0 #ddd,
      0.1rem 0.1rem 0 0 #ddd,
      0.1rem 0 0 0 #ddd inset,
      0 0.1rem 0 0 #ddd inset;
    cursor: pointer;
  }
  & div:hover {
    background-color: #ccc;
  }
`;