import { jsonApi } from "../util/AxiosApi";

export class OrderApi {

  fetchOrderList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/order`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchOrderListByRefPostId(refBoardId, refPostId, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/order`,
      params: {
        id: '',
        boardType: '',
        refBoardId: refBoardId,
        refPostId: refPostId,
        page: 1,
        listSize: 25,
        perPageSize: 15,
      },
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchOrderById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/order/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchOrderForWebList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/order/web`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchOrderForWebById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/order/web/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

};