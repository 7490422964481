import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { OrderApi } from '../../api/OrderApi';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { CalendarComponent } from '../../component/CalendarComponent';
import { OrderForWebDetailModal } from './component/OrderForWebDetailModal';
import { Pagination } from '../../component/Pagination';
import { Button } from '../../component/Button';
import { Loader } from '../../component/Loader';
import {
  BoardType,
  BoardTypeList,
  ListSize,
} from '../../__mocks__/PostData';
import { errorAlert } from '../../util/ErrorHandler';

export const OrderForWebList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "orderForWeb_search";
  const orderApi = new OrderApi();

  const [loading, setLoading] = useState(false);
  const initSearchParamObject = {
    id: '',
    boardType: '',
    refBoardId: 0,
    refPostId: '',
    orderStartDateTime: '',
    orderEndDateTime: '',
    page: 1,
    listSize: 100,
    perPageSize: 15,
    totalCount: 0,
    totalPageCount: 0,
  }
  const [modalInfo, setModalInfo] = useState({
    id: '',
    postId: '',
    show: false,
  });
  const [searchParam, setSearchParam] = useState(()=>{
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          ...initSearchParamObject
        };
      }
    }
  });
  const [result, setResult] = useState(null);

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      id: '',
      boardType: '',
      refBoardId: 0,
      refPostId: '',
      orderStartDateTime: '',
      orderEndDateTime: '',
      page: 1,
      listSize: 100,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam('page', 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    orderApi.fetchOrderForWebList(
      searchParam,
      (data) => {
        setResult(null);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setResult(null);
        errorAlert(error);
      }
    );
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }

  const handleRefBoardIdOnChange = ({target}) => {
    changeSearchParam?.('refBoardId', target.value);
  }

  const handleRefPostIdOnChange = (value) => {
    changeSearchParam?.('refPostId', value);
  }

  const handleOrderStartDateTimeOnChange = (value) => {
    changeSearchParam?.('orderStartDateTime', value);
  }

  const handleOrderEndDateTimeOnChange = (value) => {
    changeSearchParam?.('orderEndDateTime', value);
  }

  const handleListSizeOnChange = ({target}) => {
    changeSearchParam("listSize" , target.value);
  }

  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleOrderForWebDetailOnClick = (id, refPostId) => {
    saveSearchData();
    handleShowModal(id, refPostId);
  }

  const handleShowModal = (id, refPostId) => {
    setModalInfo({
      id: id,
      postId: refPostId,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: 0,
      postId: 0,
      show: false
    });
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }

  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "Home", isLink: false },
            { link: "/order-old", name: "주문(웹)", isLink:true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'주문#'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam.id}
              placeholder={'ID 입력'} min={0} maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'게시판'}
              id={'refBoardId'}
              value={searchParam.refBoardId}
              objectData={BoardTypeList}
              onChange={handleRefBoardIdOnChange}
              style={{width: '10rem'}}
            />
            <InputComponent label={'게시물 #'}
              id={'refPostId'}
              type={'text'} checkType={''}
              value={searchParam.refPostId}
              placeholder={'ID 입력'} min={0} maxLength={255}
              onChange={handleRefPostIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <CalendarComponent label={'검색시작 주문일시'}
              id={'orderStartDateTime'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={searchParam?.orderStartDateTime}
              onChange={handleOrderStartDateTimeOnChange}
              isClearable={true}
            />
            <CalendarComponent label={'검색종료 주문일시'}
              id={'orderEndDateTime'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={searchParam?.orderEndDateTime}
              onChange={handleOrderEndDateTimeOnChange}
              isClearable={true}
            />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={ListSize}
              onChange={handleListSizeOnChange}
              style={{width: '8rem'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <OrderListSection>
        <table>
          <thead>
            <tr>
              <TableTh width={10}>#</TableTh>
              <TableTh width={15}>주문일시</TableTh>
              <TableTh width={10}>총 금액(세후)</TableTh>
              <TableTh width={8}>상태</TableTh>
              <TableTh width={20}>회원#</TableTh>
              <TableTh width={8}>게시판</TableTh>
              <TableTh width={10}>게시물#</TableTh>
            </tr>
          </thead>
          <tbody>
          {0 < result?.resultList?.length ? (
            <>
              {result?.resultList?.map((order) => (
                <tr key={order.id} onDoubleClick={() => handleOrderForWebDetailOnClick(order.id, order.refPostId)} >
                  <td>
                    <input
                      id={`id${order.id}`}
                      type={'text'}
                      value={order.id}
                      disabled
                      style={{width: '98%', height: '2.3rem', textAlign: 'right', border: 0}}
                    />
                  </td>
                  <td>{order.orderDateTime}</td>
                  <td>{order.grandTotalAmount}</td>
                  <td>{order.status}</td>
                  <td>
                    {order.memberEmail}
                    <br/>
                    {0<order.memberId && order.memberId}
                  </td>
                  <td>{`${BoardType[order.refBoardId]?.label}(${order.refBoardId})`}</td>
                  <td>{order.refPostId}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='7'>
                데이터가 없습니다.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </OrderListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <OrderForWebDetailModal
        id={modalInfo.id}
        postId={modalInfo.postId}
        show={modalInfo.show}
        onClose={handleCloseModal}
      />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const OrderListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}rem` : '')};
`;
