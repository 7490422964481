import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../../../component/InputComponent';
import { OrderApi } from '../../../api/OrderApi';
import { Button } from '../../../component/Button';
import { BoardType } from '../../../__mocks__/PostData';
import { errorAlert } from '../../../util/ErrorHandler';

export const OrderForWebDetailModal = ({id, postId, show, onClose}) => {
  const navigate = useNavigate();
  const orderApi = new OrderApi();
  const [orderDetail, setOrderDetail] = useState({
    orderId: id,

    orderDateTime: '',
    paymentTitle: '',
    paymentStatus: '',
    paymentTranId: '',
    paymentApprovalCode: '',
    grandTotalAmount: '',
    totalItemAmount: '',
    totalGstAmount: '',
    totalPstAmount: '',
    memberId: '',
    memberEmail: '',

    orderCartPartList: [],
  });

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    orderApi.fetchOrderForWebById(
      id,
      (data) => {
        setOrderDetail(data?.result);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleMoveListOnClick = (boardId, postId) => {
    const boardCode = BoardType[boardId]?.code;
    if(boardCode && postId) {
      navigate(`/${boardCode}`, {
        state: {
          id: postId,
        },
      });
    }
  }

  useEffect(() => {
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>주문 정보</h3>
        </Header>
        <div>
          <InputGroup>
            <InputComponent label={'주문번호'}
              id={'id'}
              type={'text'} checkType={''}
              value={orderDetail.id} placeholder='입력' maxLength={255}
              style={{width: '10rem'}}
              disabled={true}
            />
            <InputComponent label={'결제일시'}
              id={'orderDateTime'}
              type={'text'} checkType={''}
              value={orderDetail.orderDateTime} placeholder='입력' maxLength={255}
              style={{width: '16rem', textAlign: 'center'}}
              disabled={true}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'결제번호'}
              id={'paymentTranId'}
              type={'text'} checkType={''}
              value={orderDetail.paymentTranId} placeholder='입력' maxLength={255}
              style={{width: '100%'}}
              disabled={true}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'결제 방법'}
              id={'paymentTitle'}
              type={'text'} checkType={''}
              value={orderDetail.paymentTitle} placeholder='입력' maxLength={255}
              style={{width: '10rem'}}
              disabled={true}
            />
            <InputComponent label={'상태'}
              id={'paymentStatus'}
              type={'text'} checkType={''}
              value={orderDetail.paymentStatus} placeholder='입력' maxLength={255}
              style={{width: '5rem'}}
              disabled={true}
            />
          </InputGroup>
          <h4>결제 금액</h4>
          <InputGroup>
            <InputComponent label={'총 결제금액'}
              id={'grandTotalAmount'}
              type={'text'} checkType={''}
              value={orderDetail.grandTotalAmount} placeholder='입력' maxLength={255}
              style={{width: '13rem', textAlign: 'right'}}
              disabled={true}
            />
            <InputComponent label={'총 금액'}
              id={'totalItemAmount'}
              type={'text'} checkType={''}
              value={orderDetail.totalItemAmount} placeholder='입력' maxLength={255}
              style={{width: '13rem', textAlign: 'right'}}
              disabled={true}
            />
            <InputComponent label={'총 GST'}
              id={'totalGstAmount'}
              type={'text'} checkType={''}
              value={orderDetail.totalGstAmount} placeholder='입력' maxLength={255}
              style={{width: '13rem', textAlign: 'right'}}
              disabled={true}
            />
            <InputComponent label={'주문 회원'}
              id={'memberEmail'}
              type={'text'} checkType={''}
              value={orderDetail.memberEmail} placeholder='입력' maxLength={255}
              style={{width: '18rem'}}
              disabled={true}
            />
          </InputGroup>
          <OrderDetailListSection>
            <table>
              <thead>
                <tr>
                  <TableTh width={40}></TableTh>
                  <TableTh width={50}>#</TableTh>
                  <TableTh width={40}>게시판</TableTh>
                  <TableTh width={50}>Post#</TableTh>
                  <TableTh width={200}>상품명</TableTh>
                  <TableTh width={220}>상품설명</TableTh>
                  <TableTh width={50}>금액</TableTh>
                  <TableTh width={50}>수량</TableTh>
                  <TableTh width={90}>Section Type</TableTh>
                </tr>
              </thead>
              <tbody>
              {orderDetail?.orderCartPartList?.map((detail) => (
                <tr key={detail.partId} style={{backgroundColor: (postId === detail.bdId)?'#BEEFFF':''}}>
                  <td>
                    <Button
                      type='button'
                      value={'Link'}
                      onClick={()=> handleMoveListOnClick(detail.boardId, detail.bdId)}
                      width={'4rem'}
                      style={{ color: '#fff', backgroundColor: '#0088cc'}}
                    />
                  </td>
                  <td>{detail.partId}</td>
                  <td>{BoardType[detail.boardId]?.label}</td>
                  <td>{detail.bdId}</td>
                  <td>{detail.productName}</td>
                  <td>{detail.productDescription}</td>
                  <td>{detail.productPrice}</td>
                  <td>{detail.productQuantity}</td>
                  <td>{detail.sectionType}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </OrderDetailListSection>
        </div>
      </ModalWindow>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 83.6rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.2rem;
  height: 47rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;
