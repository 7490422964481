
export const priceToString = (price, minimumSignificantDigits=1) => {
  // if(price)
  //   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // else
  //   return '0';
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumSignificantDigits: minimumSignificantDigits,
    // notation: 'compact',
  });
  return formatter.format(price);
}

export const priceToStringWithDecimal = (price) => {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    // notation: 'compact',
  });
  return formatter.format(price);
}

export const priceToStringWithCompact = (price) => {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    notation: 'compact',
  });
  return formatter.format(price);
}

export const numberToString = (number) => {
  const formatter = new Intl.NumberFormat('en-CA');
  return formatter.format(number);
}

export const numberToSimple = (number) => {
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
  });
  return formatter.format(number);
}

export const toStringByFormatting = (source, delimiter = '-') => {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  return [year, month, day].join(delimiter);
}

const leftPad = (value) => {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

export const removeHtmlTags = (string) => {
  if(string) {
    return string.replace(/(<([^>]+)>)/ig, "").replace(/&quot;/g, "").replace(/&amp;/g, "").replace(/&nbsp;/g, "");
  } else {
    return '';
  }
}

export const isBlankValue = (value) => {
  if(value) {
    return false;
  } else {
    return true;
  }
};

export const isPositiveOrZero = (value) => {
  if(0 > value) {
    return false;
  } else {
    return true;
  }
};

export const isValidEmail = (value) => {
  const emailRegex =/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (!(emailRegex.test(value))) {
    return false;
  } else {
    return true;
  }
}

export const isValidPassword = (password) => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
  if (!(passwordRegex.test(password))) {
    return false;
  } else {
    return true;
  }
}

export const randomColor = () => {
  return `#${Math.floor(Math.random()*16777215).toString(16)}`;
}

export const isEmptyObj = (obj) => {
  if(!obj) {
    return true;
  }
  if(typeof(obj) === "object"
    && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}

export const isValidImageFile = (file, maxSize=4) => {
  const FILE_TYPE = "image/.*";
  const FILE_MAX_SIZE = maxSize * 1024 * 1024;
  const { type, size } = file;

  if(!file) {
    alert("이미지를 선택하세요.");
    return false;
  }
  if(FILE_MAX_SIZE < size) {
    alert(`이미지는 ${maxSize} MB 까지 지원합니다.`);
    return false;
  }
  if(!type.match(FILE_TYPE)) {
    alert("이미지 파일만 지원합니다.(JPG, PNG)");
    return false;
  }
  return true;
}

export const addHyphenForPhoneNumber = (value) => {
  const number = value.replace(/[^0-9]/g, "");
  let phone = "";

  if (number.length < 5) {
    return number;
  } else if (number.length < 8) {
    phone = `${number.substr(0, 3)}-${number.substr(3)}`;
  } else if (number.length < 10) {
    phone = `${number.substr(0, 2)}-${number.substr(2, 3)}-${number.substr(5)}`;
  } else if (number.length < 11) {
    phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
  } else if (number.length < 12) {
    phone = `${number.substr(0, 3)}-${number.substr(3, 4)}-${number.substr(7)}`;
  }
  return phone;
}
