import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

export const SelectMultiComponent = ({ label, id, value, options, onChange, disabled, isMulti, closeMenuOnSelect, isClearable, width}) => {

  const handleOnChange = (value) => {
    onChange?.(value);
  }

  return (
    <SelectGroup>
      <label htmlFor={id}>{label}</label>
      {/* <select className="form-select"
        id={id}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        style={style}
      >
        { Object.keys(objectData).map((key) =>
          <option key={key} value={key}>{objectData[key]}</option>
        )}
      </select> */}
      <Select
        id={id}
        value={value}
        onChange={handleOnChange}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        // isClearable={isClearable}
        disabled={disabled}
        options={options}
        styles={selectCustomStyles}
        // width={width?width: 350}
        />
    </SelectGroup>
  );
}

const SelectGroup = styled.div`
  position: relative;
  margin: 0.7rem 0.7rem;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  & label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
`;

const selectCustomStyles = {
  control: (styles, { selectProps: { width }}) => ({ ...styles, width: width, }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
