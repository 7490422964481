import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserApi } from '../../../api/UserApi';
import { InputComponent } from '../../../component/InputComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { RadioComponents } from '../../../component/RadioComponents';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveType,
  AddressCity,
  AddressCountry,
  AddressRegion,
  SexType,
  UserType,
} from '../../../__mocks__/PostData';

export const UserModal = ({id, show, onClose}) => {
  const navigate = useNavigate();
  const userApi = new UserApi();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: id,
    statusCode: 0,

    username: '',
    name: '',
    nickName: '',
    password: '',

    sex: '',
    birthDay: '',

    type: '',
    subType: '',

    email: '',
    phone: '',

    address: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',

    companyName: '',
    companyPhone: '',

    adStartDate: '',
    adEndDate: '',

    newsletter: false,

    registrationDate: '',
    loginDate: '',
    loginIp: '',
    reallyInfo: {},
  });
  const [userPostCountInfo, setUserPostCountInfo] = useState({
    id: id,
    buysellCount: 0,
    jobCount: 0,
    tutorCount: 0,
    carCount: 0,
    rentCount: 0,
    homestayCount: 0,
    commercialCount: 0,
    dwellingCount: 0,
    openhouseCount: 0,
  });

  // == state 변경 ------------------------------------------------
  const changeUserInfoData = (key, value) => {
    setUserInfo((values) => {
      const updated = {...values};
      updated[key] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    handleSearchUserOnClick();
    handleSearchPostCount();
  }

  const handleSearchUserOnClick = () => {
    setLoading(true);
    userApi.fetchUserId(
      id,
      (data) => {
        setUserInfo(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchPostCount = () => {
    userApi.fetchUserPostCount(
      id,
      (data) => {
        setUserPostCountInfo(data?.result);
      },
      (error) => {
        errorAlert(error);
      }
    )
  }

  const handleSaveOnClick = () => {
    setLoading(true);
    userApi.saveUserInfo(
      id,
      userInfo,
      (data) => {
        // setUserInfo(data?.result);
        setLoading(false);
        alert(data.message);
        changeUserInfoData('password', '');
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const handleMoveListOnClick = (borderName) => {
    navigate(`/${borderName}`, {
      state: {
        userId: id,
      },
    });
  }

  const handlePasswordOnChange = (value) => {
    changeUserInfoData('password', value);
  }
  const handleNameOnChange = (value) => {
    changeUserInfoData('name', value);
  }
  const handleNickNameOnChange = (value) => {
    changeUserInfoData('nickName', value);
  }
  const handleStatusCodeOnChange = (value) => {
    changeUserInfoData('statusCode', parseInt(value));
  }
  const handleSexTypeOnChange = (value) => {
    changeUserInfoData?.('sex', value);
  }
  const handleBirthDayOnChange = (value) => {
    changeUserInfoData?.('birthDay', value);
  }
  const handleNewsletterOnChange = (key, value) => {
    if(value)
      changeUserInfoData?.('newsletter', true);
    else
      changeUserInfoData?.('newsletter', false);
  }
  const handleUserTypeOnChange = ({target}) => {
    changeUserInfoData?.('type', target.value);
  }
  const handleEmailOnChange = (value) => {
    changeUserInfoData?.('email', value);
  }
  const handlePhoneOnChange = (value) => {
    changeUserInfoData?.('phone', value);
  }
  const handleAddressOnChange = (value) => {
    changeUserInfoData?.('address', value);
  }
  const handleCityOnChange = ({target}) => {
    changeUserInfoData?.('city', target.value);
  }
  const handleRegionOnChange = ({target}) => {
    changeUserInfoData?.('region', target.value);
  }
  const handleCountryOnChange = ({target}) => {
    changeUserInfoData?.('country', target.value);
  }
  const handlePostalCodeOnChange = (value) => {
    changeUserInfoData?.('postalCode', value);
  }
  const handleCompanyNameOnChange = (value) => {
    changeUserInfoData?.('companyName', value);
  }
  const handleCompanyPhoneOnChange = (value) => {
    changeUserInfoData?.('companyPhone', value);
  }
  const handleStartDateOnChange = (value) => {
    changeUserInfoData?.('adStartDate', value);
  }
  const handleEndDateOnChange = (value) => {
    changeUserInfoData?.('adEndDate', value);
  }
  const handleReallyPostNumberOnChange = (value) => {
    changeUserInfoData?.('reallyInfo', {
      ...userInfo?.reallyInfo,
      'postNumber': parseInt(value)
    });
  }
  const handleReallyAdStartDateOnChange = (value) => {
    changeUserInfoData?.('reallyInfo', {
      ...userInfo?.reallyInfo,
      'reallyAdStartDate': value
    });
  }
  const handleReallyAdEndDateOnChange = (value) => {
    changeUserInfoData?.('reallyInfo', {
      ...userInfo?.reallyInfo,
      'reallyAdEndDate': value
    });
  }

  useEffect(() => {
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>User Information</h3>
        </Header>
        <div>
          <h4>General Information</h4>
          <InputGroup>
            <InputComponent label={'회원번호'}
              id={'id'}
              type={'number'} checkType={''}
              value={userInfo.id} placeholder='입력' maxLength={255}
              style={{width: '8rem'}}
              disabled={true}
            />
            <InputComponent label={'로그인ID'}
              id={'username'}
              type={'text'} checkType={''}
              value={userInfo.username} placeholder='입력' maxLength={255}
              style={{width: '30rem'}}
              disabled={true}
            />
            <InputComponent label={'비밀번호'}
              id={'password'}
              type={'password'} checkType={''}
              value={userInfo.password} placeholder='입력' maxLength={255}
              onChange={handlePasswordOnChange}
              style={{width: '15rem'}}
            />
            <RadioComponents label={'상태'}
              id={'statusCode'}
              value={userInfo.statusCode}
              objectData={ActiveType}
              onChange={handleStatusCodeOnChange}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={userInfo.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              style={{width: '14.7rem'}}
            />
            <InputComponent label={'별명'}
              id={'nickName'}
              type={'text'} checkType={''}
              value={userInfo.nickName} placeholder='입력' maxLength={255}
              onChange={handleNickNameOnChange}
              style={{width: '15.1rem'}}
            />
            <RadioComponents label={'성별'}
              id={'sex'}
              value={userInfo.sex}
              objectData={SexType}
              onChange={handleSexTypeOnChange}
            />
            <CalendarComponent label={'생년월일'}
              id={'birthDay'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={userInfo.birthDay}
              onChange={handleBirthDayOnChange}
              isClearable={true}
            />
            <SelectComponentWithObject label={'Type'}
              id={'type'}
              value={userInfo.type}
              objectData={UserType}
              onChange={handleUserTypeOnChange}
            />
          </InputGroup>
          <h4>Contact Information</h4>
          <InputGroup>
            <InputComponent label={'Email'}
              id={'email'}
              type={'text'} checkType={'email'}
              value={userInfo.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '26rem'}}
            />
            <InputComponent label={'Phone'}
              id={'phone'}
              type={'text'} checkType={''}
              value={userInfo.phone} placeholder='입력' maxLength={255}
              onChange={handlePhoneOnChange}
              style={{width: '15rem'}}
            />
            <SwitchComponent label={'뉴스레터'}
              id={'newsletter'}
              value={userInfo.newsletter}
              checkedValue={true}
              onChange={handleNewsletterOnChange}
            />
            <InputComponent label={'우편번호'}
              id={'postalCode'}
              type={'text'} checkType={''}
              value={userInfo.postalCode} placeholder='입력' maxLength={255}
              onChange={handlePostalCodeOnChange}
              style={{width: '10rem'}}
            />
            <InputComponent label={'주소'}
              id={'address'}
              type={'text'} checkType={''}
              value={userInfo.address} placeholder='입력' maxLength={255}
              onChange={handleAddressOnChange}
              style={{width: '20rem'}}
            />
            <SelectComponents label={'도시'}
              id={'city'}
              value={userInfo.city}
              objectData={[{ value: '', label: '선택' }, ...AddressCity]}
              onChange={handleCityOnChange}
            />
            <SelectComponents label={'주'}
              id={'region'}
              value={userInfo.region}
              objectData={[{ value: '', label: '선택' }, ...AddressRegion]}
              onChange={handleRegionOnChange}
            />
            <SelectComponents label={'국가'}
              id={'country'}
              value={userInfo.country}
              objectData={[{ value: '', label: '선택' }, ...AddressCountry]}
              onChange={handleCountryOnChange}
            />

          </InputGroup>
          <h4>Company Information</h4>
          <InputGroup>
            <InputComponent label={'회사명'}
              id={'companyName'}
              type={'text'} checkType={''}
              value={userInfo.companyName} placeholder='입력' maxLength={255}
              onChange={handleCompanyNameOnChange}
              style={{width: '22rem'}}
            />
            <InputComponent label={'회사 전화번호'}
              id={'companyPhone'}
              type={'text'} checkType={''}
              value={userInfo.companyPhone} placeholder='입력' maxLength={255}
              onChange={handleCompanyPhoneOnChange}
              style={{width: '15rem'}}
            />
            <CalendarComponent label={'계약 시작기간'}
              id={'adStartDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={userInfo.adStartDate||''}
              onChange={handleStartDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            <CalendarComponent label={'계약 종료기간'}
              id={'adEndDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={userInfo.adEndDate||''}
              onChange={handleEndDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
          </InputGroup>
          {'realtor' === userInfo?.type && (
            <>
              <h4>Realtor Information</h4>
              <InputGroup>
                <InputComponent label={'계약건수'}
                  id={'postNumber'}
                  type={'number'} checkType={''}
                  value={userInfo?.reallyInfo?.postNumber} placeholder='입력' maxLength={255}
                  onChange={handleReallyPostNumberOnChange}
                  style={{width: '10rem'}}
                />
                <CalendarComponent label={'부동산 계약 시작'}
                  id={'reallyAdStartDate'}
                  dateFormat={'yyyy-MM-dd HH:mm:ss'}
                  placeholderText={'yyyy-MM-dd HH:mm:ss'}
                  value={userInfo?.reallyInfo?.reallyAdStartDate||''}
                  onChange={handleReallyAdStartDateOnChange}
                  isClearable={true}
                  isShowTimeSelect={true}
                  width={'18.5rem'}
                />
                <CalendarComponent label={'부동산 계약 종료'}
                  id={'reallyAdEndDate'}
                  dateFormat={'yyyy-MM-dd HH:mm:ss'}
                  placeholderText={'yyyy-MM-dd HH:mm:ss'}
                  value={userInfo?.reallyInfo?.reallyAdEndDate||''}
                  onChange={handleReallyAdEndDateOnChange}
                  isClearable={true}
                  isShowTimeSelect={true}
                  width={'18.5rem'}
                />
                <InputComponent label={'승인자'}
                  id={'approvedByName'}
                  type={'text'} checkType={''}
                  value={userInfo?.reallyInfo?.approvedByName} placeholder='입력' maxLength={255}
                  style={{width: '10rem'}}
                  disabled
                />
                <InputComponent label={'계약 승인 일시'}
                  id={'approvedDate'}
                  type={'text'} checkType={''}
                  value={userInfo?.reallyInfo?.approvedDate} placeholder='입력' maxLength={255}
                  style={{width: '16rem'}}
                  disabled
                />
              </InputGroup>
            </>
          )}
          <h4>Access Information</h4>
          <InputGroup>
            <InputComponent label={'최근 접속 IP'}
              id={'loginIp'}
              type={'text'} checkType={''}
              value={userInfo.loginIp} placeholder='입력' maxLength={255}
              disabled
              style={{width: '13rem'}}
            />
            <InputComponent label={'최근 접속 일시'}
              id={'loginDate'}
              type={'text'} checkType={''}
              value={userInfo.loginDate} placeholder='입력' maxLength={255}
              disabled
              style={{width: '16.5rem'}}
            />
            <InputComponent label={'회원 가입 일시'}
              id={'registrationDate'}
              type={'text'} checkType={''}
              value={userInfo.registrationDate} placeholder='입력' maxLength={255}
              disabled
              style={{width: '16.5rem'}}
            />
          </InputGroup>
          <hr />
          <PostInfo>
            <table>
              <thead>
                <tr>
                  <th>사고팔고</th>
                  <th>구인구직</th>
                  <th>학원/튜터</th>
                  <th>자동차</th>
                  <th>홈스테이</th>
                  <th>렌트</th>
                  <th>주거매매</th>
                  <th>비즈매매</th>
                  <th>오픈하우스</th>
                  <th>커뮤니티</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td onClick={()=>handleMoveListOnClick('market/buysell')}>
                    {userPostCountInfo.buysellCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/job')}>
                    {userPostCountInfo.jobCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/tutor')}>
                    {userPostCountInfo.tutorCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/car')}>
                    {userPostCountInfo.carCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/homestay')}>
                    {userPostCountInfo.rentCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/rent')}>
                    {userPostCountInfo.homestayCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/dwelling')}>
                    {userPostCountInfo.dwellingCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/commercial')}>
                    {userPostCountInfo.commercialCount}
                  </td>
                  <td onClick={()=>handleMoveListOnClick('market/openhouse')}>
                    {userPostCountInfo.openhouseCount}
                  </td>
                  <td >
                    dev
                  </td>
                </tr>
              </tbody>
            </table>
          </PostInfo>
        </div>
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
        </ButtonGroup>
      </ModalWindow>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 83.6rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const PostInfo = styled.div`
  padding: 0rem 2.3rem;
  border: 0.1rem solid #fff;
  border-radius: 0.5rem;
  & table {
    width: 100%;
    border-collapse : collapse;
    background-color: #676868;
    & thead {
      & tr {
        height: 4rem;
        & th {
          width: 10rem;
          color: #fff;
          font-size: 0.7em;
          font-weight: 300;
          border: 0.1rem solid #fff;
        }
      }
    }
    & tbody {
      & tr {
        height: 4rem;
        & td {
          padding: 0.5rem;
          color: #fff;
          font-size: 0.8em;
          font-weight: 300;
          text-align: right;
          border: 0.1rem solid #fff;
          cursor: pointer;
        }
      }
    }
    border-radius: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;