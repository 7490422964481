import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { AuthApi } from '../api/AuthApi';
import { Button } from '../component/Button';
import { InputComponent } from '../component/InputComponent';
import { Loader } from '../component/Loader';
import { getRememberMe } from '../util/TokenManager';
import { errorAlert } from '../util/ErrorHandler';

export const SignIn = () => {
  const authApi = new AuthApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isRememberUser, setIsRememberUser] = useState(false);
  const [loginData, setLoginData] = useState({
    email: getRememberMe(),
    password: '',
  });

  const changeLoginData = (name, value) => {
    setLoginData((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const handleEmailOnChange = (value) => {
    changeLoginData('email', value);
  }
  const handlePasswordOnChange = (value) => {
    changeLoginData('password', value);
  }

  const handleRememberOnClick = () => {
    setIsRememberUser(!isRememberUser);
  }

  const handleLoginButtonOnClick = () => {
    setLoading(true);

    authApi.signIn(
      loginData,
      () => {
        setLoading(false);
        alert("환영합니다.");
        navigate("/");
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handlePasswordOnKeyUp = (e) => {
    if (e.key === "Enter") {
      handleLoginButtonOnClick();
    }
  }

  return (
    <>
      <Wrapper>
        <div className='panel'>
          <div className={`head`}>
            <img src="/img/logo_top.png" alt="Vanchosun Admin" />
            <h2>SIGN IN</h2>
          </div>
          <div className={'body'}>
            <InputComponent label={'Username'} type={'text'} checkType={'text'}
              id={'email'}
              value={loginData.email} placeholder='' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '100%'}}
            />
            <InputComponent label={'Password'} type={'password'} checkType={'text'}
              id={'password'}
              value={loginData.password} placeholder='' maxLength={255}
              onChange={handlePasswordOnChange}
              onKeyUp={handlePasswordOnKeyUp}
              style={{width: '100%'}}
            />
            <div className='buttonGroup'>
              <div className='checkboxGroup'>
                <input id={'rememberMe'} type={'checkbox'} checked={isRememberUser} onChange={()=>handleRememberOnClick()}/>
                <label htmlFor='rememberMe'>Remember me</label>
              </div>
              <Button type='button' value={'Sign in'} onClick={()=> handleLoginButtonOnClick()} style={{ color: '#fff', backgroundColor: '#0088cc'}} />
            </div>
          </div>
          <div className={'bottom'}>
          </div>
        </div>
      </Wrapper>
      <Loader loading={loading} />
    </>
  );
};

const Wrapper = styled.header`
  position: fixed;
  width: 470px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & div.panel {
    & div.head {
      position: relative;
      & img {
        width: 190px;
        height: 41px;
      }
      & h2 {
        margin: 0;
        padding: 4px 25px;
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 14px;
        color: #fff;
        background-color: #0088cc;
        border: 1px solid #0088cc;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* 인터넷익스플로러 */
        user-select: none;
      }
    }
    & div.body {
      padding: 25px 35px;
      border: 1px solid #ddd;
      border-top: 3px solid #0088cc;
      border-top-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      & div {
        margin-top: 20px;
      }
      & div.buttonGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & div.checkboxGroup {
          display: flex;
          align-items: center;
          & label {
            font-size: 14px;
            color: #777;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* 인터넷익스플로러 */
            user-select: none;
            cursor: pointer;
          }
          & input[type='checkBox'] {
            cursor: pointer;
          }
        }
      }
    }
  }
`;