import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { UserApi } from '../../api/UserApi';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { SelectComponentWithObject } from '../../component/SelectComponentWithObject';
import { Pagination } from '../../component/Pagination';
import { Loader } from '../../component/Loader';
import { Button } from '../../component/Button';
import { UserModal } from './component/UserModal';
import {
  ActiveType,
  ListSize,
} from '../../__mocks__/PostData';
import { errorAlert } from '../../util/ErrorHandler';

export const RealtorList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "realtorList_search";
  const userApi = new UserApi();
  const [selectListSize] = useState(ListSize);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(()=>{
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          id: '',
          userName: '',
          name: '',
          nickName: '',
          email: '',
          phone: '',
          status: 1,
          page: 1,
          listSize: 25,
          perPageSize: 15,
          totalCount: 0,
          totalPageCount: 0,
        }
      }
    }
  });
  const [result, setResult] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      id: '',
      userName: '',
      name: '',
      nickName: '',
      email: '',
      phone: '',
      status: 1,
      page: 1,
      listSize: 25,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam("page", 1);
    handleSearch();
  }
  const handleSearch = () => {
    setLoading(true);
    userApi.fetchRealtorList(
      searchParam,
      (data) => {
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }
  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }
  const handleUserNameOnChange = (value) => {
    changeSearchParam?.('userName', value);
  }
  const handleNameOnChange = (value) => {
    changeSearchParam?.('name', value);
  }
  const handleUserEmailOnChange = (value) => {
    changeSearchParam?.('email', value);
  }
  const handleUserPhoneOnChange = (value) => {
    changeSearchParam?.('phone', value);
  }
  const handleStatusOnChange = (e) => {
    changeSearchParam("status" , e.target.value);
  }
  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }
  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleUserOnClick = (id) => {
    saveSearchData();
    handleShowModal(id);
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleUserModalClose = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            {link:"/",name:"회원 관리",isLink:false},
            {link:"/realtor",name:"리얼터",isLink:true}
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'#'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam.id}
              placeholder='# 검색' maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent label={'로그인ID'}
              id={'userName'}
              type={'text'} checkType={''}
              value={searchParam.userName}
              placeholder='ID 검색' maxLength={255}
              onChange={handleUserNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={searchParam.name}
              placeholder='이름 검색' maxLength={255}
              onChange={handleNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={searchParam.email}
              placeholder='Email 검색' maxLength={255}
              onChange={handleUserEmailOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'전화번호'}
              id={'phone'}
              type={'text'} checkType={''}
              value={searchParam.phone}
              placeholder='Phone 입력' maxLength={255}
              onChange={handleUserPhoneOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <SelectComponentWithObject label={'상태'}
              id={'status'}
              value={searchParam.status}
              objectData={ActiveType}
              onChange={handleStatusOnChange}
              style={{width: '10rem'}}
            />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={selectListSize}
              onChange={handleListSizeOnChange}
              style={{width: '100px'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <UserListSection>
        <table>
          <thead>
            <tr>
              <th style={{width: '10rem'}}>#</th>
              <th>로그인ID</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>상태</th>
              <th>렌트</th>
              <th>주거매매</th>
              <th>비즈매매</th>
            </tr>
          </thead>
          <tbody>
          {0 < result?.resultList?.length ? (
            <>
              {result?.resultList.map((user) => (
                <tr key={user.id} onDoubleClick={() => handleUserOnClick(user.id)}>
                  <td>
                    <input
                      id={`id${user.id}`}
                      type={'text'}
                      value={user.id}
                      disabled
                      style={{width: '98%', height: '2.3rem', textAlign: 'right', border: 0}}
                    />
                  </td>
                  <td>
                    <input
                      id={`loginId${user.id}`}
                      type={'text'}
                      value={user.username}
                      disabled
                      style={{width: '98%', height: '2.3rem', border: 0}}
                    />
                  </td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{ActiveType[user.statusCode]?.label}</td>
                  <td>{user.rentCount}</td>
                  <td>{user.dwellingCount}</td>
                  <td>{user.commercialCount}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='8'>
                데이터가 없습니다.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </UserListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <UserModal id={modalInfo.id} show={modalInfo.show} onClose={handleUserModalClose} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const UserListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;