import React, { useState } from 'react';
import styled from 'styled-components';
import { isBlankValue, isPositiveOrZero, isValidEmail, isValidPassword } from '../util/utils';

export const InputComponent = ({ label, infoMessage, type, checkType, id, value, min, max, placeholder, maxLength, onChange, onBlur, onKeyUp, disabled, style, inputStyle}) => {
  const [validation, setValidation] = useState('');

  const handleOnChange = (value) => {
    switch(checkType) {
      case 'text':
        isBlankValue(value) ? setValidation("error") : setValidation("success");
        break;
      case 'positiveNumber':
        isPositiveOrZero(value) ? setValidation("success") : setValidation("error");
        break;
      case 'email':
        if(!isBlankValue(value) && isValidEmail(value)) {
          setValidation("success");
        } else {
          setValidation("error")
        }
        break;
      case 'password':
        isValidPassword(value) ? setValidation("success") : setValidation("error");
        break;
      default:
        break;
    }

    onChange?.(value);
  }

  const handleOnBlur = (value) => {
    onBlur?.(value);
  }

  const handleOnKeyUp = (event) => {
    onKeyUp?.(event);
  }

  //====

  return (
    <InputGroup style={style}>
      <label htmlFor={id}>{label}</label>
      <input className={`form-text ${validation}`}
        type={type}
        id={id}
        value={value}
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        onKeyUp={(e) => handleOnKeyUp(e)}
        maxLength={maxLength}
        autoComplete="off"
        required
        disabled={disabled}
        style={inputStyle}
        />
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </InputGroup>
  );
}

const InputGroup = styled.div`
  position: relative;
  margin: 0;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  & label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
  & input {
    display: block;
    padding: 0rem 0.5rem;
    height: 3rem;
    width: 100%;
    font-size: 1.6rem;
    border: 0rem solid #ddd;
    border-radius: 0.5rem;
    box-sizing: border-box;
  }
  & input:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.success {
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.borderSuccess};
  }
  & input.success:focus-visible {
    outline: ${({ theme }) => theme.colors.borderFocus} auto 0.1rem;
  }
  & input.error {
    border-color: ${({ theme }) => theme.colors.errorBorder};
  }
  & input.error:focus-visible {
    outline: ${({ theme }) => theme.colors.errorBorderFocus} auto 0.1rem;
  }
`;

const InfoMessage = styled.div`
  margin: 0.1rem 0.5rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;
