import { jsonApi } from "../util/AxiosApi";

export class VkpApi {

  fetchInvoiceList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/vkp/invoice`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchInvoiceId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/vkp/invoice/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  sendEmailWithInvoice(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/vkp/invoice/${id}/email`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  uploadVKPFile(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/vkp/upload`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}