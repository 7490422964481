import { jsonApi } from "../util/AxiosApi";

export class CommercialApi {

  fetchCommercialList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/commercial`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchCommercialById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/commercial/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  saveCommercial(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/commercial/${id}`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createCommercialImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/commercial/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteCommercialImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/commercial/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}
