import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { UserApi } from '../../../api/UserApi';
import { InputComponent } from '../../../component/InputComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { Pagination } from '../../../component/Pagination';
import { errorAlert } from '../../../util/ErrorHandler';

export const UserSearchModal = ({show, onClick, onClose}) => {
  const userApi = new UserApi();
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState({
    id: '',
    userName: '',
    name: '',
    email: '',
    phone: '',
    page: 1,
    listSize: 10,
    perPageSize: 15,
  });
  const [result, setResult] = useState(null);

  // == state 변경 ------------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  // handler ---------------------------------------------
  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    changeSearchParam('page', 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    userApi.fetchUserList(
      searchParam,
      (data) => {
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
        // setDialog({isOpen: true, message: error.response.data.error_message, type: "error"});
      }
    );
  }

  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
    // changeSearchData('scrollY', 0);
    // router.push(`user?page=${pageNum}&listSize=${searchParam.listSize}`)
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleUserNameOnChange = (value) => {
    changeSearchParam?.('userName', value);
  }

  const handleNameOnChange = (value) => {
    changeSearchParam?.('name', value);
  }

  const handleEmailOnChange = (value) => {
    changeSearchParam?.('email', value);
  }

  const handlePhoneOnChange = (value) => {
    changeSearchParam?.('phone', value);
  }

  const handleSelectMemberOnClick = ({id, username, name}) => {
    onClick?.(id, username, name);
    handleCloseOnClick();
  }

  useEffect(() => {

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>User Search</h3>
        </Header>
        <div>
          <InputGroup>
            <InputComponent label={'Sign ID'}
              id={'userName'}
              type={'text'} checkType={''}
              value={searchParam.userName} placeholder='입력' maxLength={255}
              onChange={handleUserNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent label={'Name'}
              id={'name'}
              type={'text'} checkType={''}
              value={searchParam.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent label={'Email'}
              id={'email'}
              type={'text'} checkType={''}
              value={searchParam.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '11rem'}}
            />
            <InputComponent label={'Phone'}
              id={'phone'}
              type={'text'} checkType={''}
              value={searchParam.phone} placeholder='입력' maxLength={255}
              onChange={handlePhoneOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '11rem'}}
            />
            <Button type={'button'}
              value={'조회'}
              onClick={()=>handleSearchOnClick()}
            />
          </InputGroup>
          <hr />
        </div>
        <Section>
          <ListSection>
            <table>
              <thead>
                <tr>
                  <TableTh width={50}>#</TableTh>
                  <TableTh width={150}>SignInID</TableTh>
                  <TableTh width={100}>Name</TableTh>
                  <TableTh width={150}>Email</TableTh>
                  <TableTh width={80}>Phone</TableTh>
                  <TableTh width={40}>Active</TableTh>
                </tr>
              </thead>
              <tbody>
                {result?.resultList.map((user) => (
                  <tr key={user.id} onClick={()=>handleSelectMemberOnClick(user)}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.statusCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ListSection>
          <Pagination
            currentPage={searchParam.page}
            totalPage={searchParam.totalPageCount}
            perPageSize={searchParam.perPageSize}
            handlePageChange={handlePageChange}/>
        </Section>
      </ModalWindow>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalWindow = styled.div`
  position: relative;
  margin: 0;
  padding: 0 0 2rem 0;
  width: 90rem;
  height: auto;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  & > div {
    margin-right: 1rem;
  }
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0rem solid #ddd;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const ListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 30rem;
  border: 0rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
