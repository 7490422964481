import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { CommercialApi } from '../../../api/CommercialApi';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { CheckBoxComponent } from '../../../component/CheckBoxComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  CommercialAdditionalFacilities,
  CommercialFlagTypes,
  CommercialIncludedOptions,
  CommercialTypes,
  CommercialUnits,
  ContactTypes,
  Premium,
  RealEstateCities,
  RealEstateProvinces,
} from '../../../__mocks__/PostData';

export const CommercialDetail = ({setDialog}) => {
  const commercialApi = new CommercialApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 12,
  });
  const [commercialDetail, setCommercialDetail] = useState({
    id: id,
    boardId: 12,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeCommercialDetailData = (name, value) => {
    setCommercialDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeCommercialDetailImages = (index, imageData) => {
    setCommercialDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteCommercialDetailImages = (index) => {
    setCommercialDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {

    setLoading(true);
    commercialApi.fetchCommercialById(
      searchParam.id,
      (data) => {
        setCommercialDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {

    const formData = {
      'id': commercialDetail.id,
      'boardId': commercialDetail.boardId,
      'active': commercialDetail.active,
      'password': commercialDetail.password,
      'memberId': commercialDetail.memberId,
      'type': commercialDetail.type,
      'name': commercialDetail.name,
      'email': commercialDetail.email,
      'phone': commercialDetail.phone,
      'contactCode': commercialDetail.contactCode,
      'title': commercialDetail.title,
      'description': editorRef.current.getContent(),
      'price': commercialDetail.price,
      'hitCount': commercialDetail.hitCount,
      'isMainDisplay': commercialDetail.isMainDisplay,
      'premium': commercialDetail.premium,
      'premiumStart': commercialDetail.premiumStart,
      'premiumEnd': commercialDetail.premiumEnd,
      'link': commercialDetail.link,
      'summary': commercialDetail.summary,
      'isSoldOut': commercialDetail.isSoldOut,
      'locationId': commercialDetail.locationId,
      'locationSubId': commercialDetail.locationSubId,
      'address': commercialDetail.address,
      'postal': commercialDetail.postal,

      'flagType': commercialDetail.flagType,
      'rentalPrice': commercialDetail.rentalPrice,
      'monthlySales': commercialDetail.monthlySales,
      'builtIn': commercialDetail.builtIn,

      'indoorSpaceUnit': commercialDetail.indoorSpaceUnit,
      'indoorSpace': commercialDetail.indoorSpace,
      'landSpaceUnit': commercialDetail.landSpaceUnit,
      'landSpace': commercialDetail.landSpace,

      'floorNumber': commercialDetail.floorNumber,
      'numberParkingSpaces': commercialDetail.numberParkingSpaces,

      'operationPeriod': commercialDetail.operationPeriod,
      'leasePeriod': commercialDetail.leasePeriod,

      'includedOptions': commercialDetail.includedOptions,
      'additionalFacilities': commercialDetail.additionalFacilities,
    };
    setLoading(true);
    commercialApi.saveCommercial(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(commercialDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', commercialDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      commercialApi.createCommercialImageFile(
        commercialDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeCommercialDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(commercialDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': commercialDetail.id,
      });

      commercialApi.deleteCommercialImageFile(
        commercialDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteCommercialDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeCommercialDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeCommercialDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeCommercialDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeCommercialDetailData(key, true);
    else
      changeCommercialDetailData(key, false);
  }

  const handleMemberNumberChange = (value) => {
    changeCommercialDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeCommercialDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeCommercialDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeCommercialDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeCommercialDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeCommercialDetailData('contactCode', value);
  }

  const handleFlagTypeOnChange = (value) => {
    changeCommercialDetailData('flagType', value);
  }

  const handleTypeOnChange = (value) => {
    changeCommercialDetailData('type', value);
  }

  const handlePriceOnChange = (value) => {
    changeCommercialDetailData('price', value);
  }

  const handleRentalPriceOnChange = (value) => {
    changeCommercialDetailData('rentalPrice', value);
  }

  const handleMonthlySalesOnChange = (value) => {
    changeCommercialDetailData('monthlySales', value);
  }

  const handleSummaryOnChange = (value) => {
    changeCommercialDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeCommercialDetailData('link', value.trim())
  }

  const handleLocationIdOnChange = ({target}) => {
    changeCommercialDetailData('locationId', target.value);
  }

  const handleLocationSubIdOnChange = ({target}) => {
    changeCommercialDetailData('locationSubId', target.value);
  }

  const handleAddressOnChange = (value) => {
    changeCommercialDetailData('address', value.trim());
  }

  const handlePostalOnChange = (value) => {
    changeCommercialDetailData('postal', value.trim());
  }

  const handlePremiumOnChange = ({target}) => {
    changeCommercialDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeCommercialDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeCommercialDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeCommercialDetailData('title', value.trim());
  }

  const handleBuiltInOnChange = (value) => {
    changeCommercialDetailData('builtIn', value);
  }

  const handleIndoorSpaceOnChange = (value) => {
    changeCommercialDetailData('indoorSpace', value);
  }

  const handleIndoorSpaceUnitOnChange = ({target}) => {
    changeCommercialDetailData('indoorSpaceUnit', target.value);
  }

  const handleLandSpaceOnChange = (value) => {
    changeCommercialDetailData('landSpace', value);
  }

  const handleLandSpaceUnitOnChange = ({target}) => {
    changeCommercialDetailData('landSpaceUnit', target.value);
  }

  const handleFloorNumberOnChange = (value) => {
    changeCommercialDetailData('floorNumber', value);
  }

  const handleNumberParkingSpacesOnChange = (value) => {
    changeCommercialDetailData('numberParkingSpaces', value);
  }

  const handleOperationPeriodOnChange = (value) => {
    changeCommercialDetailData('operationPeriod', value);
  }

  const handleLeasePeriodOnChange = (value) => {
    changeCommercialDetailData('leasePeriod', value);
  }

  const handleSoldOutOnChange = (key, value) => {
    if(value)
      changeCommercialDetailData(key, true);
    else
      changeCommercialDetailData(key, false);
  }

  const handleIncludedOptionsOnChange = (value) => {
    changeCommercialDetailData('includedOptions', value);
  }

  const handleAdditionalFacilitiesOnChange = (value) => {
    changeCommercialDetailData('additionalFacilities', value);
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            {link:"/",name:"게시판 관리",isLink:false},
            {link:`/market/commercial`, name:"비즈매매",isLink:true},
            {link:`/market/commercial/${searchParam?.id}`, name:`${searchParam?.id}`,isLink:true}]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'}
            checkType={''}
            value={commercialDetail.id}
            placeholder={'입력'}
            maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={commercialDetail?.active}
            objectData={ActiveTypeList}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'}
            checkType={''}
            value={commercialDetail.hitCount}
            placeholder='입력'
            min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={commercialDetail.password}
            placeholder={''}
            min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={commercialDetail.memberEmail}
            placeholder={''}
            maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={commercialDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={commercialDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={commercialDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={commercialDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={commercialDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={commercialDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'임대인'}
            id={'name'}
            type={'text'} checkType={''}
            value={commercialDetail.name}
            placeholder='입력'
            maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={commercialDetail.phone}
            placeholder='입력'
            maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={commercialDetail.email}
            placeholder='입력'
            maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={commercialDetail?.contactCode}
            objectData={ContactTypes}
            // infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
            onChange={handleContactCodeOnChange}
          />
          <SelectComponentWithObject
            label={'주'}
            id={'locationId'}
            value={commercialDetail?.locationId}
            objectData={RealEstateProvinces}
            onChange={handleLocationIdOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'도시'}
            id={'locationSubId'}
            value={commercialDetail?.locationSubId}
            objectData={RealEstateCities}
            onChange={handleLocationSubIdOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'나머지 주소'}
            id={'address'}
            type={'text'}
            checkType={''}
            value={commercialDetail.address}
            placeholder={'예) 331A-4501 North Road'}
            maxLength={255}
            onChange={handleAddressOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'우편번호'}
            id={'postal'}
            type={'text'}
            checkType={''}
            value={commercialDetail.postal}
            placeholder={'예) V3N 4R7'}
            maxLength={10}
            onChange={handlePostalOnChange}
            style={{width: '10rem'}}
          />
          <RadioComponents
            label={'플래그'}
            id={'flagType'}
            value={commercialDetail?.flagType||''}
            objectData={CommercialFlagTypes}
            onChange={handleFlagTypeOnChange}
          />
          <RadioComponents
            label={'건물타입'}
            id={'type'}
            value={commercialDetail?.type||''}
            objectData={CommercialTypes}
            onChange={handleTypeOnChange}
          />
          <InputComponent
            label={'매매가'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail?.price||0}
            placeholder={'예) 1000달러 -> 1000'}
            maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'렌트비'}
            id={'rentalPrice'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail?.rentalPrice||0}
            placeholder={'예) 1000달러 -> 1000'}
            maxLength={20}
            onChange={handleRentalPriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'월매출'}
            id={'monthlySales'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail?.monthlySales||0}
            placeholder={'예) 1000달러 -> 1000'}
            maxLength={20}
            onChange={handleMonthlySalesOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'완공연도'}
            id={'builtIn'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.builtIn}
            placeholder={'년'}
            maxLength={10}
            onChange={handleBuiltInOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'실내 면적'}
            id={'indoorSpace'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.indoorSpace}
            placeholder={'예) 1000'}
            maxLength={10}
            onChange={handleIndoorSpaceOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <SelectComponentWithObject
            label={'실내 면적 단위'}
            id={'indoorSpaceUnit'}
            value={commercialDetail?.indoorSpaceUnit}
            objectData={CommercialUnits}
            onChange={handleIndoorSpaceUnitOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'대지 면적'}
            id={'landSpace'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.landSpace}
            placeholder='예) 1000' maxLength={10}
            onChange={handleLandSpaceOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <SelectComponentWithObject
            label={'대지 면적 단위'}
            id={'landSpaceUnit'}
            value={commercialDetail?.landSpaceUnit}
            objectData={CommercialUnits}
            onChange={handleLandSpaceUnitOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'층(수)'}
            id={'floorNumber'}
            type={'text'}
            checkType={''}
            value={commercialDetail.floorNumber}
            placeholder={'입력'}
            maxLength={20}
            onChange={handleFloorNumberOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'주차대수'}
            id={'numberParkingSpaces'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.numberParkingSpaces}
            placeholder={'예) 1개 -> 1'}
            maxLength={20}
            onChange={handleNumberParkingSpacesOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'운영기간'}
            id={'operationPeriod'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.operationPeriod}
            placeholder={'예) 10개월 -> 10'}
            maxLength={20}
            onChange={handleOperationPeriodOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'리스기간'}
            id={'leasePeriod'}
            type={'number'}
            checkType={'positiveNumber'}
            value={commercialDetail.leasePeriod}
            placeholder={'예) 10개월 -> 10'}
            maxLength={20}
            onChange={handleLeasePeriodOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <SwitchComponent
            label={'Sold Out'}
            id={'isSoldOut'}
            value={commercialDetail?.isSoldOut}
            checkedValue={true}
            onChange={handleSoldOutOnChange}
            style={{width: '9.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <CheckBoxComponent
            label={'포함 사항'}
            id='includedOptions'
            value={commercialDetail?.includedOptions||''}
            delimiter={''}
            objectData={CommercialIncludedOptions}
            onChange={handleIncludedOptionsOnChange}
          />
          <CheckBoxComponent
            label={'부대 시설'}
            id='additionalFacilities'
            value={commercialDetail?.additionalFacilities||''}
            delimiter={''}
            objectData={CommercialAdditionalFacilities}
            onChange={handleAdditionalFacilitiesOnChange}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={commercialDetail.title}
            placeholder='입력' maxLength={255}
            style={{width: '100%'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={commercialDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={commercialDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    margin: 0 0.1rem;
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
