import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { UserApi } from '../../../api/UserApi';
import { InputComponent } from '../../../component/InputComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import { BlockIPType } from '../../../__mocks__/PostData';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';

export const BlockIPModal = ({id, type, show, onClose}) => {
  const userApi = new UserApi();
  const [loading, setLoading] = useState(false);

  const [blockIpInfo, setBlockIpInfo] = useState({
    id: '',
    type: type,
    ip: '',
    memo: '',
    registrationDate: '',
  });

  // == state 변경 ------------------------------------------------
  const init = () => {
    setBlockIpInfo({
      id: '',
      type: type,
      ip: '',
      memo: '',
      registrationDate: '',
    })
  };
  const changeBlockIpInfoData = (key, value) => {
    setBlockIpInfo((values) => {
      const updated = {...values};
      updated[key] = value;
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    handleSearchBlockIPOnClick();
  }

  const handleSearchBlockIPOnClick = () => {
    setLoading(true);
    const param = {
      id: id,
      type: type,
      page: 1,
      listSize: 10,
      perPageSize: 15,
    };
    userApi.fetchBlockIPById(
      param,
      (data) => {
        setBlockIpInfo(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleCreateOnClick = () => {
    setLoading(true);
    userApi.createBlockIPInfo(
      blockIpInfo,
      (data) => {
        setLoading(false);
        handleCloseOnClick();
        alert(data.message);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    setLoading(true);
    userApi.saveBlockIPInfo(
      id,
      blockIpInfo,
      (data) => {
        setLoading(false);
        handleCloseOnClick();
        alert(data.message);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleDeleteOnClick = () => {
    setLoading(true);
    userApi.deleteBlockIPInfo(
      id,
      blockIpInfo,
      (data) => {
        setLoading(false);
        handleCloseOnClick();
        alert(data.message);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmCreate = useConfirm(
    "신규 저장 하시겠습니까?",
    handleCreateOnClick,
    ()=>{},
  );

  const confirmSave = useConfirm(
    "저장 하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const confirmDelete = useConfirm(
    "삭제 하시겠습니까?",
    handleDeleteOnClick,
    ()=>{},
  );

  const handleIPOnChange = (value) => {
    changeBlockIpInfoData('ip', value);
  }

  const handleMemoOnChange = (value) => {
    changeBlockIpInfoData('memo', value);
  }

  useEffect(() => {
    init();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>User Information</h3>
        </Header>
        <div>
          <h4>General Information</h4>
          <InputGroup>
            <InputComponent label={'#'}
              id={'id'}
              type={'text'} checkType={''}
              value={blockIpInfo.id} placeholder='' maxLength={255}
              style={{width: '44%'}}
              disabled={true}
            />
            <SelectComponentWithObject label={'Type'}
              id={'type'}
              value={blockIpInfo.type}
              objectData={BlockIPType}
              style={{width: '20.2rem'}}
              disabled={true}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'IP'}
              id={'ip'}
              type={'text'} checkType={''}
              value={blockIpInfo.ip} placeholder='' maxLength={255}
              style={{width: '44%'}}
              onChange={handleIPOnChange}
              disabled={id?true:false}
            />
            <InputComponent label={'등록일'}
              id={'registrationDate'}
              type={'registrationDate'} checkType={''}
              value={blockIpInfo.registrationDate} placeholder='입력' maxLength={255}
              style={{width: '44%'}}
              disabled={true}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'메모'}
              id={'memo'}
              type={'memo'} checkType={''}
              value={blockIpInfo.memo} placeholder='입력' maxLength={255}
              onChange={handleMemoOnChange}
              style={{width: '93%'}}
            />
          </InputGroup>
        </div>
        <ButtonGroup>
          {id
          ? (
            <>
              <Button type='button' value={'삭제'} onClick={confirmDelete} />
              <Button type='button' value={'저장'} onClick={confirmSave} />
            </>
          ) : (
            <Button type='button' value={'저장'} onClick={confirmCreate} />
          )}
          <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
        </ButtonGroup>
      </ModalWindow>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 50rem;
  height: 30rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;