import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { RentApi } from '../../../api/RentApi';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { Pagination } from '../../../component/Pagination';
import { Button } from '../../../component/Button';
import { Loader } from '../../../component/Loader';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  ListSize,
  PostActive,
  PremiumCode
} from '../../../__mocks__/PostData';

export const RentList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "rent_search";
  const rentApi = new RentApi();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [searchParam, setSearchParam] = useState(()=>{
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          id: location?.state?.id ? location?.state?.id: '',
          userId: location?.state?.userId ? location?.state?.userId: '',
          title: '',
          phone: '',
          email: '',
          active: '',
          premium: '',
          page: 1,
          listSize: 100,
          perPageSize: 15,
          totalCount: 0,
          totalPageCount: 0,
        }
      }
    }
  });
  const [result, setResult] = useState(null);

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      id: location?.state?.id ? location?.state?.id: '',
      userId: location?.state?.userId ? location?.state?.userId: '',
      title: '',
      phone: '',
      email: '',
      active: '',
      premium: '',
      page: 1,
      listSize: 100,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // == handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam('page', 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    rentApi.fetchRentList(
      searchParam,
      (data) => {
        setLoading(false);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
      },
      (error) => {
        setLoading(false);
        setResult(null);
        errorAlert(error);
      }
    );
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }

  const handleUserIdOnChange = (value) => {
    changeSearchParam?.('userId', value);
  }

  const handleTitleOnChange = (value) => {
    changeSearchParam?.('title', value);
  }

  const handlePhoneOnChange = (value) => {
    changeSearchParam?.('phone', value);
  }

  const handleEmailOnChange = (value) => {
    changeSearchParam?.('email', value);
  }

  const handleActiveOnChange = ({target}) => {
    changeSearchParam("active" , target.value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeSearchParam("premium" , target.value);
  }

  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }

  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleRentDetailOnClick = (id) => {
    saveSearchData();
    navigate(`/market/rent/${id}`);
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "부동산 콘텐츠", isLink: false },
            { link: "/market/rent", name: "렌트", isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'#'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam.id}
              placeholder={'ID 입력'} min={0} maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent label={'사용자 #'}
              id={'userId'}
              type={'number'} checkType={''}
              value={searchParam.userId}
              placeholder={'입력'} min={0} maxLength={255}
              onChange={handleUserIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent label={'제목'}
              id={'title'}
              type={'text'} checkType={''}
              value={searchParam.title}
              placeholder={''} maxLength={255}
              onChange={handleTitleOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <InputComponent label={'전화번호'}
              id={'phone'}
              type={'text'} checkType={''}
              value={searchParam.phone}
              placeholder={''} maxLength={255}
              onChange={handlePhoneOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent label={'이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={searchParam.email}
              placeholder={''} maxLength={255}
              onChange={handleEmailOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <SelectComponents label={'상태'}
              id={'active'}
              value={searchParam.active}
              objectData={[{ value: '', label: '전체' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents
              label={'Premium'}
              id={'premium'}
              value={searchParam.premium}
              objectData={PremiumCode}
              onChange={handlePremiumOnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents
              label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={ListSize}
              onChange={handleListSizeOnChange}
              style={{width: '8rem'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <UserListSection>
        <table>
          <thead>
            <tr>
              <TableTh width={80}>#</TableTh>
              <TableTh width={80}>상태</TableTh>
              <TableTh width={250}>제목</TableTh>
              <TableTh width={100}>가격</TableTh>
              <TableTh width={200}>임대인</TableTh>
              <TableTh width={200}>전화번호</TableTh>
              <TableTh width={200}>이메일</TableTh>
              <TableTh width={50}>조회</TableTh>
              <TableTh width={200}>수정 / 등록일</TableTh>
            </tr>
          </thead>
          <tbody>
            {0 < result?.resultList?.length ? (
              <>
                {result?.resultList.map((post) => (
                  <tr key={post.id} onClick={() => handleRentDetailOnClick(post.id)}>
                    <td>
                      <input
                        id={`id${post.id}`}
                        type={'text'}
                        value={post.id}
                        disabled
                        style={{width: '98%', height: '2.3rem', textAlign: 'right', border: 0}}
                      />
                    </td>
                    <td>{PostActive[post?.active]}</td>
                    <td>{post.title}</td>
                    <td>{post.price}</td>
                    <td>{post.name}</td>
                    <td>{post.phone}</td>
                    <td>{post.email}</td>
                    <td>{post.hitCount}</td>
                    <td>
                      {`수정: ${post?.updatedDate}`}<br/>
                      {`등록: ${post?.postedDate}`}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan='9'>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </UserListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const UserListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;
