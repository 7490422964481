import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Dialog = ({isOpen, handleClose, message, type}) => {

  // const handleClose = () => {
  //   setOpen(false);
  //   return 0;
  // }

  const modals = {
    'error': <ErrorModal open={isOpen} handleClose={handleClose} message={message}></ErrorModal>,
    'alert': <AlertModal open={isOpen} handleClose={handleClose} message={message}></AlertModal>,
    'nonHeader': <NonHeaderModal open={isOpen} handleClose={handleClose} message={message}></NonHeaderModal>,
  }

  const modal = modals[type];

  return (
    <div>
      {modal}
    </div>
  );
}

const ErrorModal = ({open, handleClose, message}) => {
  const handleCloseOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleClose();
    }
  }
  return (
    <>
      {open ? (
        <Modal className={open && 'openModal'}>
          <section>
            <header>
              {`Error `}
              <button className="close" onClick={handleClose} onKeyUp={handleCloseOnKeyUp}>
                {' '}
                &times;{' '}
              </button>
            </header>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose} onKeyUp={handleCloseOnKeyUp}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      ) : null}
    </>
  );
}

const AlertModal = ({open, handleClose, message}) => {
  const handleCloseOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleClose();
    }
  }
  return (
    <>
      { open && (
        <Modal className={open && 'openModal'}>
          <section>
            <header>
              {`Alert`}
              <button className="close" onClick={handleClose} onKeyUp={handleCloseOnKeyUp}>
                {' '}
                &times;{' '}
              </button>
            </header>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose} onKeyUp={handleCloseOnKeyUp}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      )}
    </>
  );
}

const NonHeaderModal = ({open, handleClose, message}) => {

  return (
    <>
      { open && (
        <Modal className={open && 'openModal'}>
          <section>
            <main>{message}</main>
            <footer>
              <button className="close" onClick={handleClose}>
                {' '}
                close{' '}
              </button>
            </footer>
          </section>
        </Modal>
      )}
    </>
  );
}

const modalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;
const modalBgShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  & button {
    outline: none;
    cursor: pointer;
    border: 0;
  }
  & section {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: ${modalShow} 0.3s;
    overflow: hidden;
    & header {
      position: relative;
      padding: 16px 64px 16px 16px;
      background-color: #f1f1f1;
      font-weight: 700;
      & button {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        color: #999;
        background-color: transparent;
      }
      & button.nonHeader {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        color: #999;
        background-color: transparent;
      }
    }
    & main {
      padding: 16px;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
    }
    & footer {
      padding: 12px 16px;
      text-align: right;
      & button {
        padding: 6px 12px;
        color: #fff;
        background-color: #6c757d;
        border-radius: 5px;
        font-size: 13px;
      }
    }
  }
  & div.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: ${modalBgShow} 0.3s;
  }
`;
