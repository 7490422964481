import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { CheckBoxComponent } from '../../../component/CheckBoxComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { TutorApi } from '../../../api/TutorApi';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveType,
  Cities,
  ContactTypes,
  Premium,
  TutorCategories,
  TutorLearnerNumber,
  TutorLearners,
  TutorLectureLanguages,
  TutorLectureType,
  TutorRate,
  TutorTypes,
} from '../../../__mocks__/PostData';

export const TutorDetail = ({setDialog}) => {
  const tutorApi = new TutorApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 6,
  });
  const [tutorDetail, setTutorDetail] = useState({
    id: 0,
    boardId: 6,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeTutorDetailData = (name, value) => {
    setTutorDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeTutorDetailImages = (index, imageData) => {
    setTutorDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteTutorDetailImages = (index) => {
    setTutorDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    tutorApi.fetchTutorById(
      searchParam.id,
      (data) => {
        setLoading(false);
        setTutorDetail({});
        setTutorDetail(data?.result);
      },
      (error) => {
        setLoading(false);
        setTutorDetail({});
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders(null);
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data);
      },
      (error) => {
        errorAlert(error);
      },
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': tutorDetail.id,
      'boardId': tutorDetail.boardId,
      'active': tutorDetail.active,
      'hitCount': tutorDetail.hitCount,
      'password': tutorDetail.password,
      'memberId': tutorDetail.memberId,

      'isMainDisplay': tutorDetail.isMainDisplay,
      'premium': tutorDetail.premium,
      'premiumStart': tutorDetail.premiumStart,
      'premiumEnd': tutorDetail.premiumEnd,
      'summary': tutorDetail.summary,
      'link': tutorDetail.link,

      'name': tutorDetail.name,
      'phone': tutorDetail.phone,
      'email': tutorDetail.email,
      'contactCode': tutorDetail.contactCode,

      'type': tutorDetail.type,
      'categoryCode': tutorDetail.categoryCode,
      'learners': tutorDetail.learners,
      'location': tutorDetail.location,
      'lectureType': tutorDetail.lectureType,
      'learnerNumber': tutorDetail.learnerNumber,
      'lectureLanguages': tutorDetail.lectureLanguages,
      'tutorRate': tutorDetail.tutorRate,
      'price': tutorDetail.price,

      'title': tutorDetail.title,
      'description': editorRef.current.getContent(),
    };
    setLoading(true);
    tutorApi.saveTutor(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(tutorDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', tutorDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      tutorApi.createTutorImageFile(
        tutorDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeTutorDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(tutorDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': tutorDetail.id,
      });

      tutorApi.deleteTutorImageFile(
        tutorDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteTutorDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeTutorDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeTutorDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeTutorDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeTutorDetailData('isMainDisplay', true);
    else
      changeTutorDetailData('isMainDisplay', false);
  }

  const handleMemberNumberChange = (value) => {
    changeTutorDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeTutorDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeTutorDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeTutorDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeTutorDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeTutorDetailData('contactCode', value);
  }

  const handleTypeOnChange = (value) => {
    changeTutorDetailData('type', value);
  }

  const handleCategoryCodeOnChange = (value) => {
    changeTutorDetailData('categoryCode', value);
  }

  const handleLearnersOnChange = (value) => {
    changeTutorDetailData('learners', value);
  }

  const handlePriceOnChange = (value) => {
    changeTutorDetailData('price', value);
  }

  const handleSummaryOnChange = (value) => {
    changeTutorDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeTutorDetailData('link', value.trim())
  }

  const handleLocationOnChange = ({target}) => {
    changeTutorDetailData('location', target.value);
  }

  const handleLectureTypeOnChange = (value) => {
    changeTutorDetailData('lectureType', value)
  }

  const handleLearnerNumberOnChange = (value) => {
    changeTutorDetailData('learnerNumber', value)
  }

  const handleLectureLanguagesOnChange = (value) => {
    changeTutorDetailData('lectureLanguages', value)
  }

  const handleTutorRateOnChange = (value) => {
    changeTutorDetailData('tutorRate', value)
  }

  const handlePremiumOnChange = ({target}) => {
    changeTutorDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeTutorDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeTutorDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeTutorDetailData('title', value.trim());
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/tutor`, name: "학원/튜터", isLink: true },
            { link: `/market/tutor/${searchParam?.id}`, name: `${searchParam?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={tutorDetail?.id} placeholder='입력' maxLength={255}
            style={{width: '100px'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={tutorDetail?.active}
            objectData={ActiveType}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={tutorDetail?.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={tutorDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={tutorDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={tutorDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={tutorDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={tutorDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={tutorDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={tutorDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={tutorDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'업체/튜터명'}
            id={'name'}
            type={'text'} checkType={''}
            value={tutorDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={tutorDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={tutorDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={tutorDetail?.contactCode}
            objectData={ContactTypes}
            // infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
            onChange={handleContactCodeOnChange}
          />
          <RadioComponents
            label={'구분'}
            id={'type'}
            value={tutorDetail?.type}
            objectData={TutorTypes}
            onChange={handleTypeOnChange}
          />
          <RadioComponents
            label={'교습과목'}
            id={'categoryCode'}
            value={tutorDetail?.categoryCode}
            objectData={TutorCategories}
            onChange={handleCategoryCodeOnChange}
          />
          <CheckBoxComponent
            label={'교습대상'}
            id='learners'
            value={tutorDetail?.learners}
            delimiter={','}
            objectData={TutorLearners}
            onChange={handleLearnersOnChange}
          />
          <SelectComponentWithObject
            label={'지역'}
            id={'location'}
            value={tutorDetail?.location}
            objectData={Cities}
            onChange={handleLocationOnChange}
            style={{width: '12rem'}}
          />
          <CheckBoxComponent
            label={'수업모드'}
            id='lectureType'
            value={tutorDetail?.lectureType}
            delimiter={','}
            objectData={TutorLectureType}
            onChange={handleLectureTypeOnChange}
          />
          <CheckBoxComponent
            label={'학습인원'}
            id='learnerNumber'
            value={tutorDetail?.learnerNumber}
            delimiter={','}
            objectData={TutorLearnerNumber}
            onChange={handleLearnerNumberOnChange}
          />
          <CheckBoxComponent
            label={'수업언어'}
            id='lectureLanguages'
            value={tutorDetail?.lectureLanguages}
            delimiter={','}
            objectData={TutorLectureLanguages}
            onChange={handleLectureLanguagesOnChange}
          />
          <RadioComponents
            label={'수업료 Rate'}
            id={'tutorRate'}
            value={tutorDetail?.tutorRate}
            objectData={TutorRate}
            onChange={handleTutorRateOnChange}
          />
          <InputComponent
            label={'수업료'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={tutorDetail.price} placeholder='입력' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={tutorDetail.title} placeholder='입력' maxLength={255}
            style={{width: '50rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={tutorDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={tutorDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {orders?.resultList?.map((item) => (
                <tr key={item?.id} onClick={()=>handleOrderOnClick(item?.id)}>
                  <td>{item?.id}</td>
                  <td>{item?.orderDateTime}</td>
                  <td>{item?.paymentType}</td>
                  <td>{item?.status}</td>
                  <td>{item?.grandTotalAmount}</td>
                  <td>{item?.totalItemAmount}</td>
                  <td>{item?.totalGstAmount}</td>
                  <td>
                    {item?.memberEmail}
                    <br/>
                    {item?.memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
