
export const errorAlert = (error) => {
  if(error?.response) {
    console.log(error.response);
    const response = error?.response;
    const status = response?.status;
    if(status) {
      switch (status) {
        case 400:
          const data = response?.data;
          if('InputNotValid' === data?.code) {
            alert(`${data?.message}`);
          } else {
            alert(`[${status}] ${response?.statusText}`);
          }
          break;
        case 403:
          alert(`[${status}] 로그인을 다시 해주세요.`);
          window.location.href = '/signIn';
          break;
        case 500:
          alert(`[${status}] ${response?.statusText}`);
          break;
        case 503:
          alert(`[${status}] ${response?.statusText}`);
          break;
        default:
          alert(`[err] ${error}`);
          break;
      }
    } else {
      alert(`[error] ${error}`);
    }
  } else {
    console.log(error);
    alert(`[e] ${error}`);
  }

}