import React from 'react';
import styled from 'styled-components';

export const RadioComponents = ({ label, infoMessage, id, value, objectData, onChange, disabled}) => {

  const handleOnChange = ({target}) => {
    onChange?.(target.value);
  }

  return (
    <RadioGroup>
      <label htmlFor={id}>{label}</label>
      <div className='radios'>
        {Object.keys(objectData).map((key) =>
          <div key={key}>
            <input
              id={`radio-${id}-${key}`}
              name={`radio-${id}-${key}`}
              type="radio"
              checked={objectData[key].value === value}
              onChange={handleOnChange}
              value={objectData[key].value}
              disabled={disabled}
            />
            <label htmlFor={`radio-${id}-${key}`}>{objectData[key].label}</label>
          </div>
        )}
      </div>
      {infoMessage && (
        <InfoMessage>
          <img src="/icon/info-icon.png" alt="정보 아이콘" />
          {infoMessage}
        </InfoMessage>
      )}
    </RadioGroup>
  );
}

const RadioGroup = styled.div`
  position: relative;
  margin: 0;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  & > label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
  & div.radios {
    padding: 0.3rem 0.5rem;
    height: 3rem;
    width: 100%;

    display: flex;
    box-sizing: border-box;
    & div {
      display: flex;
      align-items: center;
      & input {
        margin: 0;
        width: 1.8rem;
        height: 1.8rem;
      }
      & input[type=radio] {
        accent-color: #f86258;
      }
      & label {
        margin: 0 0.5rem;
        font-size: 14px;
      }
    }
  }
`;

const InfoMessage = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 1.7rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: -0.04em;
  color: #9E9E9E;

  display: flex;
  align-items: center;
  & img {
    margin-right: 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;