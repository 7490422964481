import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { OrderApi } from '../../../api/OrderApi';
import { PaymentApi } from '../../../api/PaymentApi';
import { InputComponent } from '../../../component/InputComponent';
import { BoardType } from '../../../__mocks__/PostData';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../component/Button';
import { errorAlert } from '../../../util/ErrorHandler';

export const OrderDetailModal = ({id, show, onClose}) => {
  const navigate = useNavigate();
  const orderApi = new OrderApi();
  const paymentApi = new PaymentApi();
  const [orderDetail, setOrderDetail] = useState({
    orderId: id,
    refType: '',
    refPostId: '',
    refBoardId: '',
    orderDateTime: '',
    paymentType: '',
    status: '',
    grandTotalAmount: '',
    totalItemAmount: '',
    totalGstAmount: '',
    totalPstAmount: '',
    memberId: '',
    memberEmail: '',
    regDateTime: '',
    regMemberId: '',
    modDateTime: '',
    modMemberId: '',
    details: [],
  });
  const [paymentLog, setPaymentLog] = useState([]);

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    orderApi.fetchOrderById(
      id,
      (data) => {
        setOrderDetail(data?.result);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSearchPaymentLog = (id) => {
    const searchData = { id: id };
    paymentApi.fetchPayment(
      searchData,
      (data) => {
        setPaymentLog(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleMoveListOnClick = () => {
    const boardCode = BoardType[orderDetail.refBoardId]?.code;
    if(boardCode && orderDetail.refPostId) {
      navigate(`/${boardCode}`, {
        state: {
          id: orderDetail.refPostId,
        },
      });
    }
  }

  useEffect(() => {
    if(id) {
      handleSearchOnClick();
      handleSearchPaymentLog(id);
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>주문 정보</h3>
        </Header>
        <div>
          <InputGroup>
            <InputComponent label={'주문번호'} type={'text'} checkType={''}
              id={'id'}
              value={orderDetail.id} placeholder='입력' maxLength={255}
              style={{width: '100px'}}
              disabled={true}
            />
            <InputComponent label={'주문일시'} type={'text'} checkType={''}
              id={'orderDateTime'}
              value={orderDetail.orderDateTime} placeholder='입력' maxLength={255}
              style={{width: '160px', textAlign: 'center'}}
              disabled={true}
            />
            <InputComponent label={'결제방법'} type={'text'} checkType={''}
              id={'paymentType'}
              value={orderDetail.paymentType} placeholder='입력' maxLength={255}
              style={{width: '200px'}}
              disabled={true}
            />
            <InputComponent label={'상태'} type={'text'} checkType={''}
              id={'status'}
              value={orderDetail.status} placeholder='입력' maxLength={255}
              style={{width: '200px'}}
              disabled={true}
            />
            <InputComponent label={'총 주문금액'} type={'text'} checkType={''}
              id={'grandTotalAmount'}
              value={orderDetail.grandTotalAmount} placeholder='입력' maxLength={255}
              style={{width: '130px', textAlign: 'right'}}
            />
            <InputComponent label={'총 금액'} type={'text'} checkType={''}
              id={'totalItemAmount'}
              value={orderDetail.totalItemAmount} placeholder='입력' maxLength={255}
              style={{width: '130px', textAlign: 'right'}}
            />
            <InputComponent label={'총 GST'} type={'text'} checkType={''}
              id={'totalGstAmount'}
              value={orderDetail.totalGstAmount} placeholder='입력' maxLength={255}
              style={{width: '130px', textAlign: 'right'}}
            />
            <InputComponent label={'주문 회원'} type={'text'} checkType={''}
              id={'memberEmail'}
              value={orderDetail.memberEmail} placeholder='입력' maxLength={255}
              style={{width: '180px'}}
              disabled={true}
            />
          </InputGroup>
          <h4>게시물 정보</h4>
          <InputGroup>
            <InputComponent label={'게시판'} type={'text'} checkType={''}
              id={'refType'}
              value={`${orderDetail.refType} (${orderDetail.refBoardId})`} placeholder='입력' maxLength={255}
              style={{width: '250px'}}
            />
            <InputComponent label={'게시판 번호'} type={'text'} checkType={''}
              id={'refPostId'}
              value={orderDetail.refPostId} placeholder='입력' maxLength={255}
              style={{width: '250px'}}
            />
            <Button
              type='button'
              value={'바로가기'}
              onClick={()=> handleMoveListOnClick()}
              style={{ color: '#fff', backgroundColor: '#0088cc'}}
            />
          </InputGroup>

          <OrderDetailListSection>
            <table>
              <thead>
                <tr>
                  <TableTh width={40}>#</TableTh>
                  <TableTh width={40}>상품 #</TableTh>
                  <TableTh width={120}>상품명</TableTh>
                  <TableTh width={200}>상품설명</TableTh>
                  <TableTh width={50}>금액</TableTh>
                  <TableTh width={50}>GST</TableTh>
                  <TableTh width={50}>수량</TableTh>
                  <TableTh width={50}>총 금액</TableTh>
                  <TableTh width={50}>총 GST</TableTh>
                  <TableTh width={50}>총 합계</TableTh>
                </tr>
              </thead>
              <tbody>
              {orderDetail?.details?.map((order) => (
                <tr key={order.seq} >
                  <td>{order.seq}</td>
                  <td>{order.itemId}</td>
                  <td>{order.itemKrName}</td>
                  <td>{order.itemDescription}</td>
                  <td>{order.baseItemAmount}</td>
                  <td>{order.baseGstAmount}</td>
                  <td>{order.quantity}</td>
                  <td>{order.salesItemAmount}</td>
                  <td>{order.salesGstAmount}</td>
                  <td>{order.salesGrandTotalAmount}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </OrderDetailListSection>
          <OrderDetailListSection>
            <table>
              <thead>
                <tr>
                  <TableTh width={40}>log #</TableTh>
                  <TableTh width={40}>주문 #</TableTh>
                  <TableTh width={100}>STEP</TableTh>
                  <TableTh width={100}>요청</TableTh>
                  <TableTh width={180}>거래일시</TableTh>
                  <TableTh width={100}>거래종류</TableTh>
                  <TableTh width={100}>거래결과</TableTh>
                  <TableTh width={300}>거래내용</TableTh>
                  <TableTh width={200}>거래이름</TableTh>
                  <TableTh width={420}>거래ID</TableTh>
                  <TableTh width={100}>카드구분</TableTh>
                  <TableTh width={200}>카드번호</TableTh>
                  <TableTh width={100}>만료년월</TableTh>
                  <TableTh width={100}>금액</TableTh>
                  <TableTh width={100}>TAX</TableTh>
                  <TableTh width={360}>UUID</TableTh>
                </tr>
              </thead>
              <tbody>
              {paymentLog?.map((log) => (
                <tr key={log.logId} >
                  <td>{log.logId}</td>
                  <td>{log.orderId}</td>
                  <td>{log.logStep}</td>
                  <td>{log.transactionType}</td>
                  <td>{log.sslTransactionAt}</td>
                  <td>{log.sslTransactionType}</td>
                  {("0" === log.sslResult) ? (
                    <>
                      <td>{`wjd${log.sslResult}`}</td>
                      <td>{log.sslResultMessage}</td>
                      <td></td>
                    </>
                  ) : (
                    <>
                      <td>{log.sslErrorCode}</td>
                      <td>{log.sslErrorMessage}</td>
                      <td>{log.sslErrorName}</td>
                    </>
                  )}
                  <td>{log.sslTransactionId}</td>
                  <td>{log.paymentCardType}</td>
                  <td>{log.paymentCardNumber}</td>
                  <td>{log.paymentCardExpDate}</td>
                  <td>{log.paymentAmount}</td>
                  <td>{log.paymentTax}</td>
                  <td>{log.uuid}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </OrderDetailListSection>
        </div>
      </ModalWindow>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 83.6rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 2rem;
  height: 19rem;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;
