import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { NewsApi } from '../../api/NewsApi';
import { errorAlert } from '../../util/ErrorHandler';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { Button } from '../../component/Button';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { Pagination } from '../../component/Pagination';
import { Loader } from '../../component/Loader';
import { NewsSubCategoryModal } from './component/NewsSubCategoryModal';
import {
  ActiveTypeList,
  ListSize,
  PostActive,
} from '../../__mocks__/PostData';

export const NewsSubCategoryList = () => {
  const SAVED_SEARCH_NAME = "newsSubCategory_search";
  const newsApi = new NewsApi();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const initSearchParamObj = {
    id: '',
    activeCode: '',
    categoryName: '',
    name: '',
    page: 1,
    listSize: 100,
    perPageSize: 15,
    totalCount: 0,
    totalPageCount: 0,
  };
  const [searchParam, setSearchParam] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          id: location?.state?.id ? location?.state?.id : '',
          ...initSearchParamObj,
        };
      }
    }
  });
  const [result, setResult] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      id: location?.state?.id ? location?.state?.id : '',
      ...initSearchParamObj,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam('page', 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    newsApi.fetchNewsSubCategoryList(
      searchParam,
      (data) => {
        setLoading(false);
        setResult(null);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
      },
      (error) => {
        setLoading(false);
        setResult(null);
        errorAlert(error);
      }
    );
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleIdOnChange = (value) => {
    changeSearchParam?.('id', value);
  }

  const handleNameOnChange = (value) => {
    changeSearchParam?.('name', value);
  }

  const handleActiveOnChange = ({target}) => {
    changeSearchParam('active' , target.value);
  }

  const handleListSizeOnChange = (e) => {
    changeSearchParam('listSize' , e.target.value);
  }

  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleRowOnClick = (id) => {
    saveSearchData();
    handleShowModal(id);
  }

  const handleCreateOnClick = () => {
    handleShowModal(0);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleChangedModal = () => {
    handleSearchOnClick();
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }

  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "뉴스 콘텐츠", isLink: false },
            { link: "/news/sub-category", name: "Sub 카테고리 관리", isLink: true },
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'신규'} onClick={()=> handleCreateOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'#'}
              id={'id'}
              type={'number'} checkType={''}
              value={searchParam?.id}
              placeholder={'ID 입력'} min={0} maxLength={255}
              onChange={handleIdOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent
              label={'이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={searchParam?.name}
              placeholder={''} maxLength={255}
              onChange={handleNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '20rem'}}
            />
            <SelectComponents
              label={'상태'}
              id={'active'}
              value={searchParam?.active}
              objectData={[{ value: '', label: '전체' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents
              label={'List Size'}
              id={'listSize'}
              value={searchParam?.listSize}
              objectData={ListSize}
              onChange={handleListSizeOnChange}
              style={{width: '8rem'}}
            />
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <ListSection>
        <table>
          <thead>
            <tr>
              <TableTh width={5}>#</TableTh>
              <TableTh width={5}>상태</TableTh>
              <TableTh width={7}>카테고리</TableTh>
              <TableTh width={7}>순서</TableTh>
              <TableTh>header</TableTh>
              <TableTh>이름</TableTh>
              <TableTh>writer</TableTh>
              <TableTh>email</TableTh>
              <TableTh>paperYn</TableTh>
              <TableTh>writeYn</TableTh>
            </tr>
          </thead>
          <tbody>
            {0 < result?.resultList?.length ? (
              <>
                {result?.resultList?.map((subCategory) => (
                  <tr key={subCategory?.id} onDoubleClick={() => handleRowOnClick(subCategory.id)} style={{color: (subCategory?.active===1)||'grey', backgroundColor: (subCategory?.active===1)||'#9f9f9f'}}>
                    <td>
                      <input
                        id={`id${subCategory?.id}`}
                        type={'text'}
                        value={subCategory?.id}
                        disabled
                        style={{width: '98%', height: '2.3rem', textAlign: 'right', border: 0, color: (subCategory?.active===1)||'grey', backgroundColor: (subCategory?.active===1)||'#9f9f9f'}}
                      />
                    </td>
                    <td>{PostActive[subCategory?.active]}</td>
                    <td>{`${subCategory?.categoryName}(${subCategory?.categoryId})`}</td>
                    <td>{subCategory?.rank}</td>
                    <td>{subCategory?.header}</td>
                    <td>{subCategory?.name}</td>
                    <td>{subCategory?.writer}</td>
                    <td>{subCategory?.email}</td>
                    <td>{subCategory?.paperYn}</td>
                    <td>{subCategory?.writeYn}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan='10'>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <NewsSubCategoryModal
        id={modalInfo.id}
        show={modalInfo.show}
        onChange={handleChangedModal}
        onClose={handleCloseModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;

const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const ListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          overflow: hidden;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}rem` : '')};
`;
