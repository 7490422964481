import React from 'react';
import styled from 'styled-components';

export const CheckBoxComponent = React.memo(({ label, id, value, objectData, delimiter='', onChange, style}) => {

  const handleOnChange = (sourceValue, targetValue, delimiter) => {
    console.log(sourceValue+ " === " +targetValue);
    if(hasValue(sourceValue, targetValue)) {
      onChange?.(sourceValue?.replace(`${targetValue}${delimiter}`, ''));
    } else {
      onChange?.(`${sourceValue}${targetValue}${delimiter}`);
    }
  }

  const hasValue = (sourceValue, targetValue) => {
    return 0>sourceValue?.indexOf(targetValue)?false:true;
  }

  return (
    <CheckboxWrapper>
      <label htmlFor={id}>{label}</label>
      <CheckBoxList style={style}>
        {Object.keys(objectData).map((key) => (
          <li key={`${id}-${objectData[key].value}`}>
            <input type='checkbox'
              id={`${id}-${objectData[key].value}`}
              name={id}
              value={objectData[key].value}
              onChange={()=>handleOnChange(value, objectData[key].value, delimiter)}
              checked={hasValue(value, objectData[key].value)}
            />
            <label htmlFor={`${id}-${objectData[key].value}`}></label>
            <label className='message' htmlFor={`${id}-${objectData[key].value}`}>
              {objectData[key].label}
              {(objectData[key]?.buttonUrl) && (
                <img src={objectData[key]?.buttonUrl} alt='이상' />
              )}
            </label>
          </li>
        ))}
      </CheckBoxList>
    </CheckboxWrapper>
  );
});

const CheckboxWrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  & > label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
`;

const CheckBoxList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & li {
    padding: 0 0.4rem;
    height: 3rem;
    display: flex;
    align-items: center;
    & input + label {
      display: inline-block;
      margin: 0;
      width: 1.6rem;
      height: 1.6rem;
      border: 0.1rem solid #E0E0E0;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    & input:checked + label {
      background-color: #f86258;
      border: 0.1rem solid #f86258;
      background-image: url('/icon/input-checkbox-checked-icon.png');
      background-size: 1.12rem;
      background-repeat: no-repeat;
      background-position: center;
    }
    & input {
      display: none;
    }
    & label.message {
      margin-left: 0.4rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 2rem;

      display: flex;
      align-items: center;
      cursor: pointer;
      & img {
        margin-left: 0.4rem;
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
`;