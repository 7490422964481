import { jsonApi } from "../util/AxiosApi";

export class UserApi {

  fetchUserList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchUserId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchUserPostCount(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/${id}/postCount`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchRealtorList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/realtor`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchCarDealerList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/cardealer`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchExpertList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/expert`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchExpertId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/expert/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  saveExpert(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/user/expert/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createExpertImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/user/expert/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteExpertImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/user/expert/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchBlockIPList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/blockip`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchBlockIPById(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/user/blockip/${searchData.id}`,
      params: searchData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  saveUserInfo(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/user/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createBlockIPInfo(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/user/blockip`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  saveBlockIPInfo(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/user/blockip/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteBlockIPInfo(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/user/blockip/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
}