import axios from 'axios';
import cookie from 'react-cookies';

export const setToken = (accessToken) => {
  if(accessToken) {
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }

  let expires = new Date();
  expires.setHours(new Date().getHours()+24*3); // 3일 후

  cookie.save(
    'accessToken'
    , accessToken
    , {
      path: '/'
      , httpOnly: process.env.COOKIE_HTTP_ONLY
      , expires: expires
    }
  )
};

export const remoteToken = () => {
  cookie.remove('accessToken', { path: '/' });
  delete axios.defaults.headers.Authorization;
}

export const hasAccessToken = () => {
  return (cookie.load('accessToken')) ? true : false;
}

export const getRememberMe = () => {
  return cookie.load('rememberMe');
}

export const saveRememberMe = (email) => {
  cookie.save(
    'rememberMe'
    , email
    , {
      path: '/'
      , httpOnly: process.env.COOKIE_HTTP_ONLY
    }
  )
}

export const removeRememberMe = () => {
  cookie.remove('rememberMe', { path: '/' });
}
