import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { BuysellApi } from '../../../api/BuysellApi';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveType,
  BuysellCategories,
  BuysellConditions,
  BuysellPremiumRank,
  BuysellTypes,
  BuysellPurchaseStatus,
  Cities,
  ContactTypes,
  Premium,
} from '../../../__mocks__/PostData';

export const BuysellDetail = ({setDialog}) => {
  const buysellApi = new BuysellApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 7,
  });
  const [buysellDetail, setBuysellDetail] = useState({
    id: id,
    boardId: 1,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeBuysellDetailData = (name, value) => {
    setBuysellDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeBuysellDetailImages = (index, imageData) => {
    setBuysellDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteBuysellDetailImages = (index) => {
    setBuysellDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    buysellApi.fetchBuysellById(
      searchParam.id,
      (data) => {
        setBuysellDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': buysellDetail.id,
      'boardId': buysellDetail.boardId,
      'active': buysellDetail.active,
      'password': buysellDetail.password,
      'memberId': buysellDetail.memberId,
      'name': buysellDetail.name,
      'phone': buysellDetail.phone,
      'email': buysellDetail.email,
      'contactCode': buysellDetail.contactCode,
      'title': buysellDetail.title,
      'description': editorRef.current.getContent(),
      'premium': buysellDetail.premium,
      'premiumStart': buysellDetail.premiumStart,
      'premiumEnd': buysellDetail.premiumEnd,
      'hitCount': buysellDetail.hitCount,
      'isMainDisplay': buysellDetail.isMainDisplay,
      'rank': buysellDetail.rank,
      'summary': buysellDetail.summary,
      'link': buysellDetail.link,
      'type': buysellDetail.type,
      'location': buysellDetail.location,
      'categoryCode': buysellDetail.categoryCode,
      'conditionCode': buysellDetail.conditionCode,
      'purchaseStateCode': buysellDetail.purchaseStateCode,
      'price': buysellDetail.price,
    };
    setLoading(true);
    buysellApi.saveBuysell(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(buysellDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', buysellDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      buysellApi.createBuysellImageFile(
        buysellDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeBuysellDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(buysellDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': buysellDetail.id,
      });

      buysellApi.deleteBuysellImageFile(
        buysellDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteBuysellDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeBuysellDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeBuysellDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeBuysellDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeBuysellDetailData('isMainDisplay', true);
    else
      changeBuysellDetailData('isMainDisplay', false);
  }

  const handleMemberNumberChange = (value) => {
    changeBuysellDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeBuysellDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeBuysellDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeBuysellDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeBuysellDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeBuysellDetailData('contactCode', value);
  }

  const handleTypeOnChange = (value) => {
    changeBuysellDetailData('type', value);
  }

  const handleCategoryCodeOnChange = ({target}) => {
    changeBuysellDetailData('categoryCode', target.value);
  }

  const handlePriceOnChange = (value) => {
    changeBuysellDetailData('price', value);
  }

  const handleSummaryOnChange = (value) => {
    changeBuysellDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeBuysellDetailData('link', value.trim())
  }

  const handleConditionCodeOnChange = (value) => {
    changeBuysellDetailData('conditionCode', value);
  }

  const handlePurchaseStateCodeOnChange = (value) => {
    changeBuysellDetailData('purchaseStateCode', value);
  }

  const handleLocationOnChange = ({target}) => {
    changeBuysellDetailData('location', target.value);
  }

  const handlePremiumRankOnChange = ({target}) => {
    changeBuysellDetailData('rank', target.value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeBuysellDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeBuysellDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeBuysellDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeBuysellDetailData('title', value.trim());
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "Home", isLink: false },
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/buysell`, name:"사고팔고 목록", isLink: true },
            { link: `/market/buysell/${buysellDetail?.id}`, name: `${buysellDetail?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={buysellDetail.id} placeholder='입력' maxLength={255}
            style={{width: '100px'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={buysellDetail?.active}
            objectData={ActiveType}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={buysellDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={buysellDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={buysellDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={buysellDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄 카테고리'}
            id={'rank'}
            value={buysellDetail?.rank}
            objectData={{'': { value: '', label: '없음'}, ...BuysellPremiumRank}}
            onChange={handlePremiumRankOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={buysellDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '80px'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={buysellDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={buysellDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={buysellDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={buysellDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'판매자'}
            id={'name'}
            type={'text'} checkType={''}
            value={buysellDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={buysellDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={buysellDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={buysellDetail?.contactCode}
            objectData={ContactTypes}
            // infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
            onChange={handleContactCodeOnChange}
          />
          <SelectComponentWithObject
            label={'카테고리'}
            id={'categoryCode'}
            value={buysellDetail?.categoryCode}
            objectData={BuysellCategories}
            onChange={handleCategoryCodeOnChange}
            style={{width: '11rem'}}
          />
          <SelectComponentWithObject
            label={'거래지역'}
            id={'location'}
            value={buysellDetail?.location}
            objectData={Cities}
            onChange={handleLocationOnChange}
            style={{width: '12rem'}}
          />
          <RadioComponents
            label={'구분'}
            id={'type'}
            value={buysellDetail?.type}
            objectData={BuysellTypes}
            // infoMessage={'선택된 연락망은 게시글 화면에 노출됩니다.'}
            onChange={handleTypeOnChange}
          />
          <RadioComponents
            label={'상품상태'}
            id={'conditionCode'}
            value={buysellDetail?.conditionCode}
            objectData={{'':{value:'', label:'없음'}, ...BuysellConditions}}
            onChange={handleConditionCodeOnChange}
          />
          <RadioComponents
            label={'거래상태'}
            id={'purchaseStateCode'}
            value={buysellDetail?.purchaseStateCode}
            objectData={{'':{value:'', label:'없음'}, ...BuysellPurchaseStatus}}
            onChange={handlePurchaseStateCodeOnChange}
          />
          <InputComponent
            label={'판매가격'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={buysellDetail.price} placeholder='입력' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />

        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'상품명'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={buysellDetail.title} placeholder='입력' maxLength={255}
            style={{width: '50rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={buysellDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={buysellDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break: break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
