import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Breadcrumbs = ({list}) => {

  const isLast = (name) => {
    return list[list?.length-1].name === name;
  }

  return (
    <BreadcrumbWrapper>
      {list?.map((item, index) => (
        <div key={index}>
          {
          item?.isLink ? (
            <>
              <Link to={item?.link}>
                {item?.name}
              </Link>
              {isLast(item?.name) || ` > `}

            </>
          ) : (
            <>
              {item?.name}
              {isLast(item?.name) || ` > `}
            </>
          )
          }
        </div>

      ))}
    </BreadcrumbWrapper>
  );
};

const BreadcrumbWrapper = styled.div`
  margin: 0 2rem 0 0;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
