import { jsonApi } from "../util/AxiosApi";
import {
  hasAccessToken,
  remoteToken,
  setToken
} from "../util/TokenManager";

export class AuthApi {
  signIn(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/account/signIn`,
      data: formData,
    }).then((response) => {
      setToken(response.data.access_token);
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  signOut(callback, errorCallback) {
    remoteToken();
    jsonApi({
      method: 'post',
      url: `/api/account/signOut`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  isSignIn() {
    return hasAccessToken();
  }
}