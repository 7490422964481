import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';

export const CalendarComponent = ({ label, id, value, dateFormat, placeholderText, onChange, isClearable=false, isShowTimeSelect=false, width='13rem'}) => {

  const handleOnChange = (date) => {
    onChange?.(dateToString(date));
  }

  const dateToString = (date) => {
    if(isShowTimeSelect && date) {
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    } else if(!isShowTimeSelect && date) {
      return moment(date).format("yyyy-MM-DD");
    }
    return '';
  }

  const stringToDate = (str) => {
    if(isShowTimeSelect && str) {
      return moment(str, "yyyy-MM-DD HH:mm:ss").toDate();
    } else if(!isShowTimeSelect && str) {
      return moment(str, "yyyy-MM-DD").toDate();
    }
    return '';
  }

  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <MyDatePicker
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        selected={stringToDate(value)}
        onChange={date => handleOnChange(date)}
        isClearable={isClearable}
        showTimeSelect={isShowTimeSelect}
        timeIntervals={60}
        width={width}
        // showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </InputGroup>
  );
}

const InputGroup = styled.div`
  position: relative;
  margin: 0.7rem 0.7rem;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  & label {
    position: absolute;
    top: -0.8rem;
    padding: 0rem 0.8rem;
    height: 1.76rem;
    font-size: 0.8em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* 인터넷익스플로러 */
    user-select: none;
    color: #777;
    background-color: #fff;
  }
  & div.react-datepicker__input-container {
    & button.react-datepicker__close-icon {
      color: #ddd;
    }
    & button.react-datepicker__close-icon:after {
      color: #ddd;
      background-color: #f86258;
    }
  }
  & .react-datepicker-popper {
    z-index: 2;
  }
  & .react-datepicker {
    width: max-content;
    font-size: 1.3rem !important;
  }
  & .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }
  & .react-datepicker__header {
    padding-top: 0.6rem !important;
  }
  & .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
  }
  & .saturday {
    color: rgb(0, 0, 255) !important;
  }
  & .sunday {
    color: rgb(255, 0, 0) !important;
  }
  & .react-datepicker__day--disabled {
    color: #cccccc !important;
  }
`;
const MyDatePicker = styled(DatePicker)`
  display: block;
  padding: 0 0.5rem;
  height: 3rem;
  width: ${ props => props.width};
  font-size: 1em;
  border: 0 solid #ddd;
  border-radius: 0.5rem;
  box-sizing: border-box;
`;
