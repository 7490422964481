import React, { useEffect } from 'react'
import styled from 'styled-components';

export const Modal = ({show, onClose}) => {

  const handleCloseOnClick = () => {
    onClose?.();
  }

  useEffect(() => {
    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <div className={`modalContent`} >
        </div>
      </ModalWindow>
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  z-index: 1001;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 13.5px );
  border-radius: 10px;
  width: 345px;
  height: 736px;
`;

const CloseButton = styled.i`
  position: absolute;
  top: 24.2px;
  right: 23.2px;
  width: 16px;
  height: 16px;
  text-shadow: 1px 1px 2px gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;
