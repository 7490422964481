import { jsonApi } from "../util/AxiosApi";

export class RentApi {

  fetchRentList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/rent`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchRentById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/rent/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  saveRent(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/rent/${id}`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createRentImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/rent/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteRentImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/rent/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}