import { jsonApi } from "../util/AxiosApi";

export class NewsApi {

  fetchNewsCategoryList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/news/category`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchNewsCategoryById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/news/category/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createNewsCategory(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/news/category`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      const {response: {data}} = error;
      errorCallback(data);
    });
  }

  updateNewsCategory(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/news/category/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback(error);
    });
  }

  fetchNewsSubCategoryList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/news/sub-category`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchNewsSubCategoryById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/news/sub-category/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createNewsSubCategory(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/news/sub-category`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback(error);
    });
  }

  updateNewsSubCategory(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/news/sub-category/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback(error);
    });
  }

}