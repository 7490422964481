import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../component/Breadcrumbs';
import { Button } from '../../component/Button';
import { InputComponent } from '../../component/InputComponent';
import { SelectComponents } from '../../component/SelectComponents';
import { CalendarComponent } from '../../component/CalendarComponent';
import { Pagination } from '../../component/Pagination';
import { Loader } from '../../component/Loader';
import { VkpApi } from '../../api/VkpApi';
import { InvoicePrintModal } from './component/InvoicePrintModal';
import { priceToStringWithDecimal } from '../../util/utils';
import { errorAlert } from '../../util/ErrorHandler';
import {
  CustomerPrintTypeList,
  ListSize,
} from '../../__mocks__/PostData';

export const InvoiceList = ({setDialog}) => {
  const SAVED_SEARCH_NAME = "invoice_search";
  const vkpFileInput = useRef();
  const vkpApi = new VkpApi();
  const navigate = useNavigate();

  const [selectListSize] = useState(ListSize);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });

  const [searchParam, setSearchParam] = useState(() => {
    if(typeof window !== "undefined") {
      const saved = window.localStorage.getItem(SAVED_SEARCH_NAME);
      if(saved) {
        return JSON.parse(saved);
      } else {
        return {
          invoiceNumber: '',
          customerNumber: '',
          customerBizEnName: '',
          customerBizKrName: '',
          startDate: '',
          endDate: '',
          printType: '',
          page: 1,
          listSize: 100,
          perPageSize: 15,
          totalCount: 0,
          totalPageCount: 0,
        };
      }
    }
  });
  const [result, setResult] = useState(null);

  // == state 변경 ---------------------------------------------
  const changeSearchParam = (name, value) => {
    setSearchParam((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const initSearchParam = () => {
    setSearchParam({
      invoiceNumber: '',
      customerNumber: '',
      customerBizEnName: '',
      customerBizKrName: '',
      startDate: '',
      endDate: '',
      printType: '',
      page: 1,
      listSize: 100,
      perPageSize: 15,
      totalCount: 0,
      totalPageCount: 0,
    });
  }

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    changeSearchParam("page", 1);
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    vkpApi.fetchInvoiceList(
      searchParam,
      (data) => {
        setLoading(false);
        setResult(null);
        setResult(data);
        changeSearchParam('totalCount', data.totalCount);
        changeSearchParam('totalPageCount', data.totalPageCount);
      },
      (error) => {
        setLoading(false);
        setResult(null);
        errorAlert(error);
      }
    );
  }

  const handleSearchOnKeyUp = (e) => {
    if(13 === e.keyCode) {
      handleSearchOnClick();
    }
  }

  const handleInvoiceNumberOnChange = (value) => {
    changeSearchParam?.('invoiceNumber', value);
  }
  const handleCustomerNumberOnChange = (value) => {
    changeSearchParam?.('customerNumber', value);
  }
  const handleCustomerBizEnNameOnChange = (value) => {
    changeSearchParam?.('customerBizEnName', value);
  }
  const handleCustomerBizKrNameOnChange = (value) => {
    changeSearchParam?.('customerBizKrName', value);
  }

  const handleStartDateOnChange = (value) => {
    changeSearchParam?.('startDate', value);
  }
  const handleEndDateOnChange = (value) => {
    changeSearchParam?.('endDate', value);
  }
  const handlePrintTypeOnChange = (e) => {
    changeSearchParam("printType" , e.target.value);
  }
  const handleListSizeOnChange = (e) => {
    changeSearchParam("listSize" , e.target.value);
  }
  const handlePageChange = (pageNum) => {
    changeSearchParam('page', pageNum);
  }

  const handleUploadImage = (event) => {
    const files = event.target.files;
    if (files && 0 < files.length) {
      const file = files[0];

      uploadVKPFile(file);
      vkpFileInput.current.value = "";
    }
  }

  const uploadVKPFile = (file) => {
    if(file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      vkpApi.uploadVKPFile(
        formData,
        (data) => {
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const handleInvoiceDetailOnClick = (id) => {
    saveSearchData();
    navigate(`/vkp/invoice/${id}`);
  }

  //search 데이터 저장 및 삭제 처리
  const saveSearchData = () => {
    localStorage.setItem(SAVED_SEARCH_NAME, JSON.stringify(searchParam));
  }
  const removeSearchData = () => {
    localStorage.removeItem(SAVED_SEARCH_NAME);
  }

  const handleInvoiceOnClick = (invoiceNumber) => {
    handleShowModal(invoiceNumber);
  }

  const handleInitOnClick = () => {
    initSearchParam();
    handleSearch();
  }

  const handleSendEmailOnClick = (invoiceNumber) => {
    if(window.confirm("이메일을 보내시겠습니까?")) {
      setLoading(true);
      vkpApi.sendEmailWithInvoice(
        invoiceNumber,
        (data) => {
          setLoading(false);
          handleSearchOnClick();
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  useEffect(() => {
    handleSearch();
    removeSearchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam.page]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "VKP 관리", isLink: false },
            { link: "/vkp/invoice", name: "인보이스", isLink: false }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'초기화'} onClick={()=> handleInitOnClick()} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
        <SearchSection>
          <InputGroup>
            <InputComponent label={'Invoice No.'}
              id={'invoiceNumber'}
              type={'number'} checkType={''}
              value={searchParam.invoiceNumber}
              placeholder='' maxLength={255}
              onChange={handleInvoiceNumberOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '10rem'}}
            />
            <InputComponent
              label={'Customer No.'}
              id={'customerNumber'}
              type={'text'} checkType={''}
              value={searchParam.customerNumber}
              placeholder={''} maxLength={255}
              onChange={handleCustomerNumberOnChange}
              style={{width: '10rem'}}
            />
            <InputComponent
              label={'Biz Name(En)'}
              id={'customerBizEnName'}
              type={'text'} checkType={''}
              value={searchParam.customerBizEnName}
              placeholder={''} maxLength={255}
              onChange={handleCustomerBizEnNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <InputComponent
              label={'Biz Name(Kr)'}
              id={'customerBizKrName'}
              type={'text'} checkType={''}
              value={searchParam.customerBizKrName}
              placeholder={''} maxLength={255}
              onChange={handleCustomerBizKrNameOnChange}
              onKeyUp={handleSearchOnKeyUp}
              style={{width: '15rem'}}
            />
            <CalendarComponent  label={'From'}
              id={'startDate'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={searchParam?.startDate}
              onChange={handleStartDateOnChange}
              isClearable={true}
            />
            <CalendarComponent label={'To'}
              id={'endDate'}
              dateFormat={'yyyy-MM-dd'}
              placeholderText={'yyyy-MM-dd'}
              value={searchParam?.endDate}
              onChange={handleEndDateOnChange}
              isClearable={true}
            />
            <SelectComponents label={'Print Type'}
              id={'printType'}
              value={searchParam.printType}
              objectData={CustomerPrintTypeList}
              onChange={handlePrintTypeOnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents label={'List Size'}
              id={'listSize'}
              value={searchParam.listSize}
              objectData={selectListSize}
              onChange={handleListSizeOnChange}
              style={{width: '8rem'}}
            />
            <input
              type="file"
              id='vkpFile'
              // accept="image/jpg, image/jpeg, image/png"
              ref={vkpFileInput}
              onChange={(e) => handleUploadImage(e)}/>
          </InputGroup>
        </SearchSection>
      </HeaderSection>
      <InvoiceListSection>
        <table>
          <thead>
            <tr>
              <th style={{width: '7rem'}}>index</th>
              <th style={{minWidth: '25rem'}}>Biz Name(E)</th>
              <th style={{minWidth: '25rem'}}>Biz Name(K)</th>
              <th style={{width: '10rem'}}>Invoice No.</th>
              <th style={{width: '10rem'}}>Invoice Date</th>
              <th style={{width: '10rem'}}>Sales Amount</th>
              <th style={{width: '10rem'}}>Paid Date</th>
              <th style={{width: '10rem'}}>Paid Amount</th>
              <th style={{width: '21rem'}}>customerEmail</th>
              <th style={{width: '5rem'}}>Send</th>
              <th style={{width: '9.8rem'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {0 < result?.resultList?.length ? (
              <>
                {result?.resultList.map((row) =>(
                  <tr key={row.invoiceNumber}
                    onDoubleClick={() => handleInvoiceDetailOnClick(row.invoiceNumber)}
                  >
                    <td>{row.rowNum}</td>
                    <td>
                      <input
                        id={`customerBizEnName${row.rowNum}`}
                        type={'text'}
                        value={row.customerBizEnName}
                        disabled
                        style={{width: '98%', height: '2.3rem'}}
                      />
                    </td>
                    <td>
                      <input
                        id={`customerBizKrName${row.rowNum}`}
                        type={'text'}
                        value={row.customerBizKrName}
                        disabled
                        style={{width: '98%', height: '2.3rem'}}
                      />
                    </td>
                    <td>
                      <input
                        id={`invoiceNumber${row.rowNum}`}
                        type={'text'}
                        value={row.invoiceNumber}
                        disabled
                        style={{width: '98%', height: '2.3rem'}}
                      />
                    </td>
                    <td>
                      {row.invoiceDate}
                      </td>
                    <td style={{textAlign:'right'}}>
                      {priceToStringWithDecimal(row.amountInvoice)}
                    </td>
                    <td>{row.paidDate}</td>
                    <td style={{textAlign:'right'}}>
                      {priceToStringWithDecimal(row.amountPaid)}
                    </td>
                    <td style={{textAlign:'left'}}>
                      {row.customerEmail}
                    </td>
                    <td style={{textAlign:'right'}}>
                      {row.sendEmailCount}
                    </td>
                    <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.2rem'}}>
                      <Button type='button' value={'Email'} onClick={()=> handleSendEmailOnClick(row.invoiceNumber)} style={{width: '6rem', color: '#fff', backgroundColor: '#216ba5de'}} />
                      <Button type='button' value={'Print'} onClick={()=> handleInvoiceOnClick(row.invoiceNumber)} style={{width: '6rem', color: '#fff', backgroundColor: '#216ba5de'}} />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan='11'>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InvoiceListSection>
      <Pagination
        currentPage={searchParam.page}
        totalPage={searchParam.totalPageCount}
        perPageSize={searchParam.perPageSize}
        handlePageChange={handlePageChange}/>
      <Loader loading={loading} />
      <InvoicePrintModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal}
        setDialog={setDialog}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const SearchSection = styled.div`
  margin: 1rem 0rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const InputGroup = styled.div`
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const InvoiceListSection = styled.div`
  margin: 0.5rem 0rem;
  height: 66vh;
  border: 0.1rem solid #ddd;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr.lived {
        color: #FFAB2D;
        background-color: #fff;
      }
    }
  }
`;
