import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { NewsApi } from '../../../api/NewsApi';
import { InputComponent } from '../../../component/InputComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { Button } from '../../../component/Button';
import { Loader } from '../../../component/Loader';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import { isBlankValue } from '../../../util/utils';
import {
  ActiveTypeList,
  NewsReadCodes,
  NewsWriteCodes,
} from '../../../__mocks__/PostData';

export const NewsCategoryModal = ({id, show, onChange, onClose}) => {
  const newsApi = new NewsApi();
  const [loading, setLoading] = useState(false);
  const newsCategoryInfo = {
    id: 0,
    active: 0,
    rank: 0,
    type: 'news',
    readCode: 0,
    writeCode: 0,
    name: '',
  }
  const [categoryInfo, setCategoryInfo] = useState({
    id: id,
    ...newsCategoryInfo
  });

  // state 변경 ------------------------------------------------
  const init = () => {
    setCategoryInfo({
      id: id,
      ...newsCategoryInfo
    });
  }

  const changeCategoryInfo = (name, value) => {
    setCategoryInfo((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  // handler ---------------------------------------------
  const handleActiveOnChange = ({target}) => {
    changeCategoryInfo?.('active',target.value);
  }
  const handleRankOnChange = (value) => {
    changeCategoryInfo?.('rank', value);
  }
  const handleReadCodeOnChange = ({target}) => {
    changeCategoryInfo?.('readCode', target.value);
  }
  const handleWriteCodeOnChange = ({target}) => {
    changeCategoryInfo?.('writeCode', target.value);
  }
  const handleNameOnChange = (value) => {
    changeCategoryInfo?.('name', value);
  }

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    setLoading(true);
    newsApi.fetchNewsCategoryById(
      id,
      (data) => {
        setLoading(false);
        setCategoryInfo(null);
        setCategoryInfo(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    if(validateInfoData()) {
      setLoading(true);
      const id = categoryInfo.id;
      const updateData = JSON.stringify({
        'id': id,
        'active': categoryInfo.active,
        'rank': categoryInfo.rank,
        'type': categoryInfo.type,
        'readCode': categoryInfo.readCode,
        'writeCode': categoryInfo.writeCode,
        'name': categoryInfo.name,
      });

      newsApi.updateNewsCategory(id, updateData,
        (data) => {
          setLoading(false);
          setCategoryInfo(data?.result);
          alert("저장 하였습니다.");
          onChange();
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmSave = useConfirm(
    "저장 하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const handleCreateOnClick = () => {
    if(validateInfoData()) {
      setLoading(true);
      const createData = JSON.stringify({
        'active': categoryInfo.active,
        'rank': categoryInfo.rank,
        'type': categoryInfo.type,
        'readCode': categoryInfo.readCode,
        'writeCode': categoryInfo.writeCode,
        'name': categoryInfo.name,
      });

      newsApi.createNewsCategory(createData,
        (data) => {
          setLoading(false);
          alert("신규 등록 하였습니다.");
          setCategoryInfo(data?.result);
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmCreate = useConfirm(
    "신규 등록 하시겠습니까?",
    handleCreateOnClick,
    ()=>{},
  );

  // function ---------------------------------------------
  const validateInfoData = () => {
    // if(isBlankValue(categoryInfo?.writeCode)) {
    //   alert('쓰기 권한은 필수입니다.');
    //   return false;
    // }
    if(isBlankValue(categoryInfo?.name)) {
      alert('이름은 필수입니다.');
      return false;
    }
    return true;
  }

  // render ---------------------------------------------
  useEffect(() => {
    init();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <Header>
          <h3>뉴스 Category</h3>
        </Header>
        <GridGroup>
          <InputGroup>
            <InputComponent label={'ID'}
              id={'id'}
              type={'number'} checkType={''}
              value={categoryInfo?.id} placeholder='입력' maxLength={255}
              style={{width: '11.55rem'}}
              disabled={true}
            />
            <SelectComponents label={'상태'}
              id={'active'}
              value={categoryInfo?.active}
              objectData={[{ value: null, label: '선택' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '11.55rem'}}
            />
            <InputComponent label={'순서'}
              id={'rank'}
              type={'text'} checkType={''}
              value={categoryInfo?.rank} min={0} placeholder='' maxLength={255}
              onChange={handleRankOnChange}
              style={{width: '11.55rem'}}
              inputStyle={{textAlign: 'right'}}
            />
            <InputComponent label={'Type'}
              id={'name'}
              type={'text'} checkType={''}
              value={categoryInfo?.type}
              style={{width: '11.55rem'}}
              disabled={true}
            />
            <SelectComponentWithObject label={'읽기 권한'}
              id={'readCode'}
              value={categoryInfo?.readCode}
              objectData={NewsReadCodes}
              onChange={handleReadCodeOnChange}
              style={{width: '11.55rem'}}
            />
            <SelectComponentWithObject label={'쓰기 권한'}
              id={'writeCode'}
              value={categoryInfo?.writeCode}
              objectData={NewsWriteCodes}
              onChange={handleWriteCodeOnChange}
              style={{width: '11.55rem'}}
            />
            <InputComponent label={'카테고리 명'}
              id={'name'}
              type={'text'} checkType={''}
              value={categoryInfo?.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
          </InputGroup>
          <ButtonGroup>
            {categoryInfo?.id ? (
              <Button type='button' value={'저장'} onClick={confirmSave} />
            ) : (
              <Button type='button' value={'신규'} onClick={confirmCreate} />
            )}
            <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
          </ButtonGroup>
        </GridGroup>
      </ModalWindow>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 32rem;
  height: 33.5rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const GridGroup = styled.div`
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
