import React from 'react'
import styled from 'styled-components';

export const SwitchComponent = ({label, id, value, checkedValue, onChange, style}) => {

  const handleOnChange = () => {
    if(value === checkedValue) {
      onChange?.(id, '');
    } else {
      onChange?.(id, checkedValue);
    }
  }

  const isChecked = (value) => {
    return checkedValue === value ? true : false;
  }

  return (
    <>
      <SwitchWrapper style={style}>
        <SwitchTitle>
          {label}
        </SwitchTitle>
        <Switch>
          <input id={id}
            type="checkbox"
            onChange={handleOnChange}
            checked={isChecked(value)}
          />
          <span className="slider">
            {(isChecked(value))
              ? (<span className={`sliderOn`}>ON</span>)
              : (<span className={'sliderOff'}>OFF</span>)
            }
          </span>
        </Switch>
      </SwitchWrapper>
    </>
  )
}

const SwitchWrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 1.1rem 0.7rem 0.3rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
`;
const SwitchTitle = styled.div`
  position: absolute;
  top: -0.8rem;
  left: 0.7rem;
  padding: 0rem 0.8rem;
  height: 1.76rem;
  font-size: 0.8em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* 인터넷익스플로러 */
  user-select: none;
  color: #777;
  background-color: #fff;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 8.2rem;
  height: 3rem;

  & input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  & span.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EEEEEE;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 3.4rem;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      width: 2.6rem;
      height: 2.6rem;
      left: 0.4rem;
      bottom: 0.2rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    & span.sliderOn {
      position: absolute;
      top: 0.6rem;
      left: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
    & span.sliderOff {
      position: absolute;
      top: 0.6rem;
      right: 1.8rem;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }
  }
  & input:checked + span.slider {
    background-color: #FD6258;
  }

  & input:focus + span.slider {
    box-shadow: 0 0 0.1rem #FD6258;
  }
  & input:checked + span.slider::before {
    -webkit-transform: translateX(4.8rem);
    -ms-transform: translateX(4.8rem);
    transform: translateX(4.8rem);
  }
`;

