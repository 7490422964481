import { jsonApi } from "../util/AxiosApi";

export class JobApi {

  fetchJobList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/job`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchJobById(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/job/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  saveJob(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/job/${id}`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  createJobImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/job/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteJobImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/job/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }
};
