import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const Pagination = ({ currentPage, totalPage, perPageSize, handlePageChange }) => {
  const [startPageNum, setStartPageNum] = useState(0);

  const calculatePageSize = () => {
    if (totalPage <= startPageNum + perPageSize-1) {
      return totalPage;
    } else {
      return startPageNum + perPageSize-1;
    }
  };

  const pageRendering = () => {
    const result = [];
    for(let i=startPageNum; i<=calculatePageSize(); i++) {
      result.push(<Page key={i} className={`${i === currentPage? 'active' : ''}`} onClick={()=>handlePageOnClick(i)}>{i}</Page>);
    }
    return result;
  };

  const handlePageOnClick = (pageNum) => {
    handlePageChange?.(pageNum);
  };

  const handleLeftArrowOnClick = () => {
    if(0 < startPageNum - perPageSize) {
      handlePageChange?.(startPageNum - perPageSize);
    }
  };

  const handleRightArrowOnClick = () => {
    if(currentPage < totalPage) {
      handlePageChange?.(startPageNum + perPageSize);
    }
  };

  useEffect(() => {
    const startPageNum = (parseInt((currentPage-1) / perPageSize)*perPageSize)+1;

    setStartPageNum(startPageNum);
  }, [currentPage, totalPage, perPageSize]);

  return (
    <PaginationWrapper>
      <PaginationBox>
        <PrevPage onClick={()=>handleLeftArrowOnClick()}>Prev</PrevPage>
        {pageRendering()}
        <NextPage onClick={()=>handleRightArrowOnClick()}>Next</NextPage>
      </PaginationBox>
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
`;
const PaginationBox = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
`;
const PrevPage = styled.li`
  width: 5rem;
  height: 3.2rem;
  font-size: 0.9em;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow:
    1px 0 0 0 #ddd,
    0 1px 0 0 #ddd,
    1px 1px 0 0 #ddd,
    1px 0 0 0 #ddd inset,
    0 1px 0 0 #ddd inset;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;
const NextPage = styled.li`
  width: 5rem;
  height: 3.2rem;
  font-size: 0.9em;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow:
    1px 0 0 0 #ddd,
    0 1px 0 0 #ddd,
    1px 1px 0 0 #ddd,
    1px 0 0 0 #ddd inset,
    0 1px 0 0 #ddd inset;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;
const Page = styled.li`
  width: 3.2rem;
  height: 3.2rem;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow:
    1px 0 0 0 #ddd,
    0 1px 0 0 #ddd,
    1px 1px 0 0 #ddd,
    1px 0 0 0 #ddd inset,
    0 1px 0 0 #ddd inset;

  cursor: pointer;
  &.active {
    font-weight: bold;
    background-color: #b1b1b1;
  }
  &:hover {
    background-color: #ddd;
  }
`;