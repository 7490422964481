import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { UserApi } from '../../../api/UserApi';
import { InputComponent } from '../../../component/InputComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { RadioComponents } from '../../../component/RadioComponents';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { TextareaComponent } from '../../../component/TextareaComponent';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ExportActiveType,
  ExportCategories,
  ExportOption2,
} from '../../../__mocks__/PostData';
import { UserSearchModal } from './UserSearchModal';

export const ExpertProfileModal = ({id, show, onClose}) => {
  const userApi = new UserApi();
  const [loading, setLoading] = useState(false);
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const initExpertProfile = {
    id: 0,
    memberId: 0,
    memberSignId: '',
    active: 0,
    type: '',
    option1: '',
    option2: '',
    title: '',
    subTitle: '',
    name: '',
    company: '',
    location: '',
    phone1: '',
    phone2: '',
    fax: '',
    email: '',
    homepage: '',
    description1: '',
    description2: '',
    sign: '',
    storeId: 0,
    map: '',
    hit: 0,
    startDate: '',
    endDate: '',
    postDate: '',
    imageFile1: '',
    imageFile2: '',
    imageFile3: '',
    images: {},
    accountInfo: {},
  };
  const [expertProfileInfo, setExpertProfileInfo] = useState(initExpertProfile);

  // == state 변경 ------------------------------------------------
  const initExpertProfileInfoData = () => {
    setExpertProfileInfo(initExpertProfile);
  };
  const changeExpertProfileInfoData = (key, value) => {
    setExpertProfileInfo((values) => {
      const updated = {...values};
      updated[key] = value;
      return updated;
    });
  };

  const changeExpertProfileImages = (index, imageData) => {
    setExpertProfileInfo((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteExpertProfileImages = (index) => {
    setExpertProfileInfo((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // == handler ------------------------------------------------
  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    handleSearchExpertUserOnClick();
  }

  const handleSearchExpertUserOnClick = () => {
    setLoading(true);
    userApi.fetchExpertId(
      id,
      (data) => {
        setLoading(false);
        setExpertProfileInfo(null);
        setExpertProfileInfo(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    setLoading(true);
    userApi.saveExpert(
      id,
      expertProfileInfo,
      (data) => {
        setLoading(false);
        alert(data.message);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(expertProfileInfo.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', expertProfileInfo.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      userApi.createExpertImageFile(
        expertProfileInfo.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeExpertProfileImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(expertProfileInfo.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': expertProfileInfo.id,
      });

      userApi.deleteExpertImageFile(
        expertProfileInfo.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteExpertProfileImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleNameOnChange = (value) => {
    changeExpertProfileInfoData('name', value);
  }
  const handleTitleOnChange = (value) => {
    changeExpertProfileInfoData('title', value);
  }
  const handleSubTitleOnChange = (value) => {
    changeExpertProfileInfoData('subTitle', value);
  }
  const handleActiveOnChange = (value) => {
    changeExpertProfileInfoData('active', parseInt(value));
  }
  const handleOption2OnChange = (value) => {
    changeExpertProfileInfoData?.('option2', value);
  }
  const handleStartDateOnChange = (value) => {
    changeExpertProfileInfoData?.('startDate', value);
  }
  const handleEndDateOnChange = (value) => {
    changeExpertProfileInfoData?.('endDate', value);
  }
  const handleTypeOnChange = ({target}) => {
    changeExpertProfileInfoData?.('type', target.value);
  }
  const handleCompanyOnChange = (value) => {
    changeExpertProfileInfoData?.('company', value);
  }
  const handleLocationOnChange = (value) => {
    changeExpertProfileInfoData?.('location', value);
  }
  const handleEmailOnChange = (value) => {
    changeExpertProfileInfoData?.('email', value);
  }
  const handleHomepageOnChange = (value) => {
    changeExpertProfileInfoData?.('homepage', value);
  }
  const handlePhone1OnChange = (value) => {
    changeExpertProfileInfoData?.('phone1', value);
  }
  const handleDescription1OnChange = (value) => {
    changeExpertProfileInfoData?.('description1', value);
  }
  const handleOption1OnChange = (value) => {
    changeExpertProfileInfoData?.('option1', value);
  }
  const handleDescription2OnChange = (value) => {
    changeExpertProfileInfoData?.('description2', value);
  }
  const handleSignOnChange = (value) => {
    changeExpertProfileInfoData?.('sign', value);
  }

  const handleMemberIdChange = (value) => {
    changeExpertProfileInfoData('memberId', value);
  }

  const handleMemberSignIdOnChange = (value) => {
    changeExpertProfileInfoData('memberSignId', value);
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberId, memberSignId) => {
    if(memberId) {
      handleMemberIdChange(memberId);
      handleMemberSignIdOnChange(memberSignId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  useEffect(() => {
    initExpertProfileInfoData();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>Expert Profile</h3>
        </Header>
        <div>
          <InputGroup>
            <InputComponent label={'전문가#'}
              id={'id'}
              type={'number'} checkType={''}
              value={expertProfileInfo?.id} placeholder='입력' maxLength={255}
              style={{width: '10rem'}}
              disabled
            />
            <InputComponent label={'이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.name} placeholder='입력' maxLength={255}
              style={{width: '20rem'}}
              onChange={handleNameOnChange}
            />
            <InputComponent label={'Title'}
              id={'title'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.title} placeholder='입력' maxLength={255}
              style={{width: '23rem'}}
              onChange={handleTitleOnChange}
            />
            <InputComponent label={'Sub Title'}
              id={'subTitle'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.subTitle} placeholder='입력' maxLength={255}
              style={{width: '23rem'}}
              onChange={handleSubTitleOnChange}
            />
            <RadioComponents label={'상태'}
              id={'active'}
              value={expertProfileInfo?.active}
              objectData={ExportActiveType}
              onChange={handleActiveOnChange}
            />
            <RadioComponents label={'레벨'}
              id={'option2'}
              value={expertProfileInfo?.option2}
              objectData={ExportOption2}
              onChange={handleOption2OnChange}
            />
            <CalendarComponent label={'프리미엄 시작 일시'}
              id={'startDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={expertProfileInfo?.startDate||''}
              onChange={handleStartDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            <CalendarComponent label={'프리미엄 종료 일시'}
              id={'endDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={expertProfileInfo?.endDate||''}
              onChange={handleEndDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            <InputComponent
              label={'회원 ID'}
              id={'memberSignId'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.memberSignId} placeholder='입력' maxLength={255}
              style={{width: '20rem'}}
              disabled={true}
            />
            <Button type='button'
              value={'회원조회'}
              onClick={()=> handleUserSearchOnClick()}
              style={{width: '7rem', height: '4.4rem'}}
            />
            <SelectComponentWithObject label={'전문 영역'}
              id={'type'}
              value={expertProfileInfo?.type}
              objectData={ExportCategories}
              onChange={handleTypeOnChange}
              style={{width: '16rem'}}
            />
            <InputComponent label={'소속, 업체'}
              id={'company'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.company} placeholder='입력' maxLength={255}
              onChange={handleCompanyOnChange}
              style={{width: '20rem'}}
            />
            <InputComponent label={'주소'}
              id={'location'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.location} placeholder='입력' maxLength={255}
              onChange={handleLocationOnChange}
              style={{width: '42.5rem'}}
            />
            <InputComponent label={'이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '40%'}}
            />
            <InputComponent label={'홈페이지'}
              id={'homepage'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.homepage} placeholder='입력' maxLength={255}
              onChange={handleHomepageOnChange}
              style={{width: '54%'}}
            />
            <TextareaComponent label={'문의처'}
              id={'phone1'}
              value={expertProfileInfo?.phone1}
              placeholder='입력' maxLength={255}
              onChange={handlePhone1OnChange}
              width={'50%'}
              height={'5rem'}
            />
            <ImageUploadComponent
              images={expertProfileInfo?.images}
              uploadProcess={uploadImageFile}
              deleteProcess={deleteImageFile}
            />
          </InputGroup>
          <InputGroup>
            <TextareaComponent label={'전문가 소개'}
              id={'description1'}
              value={expertProfileInfo?.description1}
              placeholder='전문가 소개' maxLength={255}
              onChange={handleDescription1OnChange}
              width={'100%'}
              height={'5rem'}
            />
            <InputComponent label={'소개 동영상(유투브)'}
              id={'option1'}
              type={'text'} checkType={''}
              value={expertProfileInfo?.option1} placeholder='입력 예: http://www.youtube.com/v/동영상Code -> 동영상Code 부분만.' maxLength={255}
              onChange={handleOption1OnChange}
              style={{width: '100%'}}
            />
            <TextareaComponent label={'동영상 대체 문구'}
              id={'description2'}
              value={expertProfileInfo?.description2} placeholder='입력' maxLength={255}
              onChange={handleDescription2OnChange}
              width={'100%'}
              height={'5rem'}
            />
            <TextareaComponent label={'댓글 서명'}
              id={'sign'}
              value={expertProfileInfo?.sign} placeholder='※ 댓글 하단에 항상 붙는 서명입니다.' maxLength={255}
              onChange={handleSignOnChange}
              width={'100%'}
              height={'5rem'}
            />
          </InputGroup>
        </div>
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
        </ButtonGroup>
      </ModalWindow>
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  max-height: 90vh;
  overflow: scroll;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;