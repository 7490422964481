import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { JobApi } from '../../../api/JobApi';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveType,
  Cities,
  JobCareerType,
  JobCategories,
  JobContacts,
  JobEmploymentType,
  JobSalaryRate,
  JobTypes,
  Premium,
} from '../../../__mocks__/PostData';

export const JobDetail = ({setDialog}) => {
  const jobApi = new JobApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 7,
  });
  const [jobDetail, setJobDetail] = useState({
    id: id,
    boardId: 7,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeJobDetailData = (name, value) => {
    setJobDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeJobDetailImages = (index, imageData) => {
    setJobDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteJobDetailImages = (index) => {
    setJobDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    jobApi.fetchJobById(
      searchParam.id,
      (data) => {
        setLoading(false);
        setJobDetail(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders(null);
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': jobDetail.id,
      'boardId': jobDetail.boardId,
      'active': jobDetail.active,
      'password': jobDetail.password,
      'memberId': jobDetail.memberId,
      'name': jobDetail.name,
      'phone': jobDetail.phone,
      'email': jobDetail.email,
      'contactCode': jobDetail.contactCode,
      'title': jobDetail.title,
      'description': editorRef.current.getContent(),
      'premium': jobDetail.premium,
      'premiumStart': jobDetail.premiumStart,
      'premiumEnd': jobDetail.premiumEnd,
      'hitCount': jobDetail.hitCount,
      'isMainDisplay': jobDetail.isMainDisplay,
      'summary': jobDetail.summary,
      'link': jobDetail.link,
      'type': jobDetail.type,
      'location': jobDetail.location,
      'categoryCode': jobDetail.categoryCode,
      'employmentType': jobDetail.employmentType,
      'salaryRate': jobDetail.salaryRate,
      'price': jobDetail.price,
      'careerType': jobDetail.careerType,
      'endRecruitmentDate': jobDetail.endRecruitmentDate,
    };
    setLoading(true);
    jobApi.saveJob(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(jobDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', jobDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      jobApi.createJobImageFile(
        jobDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeJobDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      errorAlert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(jobDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': jobDetail.id,
      });

      jobApi.deleteJobImageFile(
        jobDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteJobDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeJobDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeJobDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeJobDetailData('password', value);
  }

  const handleMemberNumberChange = (value) => {
    changeJobDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeJobDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeJobDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeJobDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeJobDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = ({target}) => {
    changeJobDetailData('contactCode', target.value);
  }

  const handleTypeOnChange = ({target}) => {
    changeJobDetailData('type', target.value);
  }

  const handleCategoryCodeOnChange = ({target}) => {
    changeJobDetailData('categoryCode', target.value);
  }

  const handleEmploymentTypeOnChange = ({target}) => {
    changeJobDetailData('employmentType', target.value);
  }

  const handleSalaryRateOnChange = ({target}) => {
    changeJobDetailData('salaryRate', target.value);
  }

  const handlePriceOnChange = (value) => {
    changeJobDetailData('price', value);
  }

  const handleCareerTypeOnChange = ({target}) => {
    changeJobDetailData('careerType', target.value);
  }

  const handleEndRecruitmentDateOnChange = (value) => {
    changeJobDetailData('endRecruitmentDate', value);
  }

  const handleLocationOnChange = ({target}) => {
    changeJobDetailData('location', target.value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeJobDetailData('isMainDisplay', true);
    else
      changeJobDetailData('isMainDisplay', false);
  }

  const handlePremiumOnChange = ({target}) => {
    changeJobDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeJobDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeJobDetailData('premiumEnd', value);
  }

  const handleSummaryOnChange = (value) => {
    changeJobDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeJobDetailData('link', value.trim());
  }

  const handleTitleEndOnChange = (value) => {
    changeJobDetailData('title', value.trim());
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            {link: "/", name: "마켓 콘텐츠", isLink: false},
            {link: `/market/job`, name: "구인/구직 목록", isLink: true},
            {link: `/market/job/${jobDetail?.id}`, name: `${jobDetail?.id}`, isLink: true}
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={jobDetail.id} placeholder='입력' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={jobDetail?.active}
            objectData={ActiveType}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'} type={'number'} checkType={''}
            id={'hitCount'}
            value={jobDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={jobDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={jobDetail.memberEmail} placeholder='입력' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={jobDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject label={'Premium'}
            id={'premium'}
            value={jobDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작일'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={jobDetail.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료일'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={jobDetail.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={jobDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={jobDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent label={'업체명/구직자'} type={'text'} checkType={''}
            id={'name'}
            value={jobDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent label={'전화번호'} type={'text'} checkType={''}
            id={'phone'}
            value={jobDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent label={'이메일'} type={'text'} checkType={''}
            id={'email'}
            value={jobDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <SelectComponentWithObject label={'연락망 선택'}
            id={'contactCode'}
            value={jobDetail?.contactCode}
            objectData={JobContacts}
            onChange={handleContactCodeOnChange}
            style={{width: '10rem'}}
          />
          <SelectComponentWithObject label={'구분'}
            id={'type'}
            value={jobDetail?.type}
            objectData={JobTypes}
            onChange={handleTypeOnChange}
            style={{width: '10rem'}}
          />
          <SelectComponentWithObject label={'모집분야'}
            id={'categoryCode'}
            value={jobDetail?.categoryCode}
            objectData={JobCategories}
            onChange={handleCategoryCodeOnChange}
            style={{width: '100px'}}
          />
          <SelectComponentWithObject label={'고용형태'}
            id={'employmentType'}
            value={jobDetail?.employmentType}
            objectData={JobEmploymentType}
            onChange={handleEmploymentTypeOnChange}
            style={{width: '100px'}}
          />
          <SelectComponentWithObject label={'임금구분'}
            id={'salaryRate'}
            value={jobDetail?.salaryRate}
            objectData={JobSalaryRate}
            onChange={handleSalaryRateOnChange}
            style={{width: '100px'}}
          />
          <InputComponent label={'임금금액'} type={'number'} checkType={'positiveNumber'}
            id={'price'}
            value={jobDetail.price} placeholder='입력' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '130px', textAlign: 'right'}}
          />
          <SelectComponentWithObject label={'경력'}
            id={'careerType'}
            value={jobDetail?.careerType}
            objectData={JobCareerType}
            onChange={handleCareerTypeOnChange}
            style={{width: '100px'}}
          />
          <CalendarComponent
            label={'모집/구직마감일'}
            id={'endRecruitmentDate'}
            dateFormat={'yyyy-MM-dd'}
            placeholderText={'yyyy-MM-dd'}
            value={jobDetail.endRecruitmentDate}
            onChange={handleEndRecruitmentDateOnChange}
            isClearable={true}
            isShowTimeSelect={false}
            width={'13rem'}
          />
          <SelectComponentWithObject
            label={'지역'}
            id={'location'}
            value={jobDetail?.location}
            objectData={Cities}
            onChange={handleLocationOnChange}
            style={{width: '12rem'}}
          />

          <InputComponent
            label={'수정일시'} type={'text'} checkType={''}
            id={'orderDateTime'}
            value={jobDetail.updatedDate} placeholder='입력' maxLength={255}
            style={{width: '16rem', textAlign: 'center'}}
            disabled={true}
          />
          <InputComponent
            label={'등록일시'} type={'text'} checkType={''}
            id={'postedDate'}
            value={jobDetail.postedDate} placeholder='입력' maxLength={255}
            style={{width: '16rem'}}
            disabled={true}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'} type={'text'} checkType={''}
            id={'title'}
            value={jobDetail.title} placeholder='입력' maxLength={255}
            style={{width: '50rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={jobDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={jobDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {orders?.resultList?.map((item) => (
                <tr key={item.id} onClick={()=>handleOrderOnClick(item.id)}>
                  <td>{item.id}</td>
                  <td>{item.orderDateTime}</td>
                  <td>{item.paymentType}</td>
                  <td>{item.status}</td>
                  <td>{item.grandTotalAmount}</td>
                  <td>{item.totalItemAmount}</td>
                  <td>{item.totalGstAmount}</td>
                  <td>
                    {item.memberEmail}
                    <br/>
                    {item.memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    margin: 0 0.1rem;
  }
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
