import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { HomestayApi } from '../../../api/HomestayApi';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { CheckBoxComponent } from '../../../component/CheckBoxComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  ContactTypes,
  HomestayCheckInTypes,
  HomestayDetailedOptions,
  HomestayGuestGender,
  HomestayMinimumReservation,
  HomestayPaymentFrequency,
  HomestayRoomTypes,
  HomestayTypes,
  Premium,
  RealEstateCities,
  RealEstateProvinces,
} from '../../../__mocks__/PostData';

export const HomestayDetail = ({setDialog}) => {
  const homestayApi = new HomestayApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 5,
  });
  const [homestayDetail, setHomestayDetail] = useState({
    id: 0,
    boardId: 5,
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeHomestayDetailData = (name, value) => {
    setHomestayDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeHomestayDetailImages = (index, imageData) => {
    setHomestayDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteHomestayDetailImages = (index) => {
    setHomestayDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    homestayApi.fetchHomestayById(
      searchParam.id,
      (data) => {
        setHomestayDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': homestayDetail.id,
      'boardId': homestayDetail.boardId,
      'active': homestayDetail.active,
      'password': homestayDetail.password,
      'memberId': homestayDetail.memberId,
      'type': homestayDetail.type,
      'name': homestayDetail.name,
      'email': homestayDetail.email,
      'phone': homestayDetail.phone,
      'contactCode': homestayDetail.contactCode,
      'title': homestayDetail.title,
      'description': editorRef.current.getContent(),
      'price': homestayDetail.price,
      'hitCount': homestayDetail.hitCount,
      'isMainDisplay': homestayDetail.isMainDisplay,
      'premium': homestayDetail.premium,
      'premiumStart': homestayDetail.premiumStart,
      'premiumEnd': homestayDetail.premiumEnd,
      'link': homestayDetail.link,
      'summary': homestayDetail.summary,
      'isSoldOut': homestayDetail.isSoldOut,
      'locationId': homestayDetail.locationId,
      'locationSubId': homestayDetail.locationSubId,
      'address': homestayDetail.address,
      'postal': homestayDetail.postal,
      'roomType': homestayDetail.roomType,
      'bedroomCount': homestayDetail.bedroomCount,
      'bathroomCount': homestayDetail.bathroomCount,
      'roomCapacity': homestayDetail.roomCapacity,
      'guestGender': homestayDetail.guestGender,
      'paymentFrequency': homestayDetail.paymentFrequency,
      'deposit': homestayDetail.deposit,
      'checkInType': homestayDetail.checkInType,
      'checkInDate': homestayDetail.checkInDate,
      'minimumReservation': homestayDetail.minimumReservation,
      'detailedOptions': homestayDetail.detailedOptions,
    };
    setLoading(true);
    homestayApi.saveHomestay(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(homestayDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', homestayDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      homestayApi.createHomestayImageFile(
        homestayDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeHomestayDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(homestayDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': homestayDetail.id,
      });

      homestayApi.deleteHomestayImageFile(
        homestayDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteHomestayDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeHomestayDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeHomestayDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeHomestayDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeHomestayDetailData(key, true);
    else
      changeHomestayDetailData(key, false);
  }

  const handleMemberNumberChange = (value) => {
    changeHomestayDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeHomestayDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeHomestayDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeHomestayDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeHomestayDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeHomestayDetailData('contactCode', value);
  }

  const handleTypeOnChange = ({target}) => {
    changeHomestayDetailData('type', target.value);
  }

  const handleRoomTypeOnChange = ({target}) => {
    changeHomestayDetailData('roomType', target.value);
  }

  const handlePriceOnChange = (value) => {
    changeHomestayDetailData('price', value);
  }

  const handleDepositOnChange = (value) => {
    changeHomestayDetailData('deposit', value);
  }

  const handleSummaryOnChange = (value) => {
    changeHomestayDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeHomestayDetailData('link', value.trim())
  }

  const handleLocationIdOnChange = ({target}) => {
    changeHomestayDetailData('locationId', target.value);
  }

  const handleLocationSubIdOnChange = ({target}) => {
    changeHomestayDetailData('locationSubId', target.value);
  }

  const handleAddressOnChange = (value) => {
    changeHomestayDetailData('address', value.trim());
  }

  const handlePostalOnChange = (value) => {
    changeHomestayDetailData('postal', value.trim());
  }

  const handleBedroomCountOnChange = (value) => {
    changeHomestayDetailData('bedroomCount', value);
  }

  const handleBathroomCountOnChange = (value) => {
    changeHomestayDetailData('bathroomCount', value);
  }

  const handleRoomCapacityOnChange = (value) => {
    changeHomestayDetailData('roomCapacity', value);
  }

  const handleGuestGenderOnChange = (value) => {
    changeHomestayDetailData('guestGender', value)
  }

  const handlePaymentFrequencyOnChange = (value) => {
    changeHomestayDetailData('paymentFrequency', value)
  }

  const handleCheckInTypeOnChange = ({target}) => {
    if("checkIn-date" !== target.value) {
      changeHomestayDetailData('checkInDate', null);
    }
    changeHomestayDetailData('checkInType', target.value);
  }

  const handleCheckInDateOnChange = (value) => {
    changeHomestayDetailData('checkInType', "checkIn-date");
    changeHomestayDetailData('checkInDate', value);
  }

  const handleMinimumReservationOnChange = ({target}) => {
    changeHomestayDetailData('minimumReservation', target.value);
  }

  const handleSoldOutOnChange = (key, value) => {
    if(value)
      changeHomestayDetailData(key, true);
    else
      changeHomestayDetailData(key, false);
  }

  const handleDetailedOptionsOnChange = (value) => {
    changeHomestayDetailData('detailedOptions', value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeHomestayDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeHomestayDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeHomestayDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeHomestayDetailData('title', value.trim());
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.postId) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.postId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/homestay`, name: "홈스테이", isLink: true },
            { link: `/market/homestay/${homestayDetail?.id}`, name: `${homestayDetail?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={homestayDetail.id} placeholder='입력' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={homestayDetail?.active}
            objectData={ActiveTypeList}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={homestayDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={homestayDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={homestayDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={homestayDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={homestayDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={homestayDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={homestayDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={homestayDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={homestayDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'임대인'}
            id={'name'}
            type={'text'} checkType={''}
            value={homestayDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={homestayDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={homestayDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={homestayDetail?.contactCode}
            objectData={ContactTypes}
            onChange={handleContactCodeOnChange}
          />
          <SelectComponentWithObject
            label={'주'}
            id={'locationId'}
            value={homestayDetail?.locationId}
            objectData={RealEstateProvinces}
            onChange={handleLocationIdOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'도시'}
            id={'locationSubId'}
            value={homestayDetail?.locationSubId}
            objectData={RealEstateCities}
            onChange={handleLocationSubIdOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'나머지 주소'}
            id={'address'}
            type={'text'}
            checkType={''}
            value={homestayDetail.address}
            placeholder='예) 331A-4501 North Road' maxLength={255}
            onChange={handleAddressOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'우편번호'}
            id={'postal'}
            type={'text'}
            checkType={''}
            value={homestayDetail.postal}
            placeholder='예) V3N 4R7' maxLength={10}
            onChange={handlePostalOnChange}
            style={{width: '10rem'}}
          />
          <SelectComponentWithObject
            label={'숙박유형 L1'}
            id={'type'}
            value={homestayDetail?.type}
            objectData={HomestayTypes}
            onChange={handleTypeOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'숙박유형 L2'}
            id={'roomType'}
            value={homestayDetail?.roomType}
            objectData={HomestayRoomTypes}
            onChange={handleRoomTypeOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'침실(수)'}
            id={'bedroomCount'}
            type={'number'}
            checkType={''}
            value={homestayDetail.bedroomCount}
            placeholder='예) 침실 2개 -> 2' maxLength={10}
            onChange={handleBedroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'화장실(수)'}
            id={'bathroomCount'}
            type={'number'}
            checkType={''}
            value={homestayDetail.bathroomCount}
            placeholder='예) 화장실 2개 -> 2' maxLength={10}
            onChange={handleBathroomCountOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'객실정원'}
            id={'roomCapacity'}
            type={'number'}
            checkType={''}
            value={homestayDetail.roomCapacity}
            placeholder='명' maxLength={10}
            onChange={handleRoomCapacityOnChange}
            style={{width: '7.4rem', textAlign: 'right'}}
          />
          <RadioComponents
            label={'성별구별'}
            id={'guestGender'}
            value={homestayDetail?.guestGender}
            objectData={HomestayGuestGender}
            onChange={handleGuestGenderOnChange}
          />
          <RadioComponents
            label={'지불기준'}
            id={'paymentFrequency'}
            value={homestayDetail?.paymentFrequency}
            objectData={HomestayPaymentFrequency}
            onChange={handlePaymentFrequencyOnChange}
          />
          <InputComponent
            label={'가격'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={homestayDetail.price} placeholder='입력' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'디파짓'}
            id={'deposit'}
            type={'number'}
            checkType={'positiveNumber'}
            value={homestayDetail.deposit} placeholder='' maxLength={20}
            onChange={handleDepositOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <SelectComponentWithObject
            label={'입실유형'}
            id={'checkInType'}
            value={homestayDetail?.checkInType}
            objectData={HomestayCheckInTypes}
            onChange={handleCheckInTypeOnChange}
            style={{width: '12rem'}}
          />
          <CalendarComponent
            label={'입실날짜 '}
            id={'checkInDate'}
            dateFormat={'yyyy-MM-dd'}
            placeholderText={'yyyy-MM-dd'}
            value={homestayDetail.checkInDate}
            onChange={handleCheckInDateOnChange}
            isClearable={true}
            isShowTimeSelect={false}
            width={'13rem'}
          />
          <SelectComponentWithObject
            label={'최소예약'}
            id={'minimumReservation'}
            value={homestayDetail?.minimumReservation}
            objectData={HomestayMinimumReservation}
            onChange={handleMinimumReservationOnChange}
            style={{width: '12rem'}}
          />
          <SwitchComponent
            label={'Occupied'}
            id={'isSoldOut'}
            value={homestayDetail?.isSoldOut}
            checkedValue={true}
            onChange={handleSoldOutOnChange}
            style={{width: '9.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <CheckBoxComponent
            label={'상세옵션'}
            id='detailedOptions'
            value={homestayDetail?.detailedOptions}
            delimiter={''}
            objectData={HomestayDetailedOptions}
            onChange={handleDetailedOptionsOnChange}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={homestayDetail.title}
            placeholder='입력' maxLength={255}
            style={{width: '90rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={homestayDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={homestayDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {
      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
