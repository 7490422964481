import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../component/Breadcrumbs';
import { InputComponent } from '../../../component/InputComponent';
import { RadioComponents } from '../../../component/RadioComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { CarApi } from '../../../api/CarApi';
import { OrderApi } from '../../../api/OrderApi';
import { OrderDetailModal } from '../../order/component/OrderDetailModal';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { EditorComponent } from '../../../component/EditorComponent';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { CheckBoxComponent } from '../../../component/CheckBoxComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { Loader } from '../../../component/Loader';
import { Button } from '../../../component/Button';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import {
  ActiveTypeList,
  CarAdditionalOptions,
  CarBrands,
  CarColors,
  CarDrivingSystems,
  CarFlagTypes,
  CarGesTypes,
  CarIncludedOptions,
  CarTransmissions,
  CarTypes,
  Cities,
  ContactTypes,
  Premium,
} from '../../../__mocks__/PostData';

export const CarDetail = ({setDialog}) => {
  const carApi = new CarApi();
  const orderApi = new OrderApi();
  const { id } = useParams();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    id: '',
    show: false,
  });
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [searchParam] = useState({
    id: id,
    boardId: 2,
  });
  const [carDetail, setCarDetail] = useState({
    id: id,
    boardId: 2,
    additionalOptions: '',
    includedOptions: '',
    images: {},
  });
  const [orders, setOrders] = useState({});

  // == state 변경 ---------------------------------------------
  const changeCarDetailData = (name, value) => {
    setCarDetail((values) => {
      const updated = {...values};
      updated[name] = value;
      return updated;
    });
  };

  const changeCarDetailImages = (index, imageData) => {
    setCarDetail((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteCarDetailImages = (index) => {
    setCarDetail((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleSearchOnClick = () => {
    handleSearch();
  }

  const handleSearch = () => {

    setLoading(true);
    carApi.fetchCarById(
      searchParam.id,
      (data) => {
        setCarDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSearchOrderInfo = (boardId, postId) => {
    setOrders({});
    orderApi.fetchOrderListByRefPostId(
      boardId,
      postId,
      (data) => {
        setOrders(data?.resultList);
      },
      (error) => {
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    const formData = {
      'id': carDetail.id,
      'boardId': carDetail.boardId,
      'active': carDetail.active,
      'password': carDetail.password,
      'memberId': carDetail.memberId,
      'type': carDetail.type,
      'name': carDetail.name,
      'email': carDetail.email,
      'phone': carDetail.phone,
      'contactCode': carDetail.contactCode,
      'title': carDetail.title,
      'description': editorRef.current.getContent(),
      'price': carDetail.price,
      'hitCount': carDetail.hitCount,
      'isMainDisplay': carDetail.isMainDisplay,
      'premium': carDetail.premium,
      'premiumStart': carDetail.premiumStart,
      'premiumEnd': carDetail.premiumEnd,
      'link': carDetail.link,
      'summary': carDetail.summary,
      'isSoldOut': carDetail.isSoldOut,
      'location': carDetail.location,
      'model': carDetail.model,
      'color': carDetail.color,
      'mileage': carDetail.mileage,
      'agerange': carDetail.agerange,
      'personalFlag': carDetail.personalFlag,
      'categoryCode': carDetail.categoryCode,
      'gasType': carDetail.gasType,
      'transmission': carDetail.transmission,
      'drivingSystem': carDetail.drivingSystem,
      'flagType': carDetail.flagType,
      'brand': carDetail.brand,
      'includedOptions': carDetail.includedOptions,
      'additionalOptions': carDetail.additionalOptions,
    };
    setLoading(true);
    carApi.saveCar(
      searchParam.id,
      formData,
      (data) => {
        setLoading(false);
        handleSearchOnClick();
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const confirmSave = useConfirm(
    "저장하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const uploadImageFile = (file, index) => {
    if(carDetail.id && file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('postId', carDetail.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      carApi.createCarImageFile(
        carDetail.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeCarDetailImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const deleteImageFile = (index, fileId) => {
    if(carDetail.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': carDetail.id,
      });

      carApi.deleteCarImageFile(
        carDetail.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteCarDetailImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleActiveOnChange = ({target}) => {
    changeCarDetailData('active', target.value);
  }

  const handleHitCountOnChange = (value) => {
    changeCarDetailData('hitCount', value);
  }

  const handlePasswordCountOnChange = (value) => {
    changeCarDetailData('password', value);
  }

  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeCarDetailData(key, true);
    else
      changeCarDetailData(key, false);
  }

  const handleMemberNumberChange = (value) => {
    changeCarDetailData('memberId', value);
  }

  const handleMemberIdOnChange = (value) => {
    changeCarDetailData('memberEmail', value);
  }

  const handleNameOnChange = (value) => {
    changeCarDetailData('name', value.trim());
  }

  const handlePhoneOnChange = (value) => {
    changeCarDetailData('phone', value.trim());
  }

  const handleEmailOnChange = (value) => {
    changeCarDetailData('email', value.trim());
  }

  const handleContactCodeOnChange = (value) => {
    changeCarDetailData('contactCode', value);
  }

  const handleSummaryOnChange = (value) => {
    changeCarDetailData('summary', value.trim());
  }

  const handleLinkOnChange = (value) => {
    changeCarDetailData('link', value.trim())
  }

  const handleSoldOutOnChange = (key, value) => {
    if(value)
      changeCarDetailData(key, true);
    else
      changeCarDetailData(key, false);
  }

  const handleIncludedOptionsOnChange = (value) => {
    changeCarDetailData('includedOptions', value);
  }

  const handleAdditionalOptionsOnChange = (value) => {
    changeCarDetailData('additionalOptions', value);
  }

  const handlePremiumOnChange = ({target}) => {
    changeCarDetailData('premium', target.value);
  }

  const handlePremiumStartOnChange = (value) => {
    changeCarDetailData('premiumStart', value);
  }

  const handlePremiumEndOnChange = (value) => {
    changeCarDetailData('premiumEnd', value);
  }

  const handleTitleEndOnChange = (value) => {
    changeCarDetailData('title', value.trim());
  }

  const handleLocationOnChange = ({target}) => {
    changeCarDetailData('location', target.value);
  }

  const handleFlagTypeOnChange = ({target}) => {
    changeCarDetailData('flagType', target.value);
  }

  const handleTypeOnChange = (value) => {
    changeCarDetailData('type', value);
  }

  const handleBrandOnChange = ({target}) => {
    changeCarDetailData('brand', target.value);
  }

  const handleModelOnChange = (value) => {
    changeCarDetailData('model', value);
  }

  const handleAgerangeChange = (value) => {
    changeCarDetailData('agerange', value);
  }

  const handleMileageOnChange = (value) => {
    changeCarDetailData('mileage', value);
  }

  const handlePriceOnChange = (value) => {
    changeCarDetailData('price', value);
  }

  const handleGesTypeOnChange = (value) => {
    changeCarDetailData('gasType', value);
  }

  const handleTransmissionsOnChange = (value) => {
    changeCarDetailData('transmission', value);
  }

  const handleDrivingSystemsOnChange = (value) => {
    changeCarDetailData('drivingSystem', value);
  }

  const handleColorsOnChange = ({target}) => {
    changeCarDetailData('color', target.value);
  }

  // == modal ------------------------------------------------
  const handleOrderOnClick = (id) => {
    handleShowModal(id);
  }

  const handleShowModal = (id) => {
    setModalInfo({
      id: id,
      show: true
    });
  }

  const handleCloseModal = () => {
    setModalInfo({
      id: '',
      show: false
    });
  }

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId) => {
    if(memberNumber) {
      handleMemberNumberChange(memberNumber);
      handleMemberIdOnChange(memberId);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // == rendering ------------------------------------------------
  useEffect(() => {
    handleSearch();
    if(searchParam?.id) {
      handleSearchOrderInfo(searchParam?.boardId, searchParam?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <HeaderSection>
        <Breadcrumbs
          list={[
            { link: "/", name: "게시판 관리", isLink: false },
            { link: `/market/car`, name: "자동차", isLink: true },
            { link: `/market/car/${searchParam?.id}`, name: `${searchParam?.id}`, isLink: true }
          ]}
        />
        <ButtonGroup>
          <Button type='button' value={'저장'} onClick={confirmSave} />
          <Button type='button' value={'조회'} onClick={()=> handleSearchOnClick()} />
        </ButtonGroup>
      </HeaderSection>
      <Section>
        <InputGroup>
          <InputComponent
            label={'ID'}
            id={'id'}
            type={'text'} checkType={''}
            value={carDetail.id} placeholder='입력' maxLength={255}
            style={{width: '10rem'}}
            disabled={true}
          />
          <SelectComponentWithObject
            label={'상태'}
            id={'active'}
            value={carDetail?.active}
            objectData={ActiveTypeList}
            onChange={handleActiveOnChange}
            style={{width: '8.5rem'}}
          />
          <InputComponent
            label={'조회수'}
            id={'hitCount'}
            type={'number'} checkType={''}
            value={carDetail.hitCount} placeholder='입력' min={0} maxLength={255}
            onChange={handleHitCountOnChange}
            style={{width: '8rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'게시판 비번'}
            id={'password'}
            type={'password'} checkType={''}
            value={carDetail.password} placeholder='' min={0} maxLength={255}
            onChange={handlePasswordCountOnChange}
            style={{width: '15rem'}}
          />
          <InputComponent
            label={'회원 ID'}
            id={'memberEmail'}
            type={'text'} checkType={''}
            value={carDetail.memberEmail} placeholder='' maxLength={255}
            style={{width: '20rem'}}
            disabled={true}
          />
          <Button type='button'
            value={'회원조회'}
            onClick={()=> handleUserSearchOnClick()}
            style={{width: '7rem', height: '4.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <SwitchComponent
            label={'메인 프리미엄'}
            id={'isMainDisplay'}
            value={carDetail?.isMainDisplay}
            checkedValue={true}
            onChange={handleMainDisplayOnChange}
            style={{width: '9.4rem'}}
          />
          <SelectComponentWithObject
            label={'프리미엄'}
            id={'premium'}
            value={carDetail?.premium}
            objectData={Premium}
            onChange={handlePremiumOnChange}
            style={{width: '8rem'}}
          />
          <CalendarComponent
            label={'광고 시작'}
            id={'premiumStart'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={carDetail?.premiumStart}
            onChange={handlePremiumStartOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <CalendarComponent
            label={'광고 종료'}
            id={'premiumEnd'}
            dateFormat={'yyyy-MM-dd HH:mm:ss'}
            placeholderText={'yyyy-MM-dd HH:mm:ss'}
            value={carDetail?.premiumEnd}
            onChange={handlePremiumEndOnChange}
            isClearable={true}
            isShowTimeSelect={true}
            width={'20rem'}
          />
          <InputComponent
            label={'프리미엄 요약문구'}
            id={'summary'}
            type={'text'}
            checkType={''}
            value={carDetail.summary}
            placeholder={'한줄 문구를 10자 이내로 입력해 주세요.'}
            maxLength={20}
            onChange={handleSummaryOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'클릭 바로가기'}
            id={'link'}
            type={'text'}
            checkType={''}
            value={carDetail.link}
            placeholder={'ex)https://www.vanchosun.com'}
            maxLength={200}
            onChange={handleLinkOnChange}
            style={{width: '25rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'딜러/딜러샵'}
            id={'name'}
            type={'text'} checkType={''}
            value={carDetail.name} placeholder='입력' maxLength={255}
            onChange={handleNameOnChange}
            style={{width: '20rem'}}
          />
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={carDetail.phone} placeholder='입력' maxLength={255}
            onChange={handlePhoneOnChange}
            style={{width: '13rem'}}
          />
          <InputComponent
            label={'이메일'}
            id={'email'}
            type={'text'} checkType={''}
            value={carDetail.email} placeholder='입력' maxLength={255}
            onChange={handleEmailOnChange}
            style={{width: '20rem'}}
          />
          <RadioComponents
            label={'연락망'}
            id={'contactCode'}
            value={carDetail?.contactCode}
            objectData={ContactTypes}
            onChange={handleContactCodeOnChange}
          />

          <SelectComponentWithObject
            label={'지역'}
            id={'location'}
            value={carDetail?.location}
            objectData={Cities}
            onChange={handleLocationOnChange}
            style={{width: '12rem'}}
          />
          <SelectComponentWithObject
            label={'플래그 선택'}
            id={'flagType'}
            value={carDetail?.flagType}
            objectData={CarFlagTypes}
            onChange={handleFlagTypeOnChange}
            style={{width: '12rem'}}
          />
          <RadioComponents
            label={'차종별'}
            id={'type'}
            value={carDetail?.type}
            objectData={CarTypes}
            onChange={handleTypeOnChange}
          />
          <SelectComponentWithObject
            label={'브랜드'}
            id={'brand'}
            value={carDetail?.brand}
            objectData={{'': { value: '', label: '기타'}, ...CarBrands}}
            onChange={handleBrandOnChange}
            style={{width: '12rem'}}
          />
          <InputComponent
            label={'모델명'}
            id={'model'}
            type={'text'}
            checkType={''}
            value={carDetail.model} placeholder='입력' maxLength={255}
            onChange={handleModelOnChange}
            style={{width: '35rem'}}
          />
          <InputComponent
            label={'연식'}
            id={'agerange'}
            type={'number'}
            checkType={'positiveNumber'}
            value={carDetail.agerange}
            placeholder='예) 2001년 -> 2001' maxLength={20}
            onChange={handleAgerangeChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'운행(Km)'}
            id={'mileage'}
            type={'number'}
            checkType={'positiveNumber'}
            value={carDetail.mileage} placeholder='예) 12,000Km -> 12000' maxLength={20}
            onChange={handleMileageOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <InputComponent
            label={'가격'}
            id={'price'}
            type={'number'}
            checkType={'positiveNumber'}
            value={carDetail.price} placeholder='$' maxLength={20}
            onChange={handlePriceOnChange}
            style={{width: '13rem', textAlign: 'right'}}
          />
          <RadioComponents
            label={'연료'}
            id={'gasType'}
            value={carDetail?.gasType}
            objectData={CarGesTypes}
            onChange={handleGesTypeOnChange}
          />
          <RadioComponents
            label={'변속기'}
            id={'transmission'}
            value={carDetail?.transmission}
            objectData={CarTransmissions}
            onChange={handleTransmissionsOnChange}
          />
          <RadioComponents
            label={'구동'}
            id={'drivingSystem'}
            value={carDetail?.drivingSystem}
            objectData={CarDrivingSystems}
            onChange={handleDrivingSystemsOnChange}
          />
          <SelectComponentWithObject
            label={'색상'}
            id={'bracolord'}
            value={carDetail?.color}
            objectData={CarColors}
            onChange={handleColorsOnChange}
            style={{width: '12rem'}}
          />
          <SwitchComponent
            label={'Sold Out'}
            id={'isSoldOut'}
            value={carDetail?.isSoldOut}
            checkedValue={true}
            onChange={handleSoldOutOnChange}
            style={{width: '9.4rem'}}
          />
        </InputGroup>
        <InputGroup>
          <CheckBoxComponent
            label={'옵션정보'}
            id='includedOptions'
            value={carDetail?.includedOptions||''}
            delimiter={''}
            objectData={CarIncludedOptions}
            onChange={handleIncludedOptionsOnChange}
          />
          <CheckBoxComponent
            label={'특이사항'}
            id='additionalOptions'
            value={carDetail?.additionalOptions||''}
            delimiter={''}
            objectData={CarAdditionalOptions}
            onChange={handleAdditionalOptionsOnChange}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'제목'}
            id={'title'}
            type={'text'}
            checkType={''}
            value={carDetail.title}
            placeholder='입력' maxLength={255}
            style={{width: '90rem'}}
            onChange={handleTitleEndOnChange}
          />
          <EditorComponent
            editorRef={editorRef}
            description={carDetail.description}
            width={'100%'}
            height={'20rem'}
          />
        </InputGroup>
      </Section>
      <Section>
        <ImageUploadComponent
          images={carDetail.images}
          uploadProcess={uploadImageFile}
          deleteProcess={deleteImageFile}
          setDialog={setDialog}
        />
      </Section>
      <Section>
        <InputGroupTitle>주문정보</InputGroupTitle>
        <OrderDetailListSection>
          <table>
            <thead>
              <tr>
                <TableTh width={50}>#</TableTh>
                <TableTh width={150}>주문일시</TableTh>
                <TableTh width={80}>결제방법</TableTh>
                <TableTh width={80}>상태</TableTh>
                <TableTh width={80}>총합 금액</TableTh>
                <TableTh width={80}>총 금액</TableTh>
                <TableTh width={80}>총 Gst</TableTh>
                <TableTh width={150}>주문자</TableTh>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders)?.map((key) => (
                <tr key={orders[key].id} onClick={()=>handleOrderOnClick(orders[key].id)}>
                  <td>{orders[key].id}</td>
                  <td>{orders[key].orderDateTime}</td>
                  <td>{orders[key].paymentType}</td>
                  <td>{orders[key].status}</td>
                  <td>{orders[key].grandTotalAmount}</td>
                  <td>{orders[key].totalItemAmount}</td>
                  <td>{orders[key].totalGstAmount}</td>
                  <td>
                    {orders[key].memberEmail}
                    <br/>
                    {orders[key].memberId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </OrderDetailListSection>
      </Section>
      <OrderDetailModal
        id={modalInfo.id}
        show={modalInfo.show}
        onClose={handleCloseModal} />
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem 1rem 0rem 1rem;
`;
const HeaderSection = styled.div`
`;

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
`;

const InputGroupTitle = styled.h4`
  margin: 1rem 0;
  padding: 0 1.6rem;
  font-size: 0.8em;
`;

const InputGroup = styled.div`
  margin: 0;
  padding: 0 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  & > div {
    margin: 1rem 1rem 1rem 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const TableTh = styled.th`
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

const OrderDetailListSection = styled.div`
  margin: 0.5rem 1.6rem;
  padding: 0;
  height: 19rem;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  overflow: auto;
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    & thead {
      position: sticky;
      top: 0;
      background: #a88;
      color:#fff;
      & tr {
        height: 3.4rem;
        & th {
          border-bottom: 0.1rem solid #ddd;
        }
      }
    }
    & tbody {

      & tr {
        height: 3.4rem;
        cursor: pointer;
        & td {
          padding: 0.3rem;
          text-align: center;
          word-break:break-all;
        }
        &:hover {
          background-color: #ffe9d9;
        }
      }
      & tr:nth-child(2n+1) {
        background-color: #f0f0f0;
        &:hover {
          background-color: #ffe9d9;
        }
      }
    }
  }
`;
