import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { UserApi } from '../../api/UserApi';
import { InputComponent } from '../../component/InputComponent';
import { Button } from '../../component/Button';
import { Loader } from '../../component/Loader';
import { errorAlert } from '../../util/ErrorHandler';

export const User = ({setDialog}) => {
  const userApi = new UserApi();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: id,
    username: '',
    password: '',
    name: '',
    phone: '',
  });

  const handleSearchOnClick = () => {
    setLoading(true);
    userApi.fetchUserId(
      id,
      (data) => {
        setUserInfo(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
        // setDialog({isOpen: true, message: error.response.data.error_message, type: "error"});
      }
    );
  }

  useEffect(() => {
    handleSearchOnClick();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      <ButtonGroup>
        <Button type='button' value={'뒤로가기'} />
        <Button type='button' value={'저장'} />
      </ButtonGroup>
      내 계정 정보
      <div>
        <InputGroup>
          <InputComponent
            label={'id'}
            id={'id'}
            type={'text'} checkType={''}
            value={userInfo.id}
            placeholder={'입력'} maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'username'}
            id={'username'}
            type={'text'} checkType={''}
            value={userInfo.username}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'password'}
            id={'password'}
            type={'password'} checkType={''}
            value={userInfo.password}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'confirm password'}
            id={'confirmPassword'}
            type={'password'} checkType={''}
            value={userInfo.confirmPassword}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <hr />
        <InputGroup>
          <InputComponent
            label={'name'}
            id={'name'}
            type={'text'} checkType={''}
            value={userInfo.name}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'emailAddress'}
            id={'emailAddress'}
            type={'text'} checkType={''}
            value={userInfo.emailAddress}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'업체명'}
            id={'emailAddress'}
            type={'text'} checkType={''}
            value={userInfo.emailAddress}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'전화번호'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'계약기간'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'계정 타입'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder='입력' maxLength={255}
            style={{width: '200px'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'계정 상태'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder={'입력'} maxLength={255}
            style={{width: '20rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'칼럼 타입'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder={'입력'} maxLength={255}
            style={{width: '20rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'최근 접속 기록'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder={'입력'} maxLength={255}
            style={{width: '20rem'}}
          />
        </InputGroup>
        <InputGroup>
          <InputComponent
            label={'최근 접속 IP'}
            id={'phone'}
            type={'text'} checkType={''}
            value={userInfo.phone}
            placeholder={'입력'} maxLength={255}
            style={{width: '20rem'}}
          />
        </InputGroup>
      </div>
      <Loader loading={loading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: auto;
`;

const ButtonGroup = styled.div`
  margin: 0 1rem;
  display: flex;
  justify-content: flex-end;
`;

const InputGroup = styled.div`
  margin: 0.5rem;
  display: flex;
`;