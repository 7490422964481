import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { AdApi } from '../../../api/AdApi';
import { InputComponent } from '../../../component/InputComponent';
import { EditorComponent } from '../../../component/EditorComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { SwitchComponent } from '../../../component/SwitchComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { Button } from '../../../component/Button';
import { Loader } from '../../../component/Loader';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import { addHyphenForPhoneNumber, isBlankValue } from '../../../util/utils';
import {
  MarketAdType,
  MarketAdBannerPositionData,
  MarketAdLinePositionData,
  MarketAdMobilePositionData,
  MarketAdFlashPositionData,
  MarketAdPopupPositionData,
  MarketAdMoviePositionData,
  ActiveTypeList,
} from '../../../__mocks__/PostData';

export const MarketAdModal = ({id, show, onClose, setDialog}) => {
  const adApi = new AdApi();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [adPositionLv1, setAdPositionLv1] = useState([{value: '', label: '선택하세요.'}]);
  const [adPositionLv2, setAdPositionLv2] = useState([{value: '', label: '선택하세요.'}]);
  const [adInfo, setAdInfo] = useState({
    id: id,
    type: '',
    boardId: 0,
    positionLv1: '',
    positionLv2: '',
    password: '',
    name: '',
    phone: '',
    email: '',
    title: '',
    description: '',
    link: '',
    movieLink: '',
    isMainDisplay: false,
    hit: 0,
    rank: 0,
    activeCode: '',
    activeName: '',
    postDate: '',
    startDate: '',
    endDate: '',
    manager: '',
    managerEmail: '',
    managerName: '',
    images: {},
  });

  // state 변경 ------------------------------------------------
  const init = () => {
    setAdInfo({
      id: id,
      type: '',
      boardId: 0,
      positionLv1: '',
      positionLv2: '',
      password: '',
      name: '',
      phone: '',
      email: '',
      title: '',
      description: '',
      link: '',
      movieLink: '',
      isMainDisplay: false,
      hit: 0,
      rank: 0,
      activeCode: '',
      activeName: '',
      postDate: '',
      startDate: '',
      endDate: '',
      manager: '',
      managerEmail: '',
      managerName: '',
      images: {},
    });
  }

  const changeMobileAdInfo = (name, value) => {
    setAdInfo((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const changeArticleImages = (index, imageData) => {
    setAdInfo((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setAdInfo((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleNameOnChange = (value) => {
    changeMobileAdInfo?.('name', value);
  }
  const handleEmailOnChange = (value) => {
    changeMobileAdInfo?.('email', value);
  }
  const handlePhoneOnChange = (value) => {
    changeMobileAdInfo("phone", addHyphenForPhoneNumber(value));
  }
  const handleStartDateOnChange = (value) => {
    changeMobileAdInfo?.('startDate', value);
  }
  const handleEndDateOnChange = (value) => {
    changeMobileAdInfo?.('endDate', value);
  }
  const handleMovieLinkOnChange = (value) => {
    changeMobileAdInfo?.('movieLink', value);
  }
  const handlePasswordCountOnChange = (value) => {
    changeMobileAdInfo?.('password', value);
  }

  const handleUploadImage = (file, index) => {
    if(!adInfo?.id) {
      setDialog({isOpen: true, message: "게시물 저장후 업로드 해주세요.", type: "alert"});
      return;
    }

    if(adInfo?.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('adId', adInfo.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      adApi.createMarketAdImageFile(
        adInfo.id,
        formData,
        (data) => {
          setLoading(false);
          console.log(data);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      setDialog({isOpen: true, message: "파일을 선택해주세요.", type: "alert"});
    }
  }

  const handleDeleteImage = (index, fileId) => {
    if(adInfo.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': adInfo.id,
      });

      adApi.deleteMarketAdImageFile(
        adInfo.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteArticleImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleTypeOnChange = ({ target }) => {
    changeMobileAdInfo?.('type', target.value);
    if(target.value) {
      setAdPositionLv1ByType(target.value);
      setAdPositionLv2ByType(target.value, '');
    } else {
      setAdPositionLv1ByType(target.value);
      setAdPositionLv2ByType(target.value, '');
    }

  }
  const handlePositionLv1OnChange = ({ target }) => {
    changeMobileAdInfo?.('positionLv1', target.value);
    setAdPositionLv2ByType(adInfo.type, target.value);
  }
  const handlePositionsLv2OnChange = ({ target }) => {
    changeMobileAdInfo?.('positionLv2', target.value);
  }
  const handleActiveOnChange = (e) => {
    changeMobileAdInfo?.('activeCode', e.target.value);
  }
  const handleRankOnChange = (value) => {
    changeMobileAdInfo?.('rank', value);
  }
  const handleHitOnChange = (value) => {
    changeMobileAdInfo?.('hit', value);
  }
  const handleLinkOnChange = (value) => {
    changeMobileAdInfo?.('link', value);
  }
  const handleMainDisplayOnChange = (key, value) => {
    if(value)
      changeMobileAdInfo('isMainDisplay', true);
    else
      changeMobileAdInfo('isMainDisplay', false);
  }
  const handleManagerNumberChange = (value) => {
    changeMobileAdInfo('manager', value);
  }
  const handleManagerEmailOnChange = (value) => {
    changeMobileAdInfo('managerEmail', value);
  }
  const handleManagerNameOnChange = (value) => {
    changeMobileAdInfo('managerName', value);
  }
  const handleTitleOnChange = (value) => {
    changeMobileAdInfo('title', value);
  }

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    setLoading(true);
    adApi.fetchMarketAdId(
      id,
      (data) => {
        setLoading(false);
        setAdInfo(data?.result);
        try {
          setAdPositionLv1ByType(data?.result.type);
          setAdPositionLv2ByType(data?.result.type, data?.result.positionLv1);
        } catch(err) {
          console.log('err = ' + err);
        }
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    if(validateAdInfoData()) {
      setLoading(true);
      const id = adInfo.id;
      const updateData = JSON.stringify({
        'id': id,
        'type': adInfo.type,
        'positionLv1': adInfo.positionLv1,
        'positionLv2': adInfo.positionLv2,
        'activeCode': adInfo.activeCode,
        'password': adInfo.password,
        'name': adInfo.name,
        'email': adInfo.email,
        'phone': adInfo.phone,
        'title': adInfo.title,
        'description': editorRef.current.getContent(),
        'link': adInfo.link,
        'movieLink': adInfo.movieLink,
        'rank': adInfo.rank,
        'hit': adInfo.hit,
        'isMainDisplay': adInfo.isMainDisplay,
        'startDate': adInfo.startDate,
        'endDate': adInfo.endDate,
        'manager': adInfo.manager,
      });

      adApi.updateMarketAd(id, updateData,
        (data) => {
          setLoading(false);
          setAdInfo(data?.result);
          alert("저장 하였습니다.");
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmSave = useConfirm(
    "저장 하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const handleCreateOnClick = () => {
    if(validateAdInfoData()) {
      setLoading(true);
      const id = adInfo.id;
      const createData = JSON.stringify({
        'id': id,
        'type': adInfo.type,
        'positionLv1': adInfo.positionLv1,
        'positionLv2': adInfo.positionLv2,
        'activeCode': adInfo.activeCode,
        'password': adInfo.password,
        'name': adInfo.name,
        'email': adInfo.email,
        'phone': adInfo.phone,
        'title': adInfo.title,
        'description': editorRef.current.getContent(),
        'link': adInfo.link,
        'movieLink': adInfo.movieLink,
        'rank': adInfo.rank,
        'isMainDisplay': adInfo.isMainDisplay,
        'startDate': adInfo.startDate,
        'endDate': adInfo.endDate,
        'manager': adInfo.manager,
      });

      adApi.createMarketAd(createData,
        (data) => {
          setLoading(false);
          alert("신규 등록 하였습니다.");
          setAdInfo(data?.result);
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmCreate = useConfirm(
    "신규 등록 하시겠습니까?",
    handleCreateOnClick,
    ()=>{},
  );

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId, memberName) => {
    if(memberNumber) {
      handleManagerNumberChange(memberNumber);
      handleManagerEmailOnChange(memberId);
      handleManagerNameOnChange(memberName);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // function ---------------------------------------------
  const setAdPositionLv1ByType = (type) => {
    let adPositionLv1List = [];
    adPositionLv1List.push();
    switch(type) {
      case "banner":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdBannerPositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdBannerPositionData[key].value, label: MarketAdBannerPositionData[key].label});
        });
        break;
      case "line":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdLinePositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdLinePositionData[key].value, label: MarketAdLinePositionData[key].label});
        });
        break;
      case "mobile":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdMobilePositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdMobilePositionData[key].value, label: MarketAdMobilePositionData[key].label});
        });
        break;
      case "flash":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdFlashPositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdFlashPositionData[key].value, label: MarketAdFlashPositionData[key].label});
        });
        break;
      case "popup":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdPopupPositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdPopupPositionData[key].value, label: MarketAdPopupPositionData[key].label});
        });
        break;
      case "movie":
        adPositionLv1List.push({value: '', label: '선택'});
        Object.keys(MarketAdMoviePositionData).forEach((key)=> {
          adPositionLv1List.push({value: MarketAdMoviePositionData[key].value, label: MarketAdMoviePositionData[key].label});
        });
        break;
      default:
        adPositionLv1List.push({value: '', label: '선택'});
        break;
    }
    setAdPositionLv1(adPositionLv1List);
  }

  const setAdPositionLv2ByType = (type, positionLv1) => {
    if(!positionLv1) {
      setAdPositionLv2([{value: '', label: '선택'},]);
    } else {
      switch(type) {
        case "banner":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdBannerPositionData[positionLv1]?.subList
          ]);
          break;
        case "line":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdLinePositionData[positionLv1]?.subList
          ]);
          changeMobileAdInfo?.('positionLv2', MarketAdLinePositionData[positionLv1]?.subList[0]?.value);
          break;
        case "mobile":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdMobilePositionData[positionLv1]?.subList
          ]);
          break;
        case "flash":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdFlashPositionData[positionLv1]?.subList
          ]);
          break;
        case "popup":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdPopupPositionData[positionLv1]?.subList
          ]);
          break;
        case "movie":
          setAdPositionLv2([
            {value: '', label: '선택'},
            ...MarketAdMoviePositionData[positionLv1]?.subList
          ]);
          break;
        default:
          setAdPositionLv2({value: '', label: '선택'});
          break;
      }
    }
  }

  const validateAdInfoData = () => {
    if(isBlankValue(adInfo.type)) {
      // setDialog({isOpen: true, message: "Type은 필수입니다.", type: "alert"});
      alert('Type은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.positionLv1)) {
      // setDialog({isOpen: true, message: "Position Lv1 은 필수입니다.", type: "alert"});
      alert('Position Lv1 은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.positionLv2)) {
      // setDialog({isOpen: true, message: "Position Lv2 은 필수입니다.", type: "alert"});
      alert('Position Lv2 은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.name)) {
      // setDialog({isOpen: true, message: "작성자는 필수입니다.", type: "alert"});
      alert('작성자는 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.activeCode)) {
      // setDialog({isOpen: true, message: "상태는 필수입니다.", type: "alert"});
      alert('상태는 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.name)) {
      // setDialog({isOpen: true, message: "이름은 필수입니다.", type: "alert"});
      alert('이름은 필수입니다.');
      return false;
    }
    // if(isBlankValue(adInfo.email)) {
    //   setDialog({isOpen: true, message: "이메일은 필수입니다.", type: "alert"});
    //   return false;
    // }
    // if(isBlankValue(adInfo.phone)) {
    //   setDialog({isOpen: true, message: "연락처는 필수입니다.", type: "alert"});
    //   return false;
    // }
    // if(!isValidEmail(adInfo.email)) {
    //   setDialog({isOpen: true, message: "유효하지 않는 이메일입니다.", type: "alert"});
    //   return false;
    // }
    if(isBlankValue(adInfo.title)) {
      // setDialog({isOpen: true, message: "제목은 필수입니다.", type: "alert"});
      alert('제목은 필수입니다.');
      return false;
    }
    return true;
  }

  // render ---------------------------------------------

  useEffect(() => {
    init();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>Mobile Ad</h3>
        </Header>
        <GridGroup>
          <InputGroup>
            <InputComponent label={'ID'}
              id={'id'}
              type={'text'} checkType={''}
              value={adInfo.id} placeholder='입력' maxLength={255}
              style={{width: '8rem'}}
              disabled={true}
            />
            <SelectComponents label={'Type'}
              id={'type'}
              value={adInfo?.type}
              objectData={[{ value: '', label: '선택'}, ...MarketAdType]}
              onChange={e=>handleTypeOnChange(e)}
              style={{width: '13rem'}}
            />
            <SelectComponents label={'lv1'}
              id={'positionLv1'}
              value={adInfo?.positionLv1}
              objectData={adPositionLv1}
              onChange={handlePositionLv1OnChange}
              style={{width: '13rem'}}
            />
            <SelectComponents label={'lv2 (위치)'}
              id={'positionLv2'}
              value={adInfo?.positionLv2}
              objectData={adPositionLv2}
              onChange={handlePositionsLv2OnChange}
              style={{width: '16rem'}}
            />
            <SelectComponents label={'상태'}
              id={'activeCode'}
              value={adInfo?.activeCode}
              objectData={[{ value: null, label: '선택' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '5.5rem'}}
            />
            <InputComponent label={'순서'}
              id={'rank'}
              type={'text'} checkType={''}
              value={adInfo.rank} placeholder='' maxLength={255}
              onChange={handleRankOnChange}
              style={{width: '5.5rem'}}
            />
            <InputComponent label={'조회수'}
              id={'hit'}
              type={'number'} checkType={''}
              value={adInfo.hit} placeholder='' maxLength={255}
              onChange={handleHitOnChange}
              style={{width: '8.1rem'}}
            />
            <InputComponent label={'광고주 이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={adInfo.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
            <InputComponent label={'이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={adInfo.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '22.4rem'}}
              disabled={false}
            />
            <InputComponent label={'전화번호'}
              id={'phone'}
              type={'text'} checkType={'text'}
              value={adInfo.phone} placeholder={'00*-00*-0000'} maxLength={13}
              onChange={handlePhoneOnChange}
              style={{width: '12rem'}}
              disabled={false}
            />
            <div onClick={()=> handleUserSearchOnClick()}>
              <InputComponent label={'광고 담당자'}
                id={'managerName'}
                type={'text'} checkType={''}
                value={adInfo.managerName} placeholder='' maxLength={255}
                style={{width: '13rem'}}
                disabled={true}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <InputComponent label={'링크'}
              id={'companyName'}
              type={'text'} checkType={''}
              value={adInfo.link} placeholder='' maxLength={255}
              onChange={handleLinkOnChange}
              style={{width: '30rem'}}
            />
            <SwitchComponent
              label={'메인 프리미엄'}
              id={'isMainDisplay'}
              value={adInfo?.isMainDisplay}
              checkedValue={true}
              onChange={handleMainDisplayOnChange}
              style={{width: '9.4rem'}}
            />
            <CalendarComponent label={'시작일'}
              id={'startDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={adInfo.startDate}
              onChange={handleStartDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            <CalendarComponent label={'종료일'}
              id={'endDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={adInfo.endDate}
              onChange={handleEndDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            {adInfo.type === 'movie' && (
              <InputComponent label={'동영상 링크'}
                id={'movieLink'}
                type={'text'} checkType={''}
                value={adInfo.movieLink} placeholder='' maxLength={255}
                infoMessage={'e.g.) "https://www.youtube.com/watch?v=gCa2JaKBvjE" URL에서 "gCa2JaKBvjE"만 입력해주세요.'}
                onChange={handleMovieLinkOnChange}
                style={{width: '100%'}}
              />
            )}
          </InputGroup>
          <InputGroup>
            <div style={{
              'width': '66rem',
              'border': '1px solid rgb(221, 221, 221)',
              'border-radius': '0.4rem'
              }}>
              <ImageUploadComponent
                images={adInfo.images}
                uploadProcess={handleUploadImage}
                deleteProcess={handleDeleteImage}
                setDialog={setDialog}
                limit={2}
                maxSize={7}
              />
            </div>
            <InputComponent
              label={'비밀번호 변경'}
              id={'password'}
              type={'password'} checkType={''}
              value={adInfo.password} placeholder='' min={0} maxLength={255}
              infoMessage={'변경시 입력합니다.'}
              onChange={handlePasswordCountOnChange}
              style={{width: '16.6rem', height: '6.8rem'}}
            />
          </InputGroup>
          <InputGroup>
            <InputComponent label={'제목'}
              id={'title'}
              type={'text'} checkType={''}
              value={adInfo.title} placeholder='' maxLength={255}
              onChange={handleTitleOnChange}
              style={{width: '100%'}}
            />
            <EditorSection
              style={adInfo.type === 'movie' ? {height: '31rem'} : {}}>
              <EditorComponent
                editorRef={editorRef}
                description={adInfo?.description}
                width={'100%'}
                height={'100%'}
                disabled={false}
              />
            </EditorSection>
          </InputGroup>
          <ButtonGroup>
            {adInfo.id ? (
              <Button type='button' value={'저장'} onClick={confirmSave} />
            ) : (
              <Button type='button' value={'신규'} onClick={confirmCreate} />
            )}
            <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
          </ButtonGroup>
        </GridGroup>
      </ModalWindow>
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 83.6rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const GridGroup = styled.div`
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const EditorSection = styled.div`
  width: 100%;
  height: 39rem;
`;

const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
