import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { NewsApi } from '../../../api/NewsApi';
import { InputComponent } from '../../../component/InputComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { SelectComponentWithObject } from '../../../component/SelectComponentWithObject';
import { Button } from '../../../component/Button';
import { Loader } from '../../../component/Loader';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import { isBlankValue } from '../../../util/utils';
import {
  ActiveTypeList, YNCodes,
} from '../../../__mocks__/PostData';
import { SelectComponentsForCategory } from './SelectComponentsForCategory';

export const NewsSubCategoryModal = ({id, show, onChange, onClose}) => {
  const newsApi = new NewsApi();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const newsSubCategoryInfo = {
    id: 0,
    active: 0,
    rank: 0,
    header: '',
    name: '',
    writer: '',
    email: '',
    categoryId: 0,
    paperYn: '',
    writeYn: '',
  }
  const [categorySearchParam] = useState({
    id: '',
    activeCode: '1',
    categoryName: '',
    name: '',
    page: 1,
    listSize: 100,
    perPageSize: 15,
    totalCount: 0,
    totalPageCount: 0,
  });
  const [subCategoryInfo, setSubCategoryInfo] = useState({
    id: id,
    ...newsSubCategoryInfo
  });

  // state 변경 ------------------------------------------------
  const init = () => {
    setSubCategoryInfo({
      id: id,
      ...newsSubCategoryInfo
    });
  }

  const changeSubCategoryInfo = (name, value) => {
    setSubCategoryInfo((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  // handler ---------------------------------------------
  const handleActiveOnChange = ({target}) => {
    changeSubCategoryInfo?.('active',target.value);
  }
  const handleCategoryIdOnChange = ({target}) => {
    changeSubCategoryInfo?.('categoryId', target.value);
  }
  const handleRankOnChange = (value) => {
    changeSubCategoryInfo?.('rank', value);
  }
  const handleHeaderOnChange = (value) => {
    changeSubCategoryInfo?.('header', value);
  }
  const handleNameOnChange = (value) => {
    changeSubCategoryInfo?.('name', value);
  }
  const handleWriterOnChange = (value) => {
    changeSubCategoryInfo?.('writer', value);
  }
  const handleEmailOnChange = (value) => {
    changeSubCategoryInfo?.('email', value);
  }
  const handlePaperYnOnChange = ({target}) => {
    changeSubCategoryInfo?.('paperYn', target.value);
  }
  const handleWriteYnOnChange = ({target}) => {
    changeSubCategoryInfo?.('writeYn', target.value);
  }

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    setLoading(true);
    newsApi.fetchNewsSubCategoryById(
      id,
      (data) => {
        setLoading(false);
        setSubCategoryInfo(null);
        setSubCategoryInfo(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleCategorySearch = () => {
    setLoading(true);
    newsApi.fetchNewsCategoryList(
      categorySearchParam,
      (data) => {
        setLoading(false);
        setCategory(null);
        setCategory(data?.resultList);
      },
      (error) => {
        setLoading(false);
        setCategory(null);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    if(validateInfoData()) {
      setLoading(true);
      const id = subCategoryInfo.id;
      const updateData = JSON.stringify({
        'id': id,
        'active': subCategoryInfo.active,
        'rank': subCategoryInfo.rank,
        'header': subCategoryInfo.header,
        'name': subCategoryInfo.name,
        'writer': subCategoryInfo.writer,
        'email': subCategoryInfo.email,
        'categoryId': subCategoryInfo.categoryId,
        'paperYn': subCategoryInfo.paperYn,
        'writeYn': subCategoryInfo.writeYn,
      });

      newsApi.updateNewsSubCategory(id, updateData,
        (data) => {
          setLoading(false);
          setSubCategoryInfo(data?.result);
          alert("저장 하였습니다.");
          onChange();
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmSave = useConfirm(
    "저장 하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const handleCreateOnClick = () => {
    if(validateInfoData()) {
      setLoading(true);
      const createData = JSON.stringify({
        'active': subCategoryInfo.active,
        'rank': subCategoryInfo.rank,
        'header': subCategoryInfo.header,
        'name': subCategoryInfo.name,
        'writer': subCategoryInfo.writer,
        'email': subCategoryInfo.email,
        'categoryId': subCategoryInfo.categoryId,
        'paperYn': subCategoryInfo.paperYn,
        'writeYn': subCategoryInfo.writeYn,
      });

      newsApi.createNewsSubCategory(createData,
        (data) => {
          setLoading(false);
          alert("신규 등록 하였습니다.");
          setSubCategoryInfo(data?.result);
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmCreate = useConfirm(
    "신규 등록 하시겠습니까?",
    handleCreateOnClick,
    ()=>{},
  );

  // function ---------------------------------------------
  const validateInfoData = () => {
    // if(isBlankValue(categoryInfo?.writeCode)) {
    //   alert('쓰기 권한은 필수입니다.');
    //   return false;
    // }
    if(isBlankValue(subCategoryInfo?.name)) {
      alert('이름은 필수입니다.');
      return false;
    }
    return true;
  }

  // render ---------------------------------------------
  useEffect(() => {
    init();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  useEffect(() => {
    handleCategorySearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <Header>
          <h3>뉴스 Sub Category</h3>
        </Header>
        <GridGroup>
          <InputGroup>
            <InputComponent label={'ID'}
              id={'id'}
              type={'number'} checkType={''}
              value={subCategoryInfo?.id} placeholder='입력' maxLength={255}
              style={{width: '11.55rem'}}
              disabled={true}
            />
            <SelectComponents label={'상태'}
              id={'active'}
              value={subCategoryInfo?.active}
              objectData={[{ value: null, label: '선택' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '11.55rem'}}
            />
            <SelectComponentsForCategory label={'카테고리'}
              id={'categoryId'}
              value={subCategoryInfo?.categoryId}
              objectData={category}
              onChange={handleCategoryIdOnChange}
              style={{width: '25.5rem'}}
            />
            <InputComponent label={'순서'}
              id={'rank'}
              type={'text'} checkType={''}
              value={subCategoryInfo?.rank} min={0} placeholder='' maxLength={255}
              onChange={handleRankOnChange}
              style={{width: '11.55rem'}}
              inputStyle={{textAlign: 'right'}}
            />
            <InputComponent label={'머리말'}
              id={'header'}
              type={'text'} checkType={''}
              value={subCategoryInfo?.header}
              onChange={handleHeaderOnChange}
              style={{width: '11.55rem'}}
            />
            <InputComponent label={'하위 카테고리 명'}
              id={'name'}
              type={'text'} checkType={''}
              value={subCategoryInfo?.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
            <InputComponent label={'작가 이름'}
              id={'writer'}
              type={'text'} checkType={''}
              value={subCategoryInfo?.writer} placeholder='입력' maxLength={255}
              onChange={handleWriterOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
            <InputComponent label={'작가 이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={subCategoryInfo?.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
            <SelectComponentWithObject label={'신문 YN'}
              id={'paperYn'}
              value={subCategoryInfo?.paperYn}
              objectData={YNCodes}
              onChange={handlePaperYnOnChange}
              style={{width: '11.55rem', textAlign: 'center'}}
            />
            <SelectComponentWithObject label={'쓰기 YN'}
              id={'writeYn'}
              value={subCategoryInfo?.writeYn}
              objectData={YNCodes}
              onChange={handleWriteYnOnChange}
              style={{width: '11.55rem', textAlign: 'center'}}
            />
          </InputGroup>
          <ButtonGroup>
            {subCategoryInfo?.id ? (
              <Button type='button' value={'저장'} onClick={confirmSave} />
            ) : (
              <Button type='button' value={'신규'} onClick={confirmCreate} />
            )}
            <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
          </ButtonGroup>
        </GridGroup>
      </ModalWindow>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 32rem;
  height: 49.5rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const GridGroup = styled.div`
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
