import { jsonApi } from "../util/AxiosApi";

export class AdApi {

  fetchNewsAdList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/news`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchNewsAdId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/news/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createNewsAd(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/news`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  updateNewsAd(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/ad/news/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  createNewsAdImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/news/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteNewsAdImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/ad/news/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchEduAdList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/edu`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchEduAdId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/edu/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createEduAd(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/edu`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  updateEduAd(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/ad/edu/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  createEduAdImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/edu/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteEduAdImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/ad/edu/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchCommunityAdList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/community`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchCommunityAdId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/community/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createCommunityAd(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/community`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  updateCommunityAd(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/ad/community/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  createCommunityAdImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/community/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteCommunityAdImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/ad/community/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  fetchYellowpageAdList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/yellowpage`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchYellowpageAdId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/yellowpage/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createYellowpageAd(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/yellowpage`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  updateYellowpageAd(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/ad/yellowpage/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  createYellowpageAdImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/yellowpage/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteYellowpageAdImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/ad/yellowpage/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }


  fetchMarketAdList(searchData, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/market`,
      params: searchData,
      paramsSerializer: function (params) {
        const qs = require('qs');
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  fetchMarketAdId(id, callback, errorCallback) {
    jsonApi({
      method: 'get',
      url: `/api/adm/ad/market/${id}`,
    }).then((response) => {
      callback?.(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    });
  }

  createMarketAd(formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/market`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  updateMarketAd(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'patch',
      url: `/api/adm/ad/market/${id}`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  }

  createMarketAdImageFile(id, formData, callback, errorCallback) {
    jsonApi({
      method: 'post',
      url: `/api/adm/ad/market/${id}/imageFile`,
      data: formData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

  deleteMarketAdImageFile(id, jsonData, callback, errorCallback) {
    jsonApi({
      method: 'delete',
      url: `/api/adm/ad/market/${id}/imageFile`,
      data: jsonData,
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      errorCallback?.(error);
    })
  }

}