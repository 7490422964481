import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { AdApi } from '../../../api/AdApi';
import { InputComponent } from '../../../component/InputComponent';
import { EditorComponent } from '../../../component/EditorComponent';
import { SelectComponents } from '../../../component/SelectComponents';
import { CalendarComponent } from '../../../component/CalendarComponent';
import { ImageUploadComponent } from '../../../common/ImageUploadComponent';
import { UserSearchModal } from '../../user/component/UserSearchModal';
import { Button } from '../../../component/Button';
import { Loader } from '../../../component/Loader';
import { useConfirm } from '../../../hooks/userConfirm';
import { errorAlert } from '../../../util/ErrorHandler';
import { addHyphenForPhoneNumber, isBlankValue } from '../../../util/utils';
import {
  ActiveTypeList,
  YellowpageAdTypeList,
  YellowpageAdPositionLV1List,
  YellowpageAdPositionLV2List,
} from '../../../__mocks__/PostData';

export const YellowpageAdModal = ({id, show, onClose, setDialog}) => {
  const adApi = new AdApi();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const yellowpageAdInfo = {
    id: 0,
    active: 0,
    hit: 0,
    rank: 0,
    type: '',
    positionLv1: '',
    positionLv2: '',
    positionLv3: '',
    link: '',
    title: '',
    description: '',
    description2: '',
    name: '',
    email: '',
    phone: '',
    startDate: '',
    endDate: '',
    regUser: '',
    modUser: '',
    manager: '',
    images: {},
  }
  const [userSearchModal, setUserSearchModal] = useState({
    show: false,
  });
  const [adInfo, setAdInfo] = useState({
    id: id,
    ...yellowpageAdInfo
  });

  // state 변경 ------------------------------------------------
  const init = () => {
    setAdInfo({
      id: id,
      ...yellowpageAdInfo
    });
  }

  const changeMobileAdInfo = (name, value) => {
    setAdInfo((param) => {
      const updated = {...param}
      updated[name] = value
      return updated;
    });
  }

  const changeArticleImages = (index, imageData) => {
    setAdInfo((values) => {
      const updated = {...values};
      updated['images'][index] = imageData;
      return updated;
    });
  };

  const deleteArticleImages = (index) => {
    setAdInfo((values) => {
      const updated = {...values};
      delete updated['images'][index];
      return updated;
    });
  };

  // handler ---------------------------------------------
  const handleActiveOnChange = (e) => {
    changeMobileAdInfo?.('active', e.target.value);
  }
  const handleHitOnChange = (value) => {
    changeMobileAdInfo?.('hit', value);
  }
  const handleRankOnChange = (value) => {
    changeMobileAdInfo?.('rank', value);
  }
  const handleTypeOnChange = ({ target }) => {
    changeMobileAdInfo?.('type', target.value);
  }
  const handlePositionLv1OnChange = ({ target }) => {
    changeMobileAdInfo?.('positionLv1', target.value);
  }
  const handlePositionsLv2OnChange = ({ target }) => {
    changeMobileAdInfo?.('positionLv2', target.value);
  }
  const handlePositionsLv3OnChange = (value) => {
    changeMobileAdInfo?.('positionLv3', value);
  }
  const handleLinkOnChange = (value) => {
    changeMobileAdInfo?.('link', value);
  }
  const handleTitleOnChange = (value) => {
    changeMobileAdInfo('title', value);
  }
  const handleNameOnChange = (value) => {
    changeMobileAdInfo?.('name', value);
  }
  const handleEmailOnChange = (value) => {
    changeMobileAdInfo?.('email', value);
  }
  const handlePhoneOnChange = (value) => {
    changeMobileAdInfo("phone", addHyphenForPhoneNumber(value));
  }
  const handleStartDateOnChange = (value) => {
    changeMobileAdInfo?.('startDate', value);
  }
  const handleEndDateOnChange = (value) => {
    changeMobileAdInfo?.('endDate', value);
  }
  const handleManagerNumberChange = (value) => {
    changeMobileAdInfo('manager', value);
  }
  const handleManagerEmailOnChange = (value) => {
    changeMobileAdInfo('managerEmail', value);
  }
  const handleManagerNameOnChange = (value) => {
    changeMobileAdInfo('managerName', value);
  }

  const handleUploadImage = (file, index) => {
    if(!adInfo?.id) {
      alert("게시물 저장후 업로드 해주세요.");
      return;
    }

    if(adInfo?.id && file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('postId', adInfo.id);
      formData.append('seq', index);
      formData.append('imageFile', file);

      adApi.createYellowpageAdImageFile(
        adInfo.id,
        formData,
        (data) => {
          setLoading(false);
          const { result } = data;
          changeArticleImages(result.seq, result);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    } else {
      alert("파일을 선택해주세요.");
    }
  }

  const handleDeleteImage = (index, fileId) => {
    if(adInfo.id && fileId && index) {
      setLoading(true);
      const deleteImageFileData = JSON.stringify({
        'fileId': fileId,
        'seq': index,
        'postId': adInfo.id,
      });

      adApi.deleteYellowpageAdImageFile(
        adInfo.id,
        deleteImageFileData,
        () => {
          setLoading(false);
          deleteArticleImages(index);
        },
        (error) => {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    setLoading(true);
    adApi.fetchYellowpageAdId(
      id,
      (data) => {
        setLoading(false);
        setAdInfo(null);
        setAdInfo(data?.result);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  const handleSaveOnClick = () => {
    if(validateAdInfoData()) {
      setLoading(true);
      const id = adInfo.id;
      const updateData = JSON.stringify({
        'id': id,
        'active': adInfo.active,
        'hit': adInfo.hit,
        'rank': adInfo.rank,
        'type': adInfo.type,
        'positionLv1': adInfo.positionLv1,
        'positionLv2': adInfo.positionLv2,
        'positionLv3': adInfo.positionLv3,
        'link': adInfo.link,
        'title': adInfo.title,
        'description': editorRef.current.getContent(),
        'name': adInfo.name,
        'email': adInfo.email,
        'phone': adInfo.phone,
        'startDate': adInfo.startDate,
        'endDate': adInfo.endDate,
        'manager': adInfo.manager,
      });

      adApi.updateYellowpageAd(id, updateData,
        (data) => {
          setLoading(false);
          setAdInfo(data?.result);
          alert("저장 하였습니다.");
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmSave = useConfirm(
    "저장 하시겠습니까?",
    handleSaveOnClick,
    ()=>{},
  );

  const handleCreateOnClick = () => {
    if(validateAdInfoData()) {
      setLoading(true);
      const id = adInfo.id;
      const createData = JSON.stringify({
        'id': id,
        'active': adInfo.active,
        'hit': adInfo.hit,
        'rank': adInfo.rank,
        'type': adInfo.type,
        'positionLv1': adInfo.positionLv1,
        'positionLv2': adInfo.positionLv2,
        'positionLv3': adInfo.positionLv3,
        'link': adInfo.link,
        'title': adInfo.title,
        'description': editorRef.current.getContent(),
        'name': adInfo.name,
        'email': adInfo.email,
        'phone': adInfo.phone,
        'startDate': adInfo.startDate,
        'endDate': adInfo.endDate,
        'manager': adInfo.manager,
      });

      adApi.createYellowpageAd(createData,
        (data) => {
          setLoading(false);
          alert("신규 등록 하였습니다.");
          setAdInfo(data?.result);
        },
        (error)=> {
          setLoading(false);
          errorAlert(error);
        }
      );
    }
  }

  const confirmCreate = useConfirm(
    "신규 등록 하시겠습니까?",
    handleCreateOnClick,
    ()=>{},
  );

  const handleUserSearchOnClick = () => {
    handleShowUserSearchModal();
  }

  const handleShowUserSearchModal = () => {
    setUserSearchModal({
      id: id,
      show: true
    });
  }

  const handleClickUserSearchModal = (memberNumber, memberId, memberName) => {
    if(memberNumber) {
      handleManagerNumberChange(memberNumber);
      handleManagerEmailOnChange(memberId);
      handleManagerNameOnChange(memberName);
    }
  }

  const handleCloseUserSearchModal = () => {
    setUserSearchModal({
      show: false
    });
  }

  // function ---------------------------------------------
  const validateAdInfoData = () => {
    if(isBlankValue(adInfo.type)) {
      alert('Type은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.positionLv1)) {
      alert('Position Lv1 은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.positionLv2)) {
      alert('Position Lv2 은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.name)) {
      alert('이름은 필수입니다.');
      return false;
    }
    if(isBlankValue(adInfo.title)) {
      alert('제목은 필수입니다.');
      return false;
    }
    return true;
  }

  // render ---------------------------------------------
  useEffect(() => {
    init();
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <Header>
          <h3>업소록 Ad</h3>
        </Header>
        <GridGroup>
          <InputGroup>
            <InputComponent label={'ID'}
              id={'id'}
              type={'number'} checkType={''}
              value={adInfo?.id} placeholder='입력' maxLength={255}
              style={{width: '8rem'}}
              disabled={true}
            />
            <SelectComponents label={'Type'}
              id={'type'}
              value={adInfo?.type}
              objectData={[{ value: null, label: '선택' }, ...YellowpageAdTypeList]}
              onChange={e=>handleTypeOnChange(e)}
              style={{width: '8.5rem'}}
            />
            <SelectComponents label={'lv1'}
              id={'positionLv1'}
              value={adInfo?.positionLv1}
              objectData={[{ value: null, label: '선택' }, ...YellowpageAdPositionLV1List]}
              onChange={handlePositionLv1OnChange}
              style={{width: '11.5rem'}}
            />
            <SelectComponents label={'lv2'}
              id={'positionLv2'}
              value={adInfo?.positionLv2}
              objectData={[{ value: null, label: '선택' }, ...YellowpageAdPositionLV2List]}
              onChange={handlePositionsLv2OnChange}
              style={{width: '11.5rem'}}
            />
            <InputComponent label={'lv3'}
              id={'positionLv3'}
              type={'number'} checkType={''}
              value={adInfo?.positionLv3} min={0} placeholder='입력' maxLength={255}
              onChange={handlePositionsLv3OnChange}
              style={{width: '8rem'}}
            />
            <SelectComponents label={'상태'}
              id={'active'}
              value={adInfo?.active}
              objectData={[{ value: null, label: '선택' }, ...ActiveTypeList]}
              onChange={handleActiveOnChange}
              style={{width: '5.5rem'}}
            />
            <InputComponent label={'Rank'}
              id={'rank'}
              type={'number'} checkType={''}
              value={adInfo?.rank} min={0} placeholder='' maxLength={255}
              onChange={handleRankOnChange}
              style={{width: '5.5rem'}}
            />
            <InputComponent label={'조회수'}
              id={'hit'}
              type={'number'} checkType={''}
              value={adInfo?.hit} min={0} placeholder='' maxLength={255}
              onChange={handleHitOnChange}
              style={{width: '8.1rem'}}
            />
            <InputComponent label={'광고주 이름'}
              id={'name'}
              type={'text'} checkType={''}
              value={adInfo?.name} placeholder='입력' maxLength={255}
              onChange={handleNameOnChange}
              style={{width: '29rem'}}
              disabled={false}
            />
            <InputComponent label={'이메일'}
              id={'email'}
              type={'text'} checkType={''}
              value={adInfo?.email} placeholder='입력' maxLength={255}
              onChange={handleEmailOnChange}
              style={{width: '22.4rem'}}
              disabled={false}
            />
            <InputComponent label={'전화번호'}
              id={'phone'}
              type={'text'} checkType={'text'}
              value={adInfo?.phone} placeholder={'00*-00*-0000'} maxLength={13}
              onChange={handlePhoneOnChange}
              style={{width: '12rem'}}
              disabled={false}
            />
            <div onClick={()=> handleUserSearchOnClick()}>
              <InputComponent label={'광고 담당자'}
                id={'managerName'}
                type={'text'} checkType={''}
                value={adInfo?.managerName} placeholder='' maxLength={255}
                style={{width: '13rem'}}
                disabled={true}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <InputComponent label={'링크'}
              id={'companyName'}
              type={'text'} checkType={''}
              value={adInfo?.link} placeholder='' maxLength={255}
              onChange={handleLinkOnChange}
              style={{width: '30rem'}}
            />
            <CalendarComponent label={'시작일'}
              id={'startDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={adInfo?.startDate}
              onChange={handleStartDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
            <CalendarComponent label={'종료일'}
              id={'endDate'}
              dateFormat={'yyyy-MM-dd HH:mm:ss'}
              placeholderText={'yyyy-MM-dd HH:mm:ss'}
              value={adInfo?.endDate}
              onChange={handleEndDateOnChange}
              isClearable={true}
              isShowTimeSelect={true}
              width={'18.5rem'}
            />
          </InputGroup>
          <InputGroup>
            <div style={{
              'width': '66rem',
              'border': '1px solid rgb(221, 221, 221)',
              'border-radius': '0.4rem'
              }}>
              <ImageUploadComponent
                images={adInfo?.images}
                uploadProcess={handleUploadImage}
                deleteProcess={handleDeleteImage}
                setDialog={setDialog}
                limit={2}
                maxSize={7}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <InputComponent label={'제목'}
              id={'title'}
              type={'text'} checkType={''}
              value={adInfo?.title} placeholder='' maxLength={255}
              onChange={handleTitleOnChange}
              style={{width: '100%'}}
            />
            <EditorSection>
              <EditorComponent
                editorRef={editorRef}
                description={adInfo?.description}
                width={'100%'}
                height={'100%'}
                disabled={false}
              />
            </EditorSection>
          </InputGroup>
          <ButtonGroup>
            {adInfo?.id ? (
              <Button type='button' value={'저장'} onClick={confirmSave} />
            ) : (
              <Button type='button' value={'신규'} onClick={confirmCreate} />
            )}
            <Button type='button' value={'닫기'} onClick={handleCloseOnClick} />
          </ButtonGroup>
        </GridGroup>
      </ModalWindow>
      <UserSearchModal
        show={userSearchModal.show}
        onClick={handleClickUserSearchModal}
        onClose={handleCloseUserSearchModal} />
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 83.6rem;
  & hr {
    border: 0;
    height: 0.1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
  & h4 {
    margin: 1rem 2.3rem;
    font-size: 0.8em;
  }
`;

const GridGroup = styled.div`
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const Header = styled.div`
  padding-left: 2.3rem;
`;

const InputGroup = styled.div`
  margin: 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  &>div {
    margin: 0.4rem;
  }
`;

const EditorSection = styled.div`
  width: 100%;
  height: 39rem;
`;

const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
