import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Loader } from '../../../component/Loader';
import { VkpApi } from '../../../api/VkpApi';
import { priceToStringWithDecimal } from '../../../util/utils';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { errorAlert } from '../../../util/ErrorHandler';

export const InvoicePrintModal = ({id, show, onClose, setDialog}) => {
  const vkpApi = new VkpApi();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();

  const [invoiceDetail, setInvoiceDetail] = useState({
    invoiceNumber: '',
    customerNumber: '',
    invoiceDate: '',
    amountSub: 0,
    amountGst: 0,
    amountInvoice: 0,
    amountBalance: 0,
    amountBased: 0,
    amountInterest: 0,
    dsMemo: '',
    dueDate: '',
    salesNumber: '',
    salesPersonKrName: '',
    isWaive: '',
    appliedYn: '',
    customerDto: {
      customerNumber: '',
      bizCode: '',
      customerBizEnName: '',
      customerBizKrName: '',

      customerKrName: '',
      customerLastName: '',
      customerFirstName: '',
      country: '',
      province: '',
      city: '',
      address: '',
      postalCode: '',
      phoneNumber: '',
      phoneExtNumber: '',
      cellPhoneNumber: '',
      faxNumber: '',
      email: '',
      sinNumber: '',
      isCredit: false,

      customerKrName2: '',
      customerLastName2: '',
      customerFirstName2: '',
      country2: '',
      province2: '',
      city2: '',
      address2: '',
      postalCode2: '',
      phoneNumber2: '',
      phoneExtNumber2: '',
      cellPhoneNumber2: '',
      faxNumber2: '',
      email2: '',
      sinNumber2: '',
      isCredit2: false,

      website: '',
      paymentType: '',
      noCredit: '',
      expireDate: '',
      cardOwnerName: '',
      printType: '',
      isEnd: false,
      isInvoice: false,
    },
    contractList: [{
      contractNumber: '',
      issuedDay: '',
      methodType: '',
      freqType: '',
      startDate: '',
      endDate: '',
      itemId: '',
      itemDisplayName: '',
      times: '',
      salesNumber: '',
      salesPersonKrName: '',
      rateAmount: 0,
      discount: 0,
      totalAmount: 0,
    }],
    statementList: [{
      rowNum: 1,
      dseq: 0,
      seq: 0,
      contractNumber: '',
      customerNumber: '',
      issuedDay: '',
      issuedDate: '',
      invoiceNumber: '',
      itemId: '',
      itemDisplayName: '',
      content: '',
      salesNumber: '',
      forwardYn: '',
      interestType: '',
      itemRateAmount: 0,
      itemDiscount: 0,
      itemSubAmount: 0,
      itemGstAmount: 0,
      itemTotalAmount: 0,
    }],
    paymentList: [{
      seq: 0,
      customerNumber: '',
      invoiceNumber: '',
      paidDate: '',
      paymentType: '',
      memo: '',
      paidAmount: 0,
    }],
    interestList: [{
      invoiceNumber: '',
      invoiceDate: '',
      dueDate: '',
      interestType: '',
      isApplied: '',
      isWaive: '',
      invoiceAmount: 0,
      basedAmount: 0,
      interestAmount: 0,
    }],
  });

  // state 변경 ------------------------------------------------

  // handler ---------------------------------------------
  const handleCloseOnClick = () => {
    onClose?.();
  }

  const handleSearchOnClick = () => {
    setLoading(true);
    vkpApi.fetchInvoiceId(
      id,
      (data) => {
        setInvoiceDetail(data?.result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        errorAlert(error);
      }
    );
  }

  // function ---------------------------------------------

  // render ---------------------------------------------
  useEffect(() => {
    if(id) {
      handleSearchOnClick();
    }

    if(show) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: none;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    show && (
    <Wrapper>
      <ModalWindow>
        <CloseButton onClick={handleCloseOnClick} />
        <ContentWrap ref={componentRef}>
          <HeaderGroup>
            <div>
              <img src='/img/vkp-invoice-logo.png' alt=''/>
              <div>GST Registration No: 135358760</div>
            </div>
            <CustomerInfo>
              <div className='title'>
                ◑ CUSTOMER INFORMATION
              </div>
              <CustomerInfoGrid>
                <div>Customer</div>
                <div className='gridCell'>{invoiceDetail?.customerDto?.customerBizEnName}</div>
                <div>Customer No.</div>
                <div className='gridCell'>{invoiceDetail?.customerDto?.customerNumber}</div>
                <div></div>
                <div className='gridCell'>{invoiceDetail?.customerDto?.customerBizKrName}</div>
                <div>Invoice No.</div>
                <div className='gridCell'>{invoiceDetail?.invoiceNumber}</div>
              </CustomerInfoGrid>
              <div className='title'>
                ◑ CONTACT US
              </div>
              <ContactInfoGrid>
                <div>Telephone</div>
                <div>
                  {`604-877-1178 / 1158`}
                </div>
                <div>e-mail</div>
                <div>{`info@vanchosun.com`}</div>
                <div>Fax</div>
                <div>{`604-877-1128`}</div>
                <div>website</div>
                <div>{`www.vanchosun.com`}</div>
              </ContactInfoGrid>
            </CustomerInfo>
          </HeaderGroup>
          <BodyGroup>
            <InvoiceListGroup>
              <div className='title'>INVOICE</div>
              <span className='listTitle'>New Charges</span>
              <InvoiceList>
                <div className='invoiceListHead'></div>
                <div className='invoiceListHead'>DATE</div>
                <div className='invoiceListHead'>DAY</div>
                <div className='invoiceListHead' style={{textAlign: 'left'}}>ACTIVITY DESCRIPTION</div>
                <div className='invoiceListHead' style={{textAlign: 'right'}}>RATE</div>
                <div className='invoiceListHead' style={{textAlign: 'right'}}>Disc(%)</div>
                <div className='invoiceListHead' style={{textAlign: 'right'}}>SUB</div>
                <div className='invoiceListHead' style={{textAlign: 'right'}}>GST</div>
                <div className='invoiceListHead' style={{textAlign: 'right'}}>AMOUNT</div>
                {invoiceDetail?.statementList?.map((row) => (
                  <>
                    <div className='invoiceListCell'>{row.rowNum}</div>
                    <div className='invoiceListCell'>{row.issuedDate}</div>
                    <div className='invoiceListCell'>{row.issuedDay}</div>
                    <div className='limitStr'>
                      {row.content
                        ? `${row.itemDisplayName}, ${row.content}`
                        : `${row.itemDisplayName}`
                      }
                    </div>
                    <div className='invoiceListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemRateAmount)}</div>
                    <div className='invoiceListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemDiscount)}</div>
                    <div className='invoiceListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemSubAmount)}</div>
                    <div className='invoiceListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemGstAmount)}</div>
                    <div className='invoiceListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.itemTotalAmount)}</div>
                  </>
                ))}
              </InvoiceList>
              <InvoiceListSummery>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>{priceToStringWithDecimal(invoiceDetail?.amountSub)}</div>
                <div>{priceToStringWithDecimal(invoiceDetail?.amountGst)}</div>
                <div>{priceToStringWithDecimal(invoiceDetail?.amountInvoice)}</div>
              </InvoiceListSummery>
              <InvoiceSummery>
                <div className='summeryHead'>
                  Total Current Charges
                </div>
                <div className='summeryCell'>
                  {priceToStringWithDecimal(invoiceDetail?.amountInvoice)}
                </div>
              </InvoiceSummery>
            </InvoiceListGroup>
            <PaymentListGroup>
              <span className='listTitle'>Payment Received</span>
              <PaymentList>
                <div className='paymentListHead'></div>
                <div className='paymentListHead'>DATE</div>
                <div className='paymentListHead' style={{textAlign: 'left'}}>ACTIVITY DESCRIPTION</div>
                <div className='paymentListHead' style={{textAlign: 'right'}}>AMOUNT</div>
                {0 < invoiceDetail?.paymentList?.length
                  ? (
                    <>
                      {invoiceDetail?.paymentList?.map((row) => (
                        <>
                          <div className='paymentListCell'>{row.seq}</div>
                          <div className='paymentListCell'>{row.paidDate}</div>
                          <div className='limitStr'>
                            {`${row.paymentType} ${row.memo}`}
                          </div>
                          <div className='paymentListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(row.paidAmount*-1)}</div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <>
                        <div className='paymentListCell'></div>
                        <div className='paymentListCell'></div>
                        <div className='limitStr'>None</div>
                        <div className='paymentListCell' style={{textAlign: 'right'}}>{priceToStringWithDecimal(0)}</div>
                      </>
                    </>
                  )
                }
              </PaymentList>
            </PaymentListGroup>
            <AmountDue>
              <div className='summeryHead'>
                Amount Due
              </div>
              <div className='summeryCell'>
                {priceToStringWithDecimal(invoiceDetail?.amountBalance)}
              </div>
            </AmountDue>
          </BodyGroup>
          <BottomGroup className={15<invoiceDetail?.statementList?.length ? 'pageBreak' : ''}>
            <div className='message'>Return sub below with cheque payable to: The Vancouver Korean Press Ltd.</div>
            <DueInfo>
              <div className='bottomLogo'>
                <img src='/img/vkp-invoice-bottom-logo.png' alt=''/>
              </div>
              <div>
                <BottomBoxGrid>
                  <BottomBox>
                    <div className='btTitle'>Invoice Date</div>
                    <div className='btData'>{invoiceDetail?.invoiceDate}</div>
                  </BottomBox>
                  <BottomBox>
                    <div className='btTitle'>Payment Due Date</div>
                    <div className='btData'>
                      {invoiceDetail?.amountBalance
                        ? invoiceDetail?.dueDate
                        : 'PAID'
                      }
                    </div>
                  </BottomBox>
                  <BottomBox>
                    <div className='btTitle'>ToTal Amount Due</div>
                    <div className='btData'>{priceToStringWithDecimal(invoiceDetail?.amountBalance)}</div>
                  </BottomBox>
                </BottomBoxGrid>
                <BottomBoxGrid style={{marginTop: '1rem'}}>
                  <BottomBox className='btRemoveLine'>
                    <div className='btTitle'></div>
                    <div className='btData'></div>
                  </BottomBox>
                  <BottomBox className='btRemoveLine'>
                    <div className='btTitle'></div>
                    <div className='btData'></div>
                  </BottomBox>
                  <BottomBox>
                    <div className='btTitle'>Amount Paid</div>
                    <div className='btData'></div>
                  </BottomBox>
                </BottomBoxGrid>
              </div>
            </DueInfo>
            <ClientInfo>
              <div className='clientAddress'>
                <div>Client Number : {invoiceDetail?.customerNumber}</div>
                <div className='clientAttn'>
                  Attn : {`${invoiceDetail?.customerDto?.customerKrName} ${invoiceDetail?.customerDto?.customerLastName} ${invoiceDetail?.customerDto?.customerFirstName}`}
                </div>
                <div className='clientName'>
                  {invoiceDetail?.customerDto?.customerBizEnName}<br/>
                  {invoiceDetail?.customerDto?.customerBizKrName}
                </div>
                <div>{invoiceDetail?.customerDto?.address}</div>
                <div>{`${invoiceDetail?.customerDto?.city} ${invoiceDetail?.customerDto?.province} ${invoiceDetail?.customerDto?.country} ${invoiceDetail?.customerDto?.postalCode}`}</div>
              </div>
              <div className='paymentOption'>
                <div>Payment Options</div>
                <ul>
                  <li>By cheque (Pay to: The Vancouver Korean Press Ltd.)</li>
                  <li>By credit card (Call to 604-877-1178)</li>
                  <li>By bank transfer (Sharons Credit Union, 01310-809-10001380500)</li>
                </ul>
              </div>
            </ClientInfo>
          </BottomGroup>
        </ContentWrap>
      </ModalWindow>
      <PrintButton className='sticky'>
        <ReactToPrint
          trigger={() => <img src='/icon/printer-light.png' alt='Write button'/>}
          content={() => componentRef.current}
        />
      </PrintButton>
      <Loader loading={loading} />
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalWindow = styled.div`
  padding: 0;
  position: relative;
  background: rgba(255, 255, 255);
  border-radius: 1rem;
  width: 90rem;
  height: 100%;
  overflow: auto;
  font-size: 11px;
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.4rem;
  right: 2.3rem;
  width: 1.6rem;
  height: 1.6rem;
  text-shadow: 0.1rem 0.1rem 0.2rem gray;
  color: rgb(0, 0, 0);
  background-image: url('/icon/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const ContentWrap = styled.div`
  padding: 4rem 2rem;
`;

const HeaderGroup = styled.div`
  width: 100%;
  height: 14rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CustomerInfo = styled.div`
  width: 50rem;
  height: 10rem;
  .title {
    font-weight: 600;
    border-bottom: 0.1rem solid #000;
  }
`;

const CustomerInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  .gridCell {
    font-weight: 500;
  }
`;

const ContactInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
`;

const BodyGroup = styled.div`
  margin-top: 1rem;
  min-height: 52rem;
`;

const InvoiceListGroup = styled.section`
  .title {
    margin-bottom: 0.3rem;
    padding: 0 1rem;
    font-size: 21px;
    font-weight: 700;
    background-color: #ddd;
  }
  .listTitle {
    margin-top: 0.2rem;
    padding: 0.1rem 1rem;
    font-weight: 500;
    background-color: #ddd;
  }
`;

const InvoiceList = styled.div`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 3fr 1fr 1fr 1fr 1fr 1fr;
  .invoiceListHead {
    text-align: center;
    border-bottom: 0.1rem solid #ddd;
  }
  .invoiceListCell {
    text-align: center;
  }
  .limitStr {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const InvoiceListSummery = styled.div`
  padding: 0 1rem;
  height: 2.4rem;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 3fr 1fr 1fr 1fr 1fr 1fr;
  text-align: right;
  border-top: 0.1rem solid #000;
  border-bottom: 0.1rem solid #000;
`;

const InvoiceSummery = styled.div`
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  .summeryHead {
    font-weight: 500;
  }
  .summeryCell {
    text-align: right;
  }
`;

const PaymentListGroup = styled.section`
  padding: 0 1rem;
  .title {
    margin-bottom: 0.1rem;
    font-size: 21px;
    font-weight: 700;
    background-color: #ddd;
  }
  .listTitle {
    margin-top: 0.2rem;
    font-weight: 500;
  }
`;

const PaymentList = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 3fr 1fr;
  .paymentListHead {
    text-align: center;
    border-bottom: 0.1rem solid #ddd;
  }
  .paymentListCell {
    text-align: center;
  }
  .limitStr {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AmountDue = styled.div`
  margin-top: 1rem;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  font-size: 21px;
  font-weight: 700;
  background-color: #ddd;
  .summeryHead {
    font-weight: 600;
  }
  .summeryCell {
    text-align: right;
  }
`;

const BottomGroup = styled.div`
  margin-top: 2rem;
  .message {
    height: 2.2rem;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border-bottom: 0.1rem dashed #ddd;
  }
  &.pageBreak {
    break-before: page;
  }
`;

const DueInfo = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .bottomLogo {
  }
`;

const BottomBoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.1rem;
  box-sizing: border-box;
`;

const BottomBox = styled.div`
  width: 15rem;
  font-weight: 500;
  outline: 0.1rem solid #000;
  .btTitle {
    height: 2rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .btData {
    height: 2.5rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  &.btRemoveLine {
    outline: 0rem solid #000;
  }
`;

const ClientInfo = styled.div`
  padding: 0 0 0 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .clientAddress {
    .clientAttn {
      font-weight: 600;
    }
    .clientName {
      font-weight: 600;
    }
  }
  .paymentOption {
    div{
      font-weight: 600;
    }
    ul {
      margin: 0;
      padding: 0 2rem;
    }
  }
`;

const PrintButton = styled.div`
  position: fixed;
  bottom: 2.7rem;
  right: 1.6rem;
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fd6258;
  border-radius: 50%;
  z-index: 10;
  & img {
    position: absolute;
    top: 24%;
    left: 24%;
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
  }
`;