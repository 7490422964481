export const menuData = {
  // 'menu': {
  //   'id': 'menu',
  //   'name': '메뉴관리',
  //   'subMenu': [
  //     {
  //       "id": "메뉴목록관리",
  //       "name": "메뉴목록관리",
  //       "link": "/menu",
  //     },
  //     {
  //       "id": "메뉴권한관리",
  //       "name": "메뉴권한관리",
  //       "link": "/menu/auth",
  //     },
  //   ]
  // },
  'member': {
    'id': 'member',
    'name': '회원 관리',
    'subMenu': [
      {
        "id": "일반",
        "name": "일반",
        "link": "/user",
      },
      {
        "id": "부동산리얼터",
        "name": "부동산리얼터",
        "link": "/realtor",
      },
      {
        "id": "자동차딜러",
        "name": "자동차딜러",
        "link": "/cardealer",
      },
      {
        "id": "전문가",
        "name": "전문가",
        "link": "/expert",
      },
      {
        "id": "차단 IP관리",
        "name": "차단 IP관리",
        "link": "/block-ip",
      },
    ]
  },
  'ad': {
    'id': 'ad',
    'name': '광고 관리',
    'subMenu': [
      {
        "id": "ad-news",
        "name": "뉴스",
        "link": "/ad-news",
      },
      {
        "id": "ad-market",
        "name": "마켓",
        "link": "/ad-market",
      },
      // {
      //   "id": "ad-edu",
      //   "name": "교육",
      //   "link": "/ad-edu",
      // },
      {
        "id": "ad-realty",
        "name": "부동산",
        "link": "/ad-realty",
      },
      {
        "id": "ad-community",
        "name": "커뮤니티",
        "link": "/ad-community",
      },
      {
        "id": "ad-yellowpage",
        "name": "업소록",
        "link": "/ad-yellowpage",
      },
      // {
      //   "id": "newsletter",
      //   "name": "뉴스레터",
      //   "link": "/newsletter",
      // },
    ]
  },
  'news-content': {
    'id': 'news-content',
    'name': '뉴스 콘텐츠',
    'subMenu': [
      {
        "id": "news",
        "name": "News",
        "link": "/news",
      },
      {
        "id": "news-category",
        "name": "카테고리",
        "link": "/news/category",
      },
      {
        "id": "news-sub-category",
        "name": "하위 카테고리",
        "link": "/news/sub-category",
      }
    ]
  },
  'market-content': {
    'id': 'market-content',
    'name': '마켓 콘텐츠',
    'subMenu': [
      {
        "id": "buysell",
        "name": "사고팔고",
        "link": "/market/buysell",
      },
      {
        "id": "job-board",
        "name": "구인/구직",
        "link": "/market/job",
      },
      {
        "id": "tutor",
        "name": "학원/튜터",
        "link": "/market/tutor",
      },
      {
        "id": "car",
        "name": "자동차",
        "link": "/market/car",
      },
      {
        "id": "homestay",
        "name": "홈스테이",
        "link": "/market/homestay",
      },
    ]
  },
  'community-content': {
    'id': 'community-content',
    'name': '커뮤니티 콘텐츠',
    'subMenu': [
      {
        "id": "community-board",
        "name": "Board",
        "link": "/community/board",
      },
    ]
  },
  'realestate-content': {
    'id': 'realestate-content',
    'name': '부동산 콘텐츠',
    'subMenu': [
      {
        "id": "rent",
        "name": "렌트",
        "link": "/market/rent",
      },
      {
        "id": "dwelling",
        "name": "주거매매",
        "link": "/market/dwelling",
      },
      {
        "id": "commercial",
        "name": "비즈매매",
        "link": "/market/commercial",
      },
      {
        "id": "openhouse",
        "name": "오픈하우스",
        "link": "/market/openhouse",
      },
    ]
  },
  // 'static': {
  //   'id': 'static',
  //   'name': '통계 관리',
  //   'subMenu': [
  //     {
  //       "id": "검색어",
  //       "name": "검색어",
  //       "link": "검색어",
  //     },
  //   ]
  // },
  'sales': {
    'id': 'sales',
    'name': '영업 관리',
    'subMenu': [
      {
        "id": "sales-order",
        "name": "주문(모)목록",
        "link": "/sales/order",
      },
      {
        "id": "sales-order-old",
        "name": "주문(웹)목록",
        "link": "/sales/order-old",
      },
    ]
  },
  'vkp': {
    'id': 'vkp',
    'name': 'VKP 관리',
    'subMenu': [
      {
        "id": "invoice",
        "name": "인보이스",
        "link": "/vkp/invoice",
      },
    ]
  },
};

export const UserTypeList2 = [
  { value: 'regular', label: '회원' },
  { value: 'sadmin', label: '관리' },
];
export const UserType2 = {
  'regular': { value: 'regular', label: '회원' },
  'admin': { value: 'admin', label: '관리자' },
};
export const UserTypeList = [
  { value: 'regular', label: '회원' },
  { value: 'designer', label: '디자이너' },
  { value: 'columnist', label: '칼럼니스트' },
  { value: 'expert', label: '전문가' },
  { value: 'realtor', label: '리얼터' },
  { value: 'cardealer', label: '자동차딜러' },
  { value: 'admin', label: '관리자' },
  { value: 'sadmin', label: 'Super 관리자' },
];
export const UserType = {
  'regular': { value: 'regular', label: '회원' },
  'designer': { value: 'designer', label: '디자이너' },
  'columnist': { value: 'columnist', label: '칼럼니스트' },
  'expert': { value: 'expert', label: '전문가' },
  'realtor': { value: 'realtor', label: '리얼터' },
  'cardealer': { value: 'cardealer', label: '자동차딜러' },
  'admin': { value: 'admin', label: '관리자' },
  'sadmin': { value: 'sadmin', label: 'Super 관리자' },
};


export const UserSubTypeList = [
  { value: '', label: 'All' },
  { value: 'manager', label: 'manager' },
  { value: 'reporter', label: 'reporter' },
  { value: 'admin1', label: 'admin1' },
  { value: 'manager2', label: 'manager2' },
  { value: 'regular', label: 'regular' },
  { value: 'regular_cardealer', label: 'regular_cardealer' },
  { value: 'regular_columnist', label: 'regular_columnist' },
  { value: 'regular_designer', label: 'regular_designer' },
  { value: 'regular_expert', label: 'regular_expert' },
  { value: 'regular_normal', label: 'regular_normal' },
  { value: 'regular_realtor', label: 'regular_realtor' },
  { value: 'sadmin', label: 'sadmin' },
];
export const UserSubType = {
  'regular_normal': { value: 'regular_normal', label: '일반회원' },
  'regular_designer': { value: 'regular_designer', label: '디자이너' },
  'regular_columnist': { value: 'regular_columnist', label: '칼럼니스트' },
  'regular_expert': { value: 'regular_expert', label: '전문가' },
  'regular_realtor': { value: 'regular_realtor', label: '리얼터' },
  'regular_cardealer': { value: 'regular_cardealer', label: '자동차딜러' },
};
export const UserSubType2 = {
  '': {
    '': 'All',
  },
  'admin': {
    '': 'All',
    'manager':'manager',
    'reporter':'reporter',
  },
  'regular': {
    '': 'All',
    'admin1':'admin1',
    'manager2':'manager2',
    'regular':'regular',
    'regular_cardealer':'regular_cardealer',
    'regular_columnist':'regular_columnist',
    'regular_designer':'regular_designer',
    'regular_expert':'regular_expert',
    'regular_normal':'regular_normal',
    'regular_realtor':'regular_realtor',
    'sadmin':'sadmin',
  },
  'sadmin': {
    '': 'All',
    'regular_normal':'regular_normal',
    'reporter':'reporter',
    'sadmin':'sadmin',
  },
};

export const BlockIPType = {
  'news': { value: 'news', label: '뉴스' },
  'market': { value: 'market', label: '마켓' },
  'realty': { value: 'realty', label: '부동산' },
  'yellowpage': { value: 'yellowpage', label: '업소록' },
};



export const SexTypeList = [
  { value: '', label: 'None' },
  { value: 'M', label: 'Man' },
  { value: 'F', label: 'Female' },
];
export const SexType = {
  '': { value: '', label: 'None' },
  'M': { value: 'M', label: 'Man' },
  'F': { value: 'F', label: 'Female' },
};

export const BoardTypeList = [
  { value: 0, label: '전체'},
  { value: 1, label: '사고팔고'},
  { value: 6, label: '학원튜터'},
  { value: 7, label: '구인구직'},
  { value: 9, label: 'FOOD'},
  { value: 2, label: '자동차'},
  { value: 5, label: '홈스테이'},
  { value: 21, label: '렌트'},
  { value: 11, label: '주거매매'},
  { value: 12, label: '비즈매매'},
  { value: 13, label: '오픈하우스'},
];
export const BoardType = {
  0: { value: 0, label: '전체', code: 'All'},
  1: { value: 1, label: '사고팔고', code: 'buysell'},
  6: { value: 6, label: '학원튜터', code: 'tutor'},
  7: { value: 7, label: '구인구직', code: 'job'},
  9: { value: 9, label: 'FOOD', code: 'food'},
  2: { value: 2, label: '자동차', code: 'car'},
  5: { value: 5, label: '홈스테이', code: 'homestay'},
  21: { value: 21, label: '렌트', code: 'rent'},
  11: { value: 11, label: '주거매매', code: 'dwelling'},
  12: { value: 12, label: '비즈매매', code: 'commercial'},
  13: { value: 13, label: '오픈하우스', code: 'openhouse'},
};

export const Premium = {
  0: { value: 0, label: '취소'},
  1: { value: 1, label: '등록'},
  2: { value: 2, label: '신청중'},
};

export const PremiumCode = [
  { value: '', label: '전체' },
  { value: '1', label: '등록' },
  { value: '2', label: '신청중' },
  { value: '0', label: '취소' },
]

export const ListSize = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 500, label: 500 },
]

export const CustomerPrintTypeList = [
  { value: '', label: 'All' },
  { value: 'E', label: 'eMail' },
  { value: 'I', label: 'Invoice Only' },
  { value: 'S', label: 'Statement Only' },
  { value: 'IS', label: 'Inv + Stat' },
  { value: 'IT', label: 'Inv + Tear' },
  { value: 'ITS', label: 'Inv + Tear + Stat' },
  { value: 'N', label: 'Not to Print' },
];

export const ActiveTypeList = [
  { value: 0, label: '닫기' },
  { value: 1, label: '열기' },
  { value: 2, label: '대기' },
  { value: 9, label: '삭제' },
];

export const ActiveType = {
  0: { value: 0, label: '닫기' },
  1: { value: 1, label: '열기' },
  2: { value: 2, label: '대기' },
  9: { value: 9, label: '삭제' },
};

export const ContactTypes = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const PostState = [
  { value: '',label: '' },
];

export const BuysellTypes =  {
  '': { value: '', label: '팝니다'},
  '삽니다': { value: '삽니다', label: '삽니다'},
  '무료나눔': { value: '무료나눔', label: '무료나눔'},
  '무빙세일': { value: '무빙세일', label: '무빙세일'},
};

export const BuysellPremiumRank =  {
  '1': { value: '1', label: '운송'},
  '2': { value: '2', label: '핸디맨'},
  '3': { value: '3', label: '건축'},
  '4': { value: '4', label: '자동차'},
  '5': { value: '5', label: '클리닝'},
  '6': { value: '6', label: '식품'},
  '7': { value: '7', label: '뷰티'},
  '9': { value: '9', label: '가전'},
  '10': { value: '10', label: '정크처리'},
  '11': { value: '11', label: '컴퓨터'},
  '12': { value: '12', label: '관광/택시'},
  '13': { value: '13', label: '통신'},
  '14': { value: '14', label: '가구'},
  '15': { value: '15', label: '스포츠'},
  '102': { value: '102', label: '가드닝'},
  '103': { value: '103', label: '안경'},
  '106': { value: '106', label: '보험'},
  '107': { value: '107', label: '이민'},
  '109': { value: '109', label: '간판/디자인'},
  '113': { value: '113', label: '회계사'},
  '116': { value: '116', label: '건강'},
  '117': { value: '117', label: '기타일반'},
  '118': { value: '118', label: '데이케어'},
  '120': { value: '120', label: '교육'},
  '121': { value: '121', label: '애견'},
  '122': { value: '122', label: '귀금속'},
  '123': { value: '123', label: '열쇠'},
  '135': { value: '135', label: '방역'},
  '137': { value: '137', label: '지붕'},
  '138': { value: '138', label: '보안'},
};

export const BuysellCategories = {
  'fashion-beauty': { value: 'fashion-beauty', label: '패션/미용', displaySelect: true},
  'living-furniture': { value: 'living-furniture', label: '생활/가구', displaySelect: true},
  'digital-appliances': { value: 'digital-appliances', label: '디지털/가전', displaySelect: true},
  'sports-leisure': { value: 'sports-leisure', label: '스포츠/레저', displaySelect: true},
  'childBirth-care': { value: 'childBirth-care', label: '출산/육아', displaySelect: true},
  'hobby-book': { value: 'hobby-book', label: '취미/도서', displaySelect: true},
  'etc-goods': { value: 'etc-goods', label: '기타/잡화', displaySelect: true},
};

export const BuysellConditions =  {
  new: { value: 'new', label: '미사용'},
  good: { value: 'good', label: '상태좋음'},
  used: { value: 'used', label: '사용감 있음'},
};

export const BuysellPurchaseStatus =  {
  'trading': { value: 'trading', label: '거래중'},
  'sold-out': { value: 'sold-out', label: '판매완료'},
};

export const JobTypeList = [
  { value: '구인', label: '구인' },
  { value: '구직', label: '구직' },
];

export const JobTypes = {
  '구인': { value: '구인', label: '구인' },
  '구직': { value: '구직', label: '구직' },
};

export const JobPayTypes = [
  { value: '8', label: '인터넷(Credit Card) 결제 ($10+GST/month)' },
  { value: '', label: '전화 결제 (604-877-1178)' },
];

export const JobContacts = {
  'phone': { value: 'phone', label: '전화번호'},
  'email': { value: 'email', label: '이메일'},
  'both': { value: 'both', label: '모두 선택'},
};

export const JobCategories = {
  'restaurant': { value: 'restaurant', label: '외식/요식'},
  'office-accounting': { value: 'office-accounting', label: '사무/회계'},
  'sales-consultation': { value: 'sales-consultation', label: '영업/상담'},
  'retail-dealership': { value: 'retail-dealership', label: '유통/판매'},
  'shipping-logistics': { value: 'shipping-logistics', label: '배송/물류'},
  'production-tech': { value: 'production-tech', label: '생산/기술'},
  'construction': { value: 'construction', label: '건설/토목'},
  'care-cleaning': { value: 'care-cleaning', label: '돌봄/청소'},
  'it-design': { value: 'it-design', label: 'IT/디자인'},
  'beauty-ceremony': { value: 'beauty-ceremony', label: '미용/예식'},
  'healthcare': { value: 'healthcare', label: '간호/의료'},
  'teaching-lecturer': { value: 'teaching-lecturer', label: '교육/강사'},
  'etc': { value: 'etc', label: '기타'},
};

export const JobEmploymentType = {
  'full-time': { value: 'full-time', label: '풀타임'},
  'part-time': { value: 'part-time', label: '파트타임'},
  'both': { value: 'both', label: '무관'},
};

export const JobSalaryRate = {
  'hour': { value: 'hour', label: '시급' },
  'month': { value: 'month', label: '월급' },
  'year': { value: 'year', label: '연봉' },
  'deal': { value: 'deal', label: '협의' },
};

export const JobCareerType = {
  'entry-level': { value: 'entry-level', label: '신입'},
  'experienced': { value: 'experienced', label: '경력'},
  'both': { value: 'both', label: '무관'},
};

export const TutorTypes = {
  '학원': { value: '학원', label: '학원'},
  '튜터': { value: '튜터', label: '튜터'},
};

export const TutorCategories = {
  'school-curriculum': { value: 'school-curriculum', label: '교과'},
  'language': { value: 'language', label: '외국어'},
  'art': { value: 'art', label: '예술'},
  'sports-leisure': { value: 'sports-leisure', label: '스포츠/여가'},
  'computer-it': { value: 'computer-it', label: '컴퓨터/IT'},
  'professional-skill': { value: 'professional-skill', label: '전문/기술'},
  'etc': { value: 'etc', label: '기타'},
};

export const TutorLearners = {
  'grade0': { value: 'grade0', label: '미취학아동'},
  'grade1-6': { value: 'grade1-6', label: '초등(Gr.1-6)'},
  'grade7-9': { value: 'grade7-9', label: '중등(Gr.7-9)'},
  'grade10-12': { value: 'grade10-12', label: '고등(Gr.10-12)'},
  'college-entrance': { value: 'college-entrance', label: '고등(입시)'},
  'bachelor-master-degree': { value: 'bachelor-master-degree', label: '대학(원)'},
  'career-adult': { value: 'career-adult', label: '커리어(성인)'},
};

export const TutorLectureType = {
  'in-person': { value: 'in-person', label: '대면'},
  'remote': { value: 'remote', label: '비대면'},
};

export const TutorLearnerNumber = {
  '1-1': { value: '1-1', label: '1:1'},
  'group': { value: 'group', label: '그룹'},
};

export const TutorLectureLanguages = {
  'korean': { value: 'korean', label: '한국어'},
  'english': { value: 'english', label: '영어'},
};

export const TutorRate = {
  'hour': { value: 'hour', label: '시간당' },
  'time': { value: 'time', label: '회당' },
  'month': { value: 'month', label: '월' },
  'deal': { value: 'deal', label: '문의' },
};

// ------
export const CarFlagTypes = {
  '': { value: '', label: '없음'},
  '급매': { value: '급매', label: '급매'},
};

export const CarTypes = {
  '경차': { value: '경차', label: '경차'},
  '소형차': { value: '소형차', label: '소형차'},
  '준중형차': { value: '준중형차', label: '준중형차'},
  '중형차': { value: '중형차', label: '중형차'},
  '대형차': { value: '대형차', label: '대형차'},
  'SUV': { value: 'SUV', label: 'SUV'},
  '미니밴': { value: '미니밴', label: '미니밴'},
  'RV/트럭': { value: 'RV/트럭', label: 'RV/트럭'},
  '기타': { value: '기타', label: '기타'},
};

export const CarGesTypes = {
  '디젤': { value: '디젤', label: '디젤'},
  '가솔린': { value: '가솔린', label: '가솔린'},
  '하이브리드': { value: '하이브리드', label: '하이브리드'},
  '전기': { value: '전기', label: '전기'},
  '기타': { value: '기타', label: '기타'},
};

export const CarTransmissions = {
  '오토': { value: '오토', label: '오토'},
  '수동': { value: '수동', label: '수동'},
  '세미오토': { value: '세미오토', label: '세미오토'},
  'CVT': { value: 'CVT', label: 'CVT'},
  '기타': { value: '기타', label: '기타'},
};

export const CarDrivingSystems = {
  '4WD': { value: '4WD', label: '4WD'},
  'AWD': { value: 'AWD', label: 'AWD'},
  'FWD': { value: 'FWD', label: 'FWD'},
  'RWD': { value: 'RWD', label: 'RWD'},
  '기타': { value: '기타', label: '기타'},
};

export const CarColors = {
  '2': { value: '2', label: '블랙'},
  '6': { value: '6', label: '화이트'},
  '3': { value: '3', label: '그레이'},
  '9': { value: '9', label: '실버'},
  '12': { value: '12', label: '브론즈'},
  '13': { value: '13', label: '브라운'},
  '11': { value: '11', label: '블루'},
  '1': { value: '1', label: '레드'},
  '14': { value: '14', label: '골드'},
  '8': { value: '8', label: '티이일'},
  '7': { value: '7', label: '다크그레이'},
  '4': { value: '4', label: '그린'},
  '5': { value: '5', label: '옐로우'},
  '10': { value: '10', label: '기타'},
};

export const CarBrands = {
  'Acura': { value: 'Acura', label: '아큐라'},
  'Alfa Romeo': { value: 'Alfa Romeo', label: '알파로미오'},
  'Audi': { value: 'Audi', label: '아우디'},
  'BMW': { value: 'BMW', label: 'BMW'},
  'Buick': { value: 'Buick', label: '뷰익'},
  'Cadillac': { value: 'Cadillac', label: '캐딜락'},
  'Caliber': { value: 'Caliber', label: '캘리버'},
  'Chevrolet': { value: 'Chevrolet', label: '쉐보레'},
  'Chrysler': { value: 'Chrysler', label: '크라이슬러'},
  'Dodge': { value: 'Dodge', label: '닷지'},
  'Fiat': { value: 'Fiat', label: '피아트'},
  'Ford': { value: 'Ford', label: '포드'},
  'GMC': { value: 'GMC', label: 'GMC'},
  'Genesis': { value: 'Genesis', label: '제네시스'},
  'Honda': { value: 'Honda', label: '혼다'},
  'Hummer': { value: 'Hummer', label: '허머'},
  'Hyundai': { value: 'Hyundai', label: '현대'},
  'Infiniti': { value: 'Infiniti', label: '인피니티'},
  'Jaguar': { value: 'Jaguar', label: '재규어'},
  'Jeep': { value: 'Jeep', label: 'Jeep'},
  'Kia': { value: 'Kia', label: '기아'},
  'Land Rover': { value: 'Land Rover', label: '랜드로버'},
  'Lexus': { value: 'Lexus', label: '렉서스'},
  'Lincoln': { value: 'Lincoln', label: '링컨'},
  'Mini': { value: 'Mini', label: 'Mini'},
  'Maserati': { value: 'Maserati', label: '마세라티'},
  'Mazda': { value: 'Mazda', label: '마쓰다'},
  'Mercedes-AMG': { value: 'Mercedes-AMG', label: '메르세데스-AMG'},
  'Mercedes-Benz': { value: 'Mercedes-Benz', label: '메르세데스-벤츠'},
  'Mitsubishi': { value: 'Mitsubishi', label: '미쓰비시'},
  'Morgan': { value: 'Morgan', label: '모건'},
  'Nissan': { value: 'Nissan', label: '닛산'},
  'Pontiac': { value: 'Pontiac', label: '폰티액'},
  'Porsche': { value: 'Porsche', label: '포르쉐'},
  'Ram': { value: 'Ram', label: '램'},
  'Saab': { value: 'Saab', label: '사브'},
  'Saturn': { value: 'Saturn', label: '새턴'},
  'Scion': { value: 'Scion', label: '사이언'},
  'Smart': { value: 'Smart', label: '스마트'},
  'Subaru': { value: 'Subaru', label: '스바루'},
  'Suzuki': { value: 'Suzuki', label: '스즈키'},
  'Tesla': { value: 'Tesla', label: '테슬라'},
  'Toyota': { value: 'Toyota', label: '토요타'},
  'Volkswagen': { value: 'Volkswagen', label: '폭스바겐'},
  'Volvo': { value: 'Volvo', label: '볼보'},
};


export const CarIncludedOptions = {
  '[sunroof]': { value: '[sunroof]', label: '선루프'},
  '[roof-rack]': { value: '[roof-rack]', label: '루프랙'},
  '[navigation]': { value: '[navigation]', label: '네비게이션'},
  '[bluetooth]': { value: '[bluetooth]', label: '블루투스'},
  '[leather-seat]': { value: '[leather-seat]', label: '가죽시트'},
  '[electric-seat]': { value: '[electric-seat]', label: '전동시트'},
  '[rear-camera]': { value: '[rear-camera]', label: '후방카메라'},
  '[collision-avoidance]': { value: '[collision-avoidance]', label: '추돌방지기능'},
  '[lane-departure-warning]': { value: '[lane-departure-warning]', label: '차선이탈경보'},
};

export const CarAdditionalOptions = {
  '[viewable-car-history]': { value: '[viewable-car-history]', label: '차량기록조회 가능'},
  '[possible-trade-in]': { value: '[possible-trade-in]', label: '트레이드인 가능'},
  '[full-inspection-complete]': { value: '[full-inspection-complete]', label: '풀인스펙션 완료'},
  '[extensible-warranty]': { value: '[extensible-warranty]', label: '워런티 연장가능'},
  '[owned-by-one-person]': { value: '[owned-by-one-person]', label: '1인 소유'},
  '[non-smoking]': { value: '[non-smoking]', label: '비흡연'},
  '[accident-free]': { value: '[accident-free]', label: '무사고'},
};

// ------
export const HomestayPayTypes = [
  { value: '8', label: '인터넷(Credit Card) 결제 ($10+GST/month)' },
  { value: '', label: '전화 결제 (604-877-1178)' },
];

export const HomestayTypes = {
  '1': { value: '1', label: '하우스'},
  '2': { value: '2', label: '타운하우스'},
  '3': { value: '3', label: '콘도/아파트'},
  '4': { value: '4', label: '사무실/상가'},
  '5': { value: '5', label: '비지니스'},
  '6': { value: '6', label: '기타'},
};

export const HomestayRoomTypes = {
  '1': { value: '1', label: '독채'},
  '2': { value: '2', label: '개인실'},
  '3': { value: '3', label: '다인실'},
  '4': { value: '4', label: '가족실'},
  '5': { value: '5', label: '기타'},
};

export const HomestayGuestGender = {
  'male-only': { value: 'male-only', label: '남성전용'},
  'female-only': { value: 'female-only', label: '여성전용'},
  '': { value: '', label: '성별무관'},
};

export const HomestayPaymentFrequency = {
  '1박': { value: '1박', label: '1박' },
  '월': { value: '월', label: '월' },
  '': { value: '', label: '가격문의' },
};

export const HomestayCheckInTypes = {
  'immediate-checkIn': { value: 'immediate-checkIn', label: '즉시입주'},
  'checkIn-date': { value: 'checkIn-date', label: '날짜선택'},
  'deal': { value: 'deal', label: '협의 가능'},
};

export const HomestayMinimumReservation = {
  '1박': { value: '1박', label: '1박' },
  '2박': { value: '2박', label: '2박' },
  '3박': { value: '3박', label: '3박' },
  '4박': { value: '4박', label: '4박' },
  '5박': { value: '5박', label: '5박' },
  '6박': { value: '6박', label: '6박' },
  '7박 이상': { value: '7박 이상', label: '7박 이상' },
  '1달 이상': { value: '1달 이상', label: '1달 이상' },
  '': { value: '', label: '기타' },
};

export const HomestayDetailedOptions = {
  '[meals]': { value: '[meals]', label: '식사제공'},
  '[airport-pick-up]': { value: '[airport-pick-up]', label: '공항픽업'},
  '[wifi]': { value: '[wifi]', label: 'WIFI'},
  '[ride]': { value: '[ride]', label: '라이드'},
  '[kitchen]': { value: '[kitchen]', label: '주방시설'},
  '[parking]': { value: '[parking]', label: '주차가능'},
  '[pet]': { value: '[pet]', label: '반려동물'},
  '[cleaning]': { value: '[cleaning]', label: '청소/세탁 서비스'},
  '[learning-management]': { value: '[learning-management]', label: '학습관리'},
  '[separate-door]': { value: '[separate-door]', label: '별도 출입문'},
  '[washroom]': { value: '[washroom]', label: '전용 화장실'},
};

// ------
export const RentTypes = {
  '1': { value: '1', label: '하우스'},
  '2': { value: '2', label: '타운하우스'},
  '3': { value: '3', label: '콘도/아파트'},
  '4': { value: '4', label: '사무실/상가'},
  '5': { value: '5', label: '창고/차고'},
  '6': { value: '6', label: '기타'},
};

export const RentRoomTypes = {
  '1': { value: '1', label: '스튜디오', buttonUrl: '' },
  '2': { value: '2', label: '1BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '3': { value: '3', label: '2BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '4': { value: '4', label: '3BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '5': { value: '5', label: '4BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '6': { value: '6', label: '5BR', buttonUrl: '/icon/rent-arrow-up-icon.png' },
  '7': { value: '7', label: '룸렌트', buttonUrl: '' },
  '8': { value: '8', label: '기타', buttonUrl: '' },
};

export const RentPaymentFrequency = {
  '월': { value: '월', label: '월' },
  '': { value: '', label: '가격문의' },
};

export const RentMovingTypes = {
  'immediate-moveIn': { value: 'immediate-moveIn', label: '즉시입주'},
  'moveIn-date': { value: 'moveIn-date', label: '날짜선택'},
  'deal': { value: 'deal', label: '협의 가능'},
};

export const RentLeaseTerms = {
  '1달 미만': { value: '1달 미만', label: '1달 미만'},
  '1달 이상': { value: '1달 이상', label: '1달 이상'},
  '3달 이상': { value: '3달 이상', label: '3달 이상'},
  '6달 이상': { value: '6달 이상', label: '6달 이상'},
  '1년 이상': { value: '1년 이상', label: '1년 이상'},
  '기타': { value: '기타', label: '기타'},
};

export const RentDetailedOptions = {
  '[fully-furnished]': { value: '[fully-furnished]', label: '가구완비' },
  '[included-utilities]': { value: '[included-utilities]', label: '유틸리티(포함)' },
  '[semi-utilities]': { value: '[semi-utilities]', label: '유틸리티(분할)' },
  '[included-internet]': { value: '[included-internet]', label: '인터넷(포함)' },
  '[semi-internet]': { value: '[semi-internet]', label: '인터넷(분할)' },
  '[allowed-parking]': { value: '[allowed-parking]', label: '주차가능' },
  '[allowed-pet]': { value: '[allowed-pet]', label: '반려동물 가능' },
  '[allowed-smoking]': { value: '[allowed-smoking]', label: '흡연 가능' },
  '[included-washroom]': { value: '[included-washroom]', label: '전용 화장실' },
  '[included-washingMachine]': { value: '[included-washingMachine]', label: '별도 전용세탁기' },
  '[included-entranceDoor]': { value: '[included-entranceDoor]', label: '별도 출입문' },
  '[need-tenantInsurance]': { value: '[need-tenantInsurance]', label: '세입자보험' },
};
// ------
export const DwellingTypes = {
  '하우스': { value: '하우스', label: '하우스'},
  '타운하우스': { value: '타운하우스', label: '타운하우스'},
  '콘도/아파트': { value: '콘도/아파트', label: '콘도/아파트'},
  '기타': { value: '기타', label: '기타'},
};

export const DwellingFlagTypes = {
  '': { value: '', label: '없음'},
  '급매': { value: '급매', label: '급매'},
  '전매': { value: '전매', label: '전매'},
  '분양': { value: '분양', label: '분양'},
};

export const DwellingSpaceUnits = {
  'sqft': { value: 'sqft', label: 'Sqft'},
  'acre': { value: 'acre', label: 'Acre'},
};

export const DwellingIncludedOptions = {
  '[refrigerator]': { value: '[refrigerator]', label: '냉장고'},
  '[dishwasher]': { value: '[dishwasher]', label: '식기세척기'},
  '[microwave]': { value: '[microwave]', label: '전자레인지'},
  '[washer-dryer]': { value: '[washer-dryer]', label: '세탁기/건조기'},
  '[clothes-machine]': { value: '[clothes-machine]', label: '의류관리기'},
  '[air-conditioner]': { value: '[air-conditioner]', label: '에어컨'},
  '[floor-heating]': { value: '[floor-heating]', label: '바닥난방'},
  '[laminated-floor]': { value: '[laminated-floor]', label: '라미네이트 바닥'},
  '[carpet-floor]': { value: '[carpet-floor]', label: '카펫바닥'},
  '[fireplace]': { value: '[fireplace]', label: '벽난로'},
  '[storage]': { value: '[storage]', label: '스토리지'},
};

export const DwellingAdditionalFacilities = {
  '[fitness-room]': { value: '[fitness-room]', label: '피트니스룸'},
  '[yoga-room]': { value: '[yoga-room]', label: '요가룸'},
  '[pool]': { value: '[pool]', label: '수영장'},
  '[playground]': { value: '[playground]', label: '놀이터'},
  '[lounge]': { value: '[lounge]', label: '라운지'},
  '[concierge]': { value: '[concierge]', label: '컨시어지'},
  '[rooftop]': { value: '[rooftop]', label: '루프탑'},
  '[ev-charger]': { value: '[ev-charger]', label: 'EV 충전기'},
};
// ------
export const CommercialFlagTypes = {
  '': { value: '', label: '없음'},
  '급매': { value: '급매', label: '급매'},
  '전매': { value: '전매', label: '전매'},
  '분양': { value: '분양', label: '분양'},
};

export const CommercialTypes = {
  '비즈니스': { value: '비즈니스', label: '비즈니스'},
  '소매': { value: '소매', label: '소매'},
  '상가': { value: '상가', label: '상가'},
  '사무실': { value: '사무실', label: '사무실'},
  '땅/부지': { value: '땅/부지', label: '땅/부지'},
  '기타': { value: '기타', label: '기타'},
};

export const CommercialTypeList = [
  { value: '비즈니스', label: '비즈니스'},
  { value: '소매', label: '소매'},
  { value: '상가', label: '상가'},
  { value: '사무실', label: '사무실'},
  { value: '땅/부지', label: '땅/부지'},
  { value: '기타', label: '기타'},
];

export const CommercialUnits = {
  'sqft': { value: 'sqft', label: 'Sqft'},
  'acre': { value: 'acre', label: 'Acre'},
};

export const CommercialIncludedOptions = {
  '[elevator]': { value: '[elevator]', label: '엘리베이터'},
  '[full-kitchen]': { value: '[full-kitchen]', label: '풀키친'},
  '[storage]': { value: '[storage]', label: '창고'},
  '[cellar]': { value: '[cellar]', label: '지하실'},
  '[liquor-license]': { value: '[liquor-license]', label: '주류면허'},
  '[patio]': { value: '[patio]', label: '파티오'},
  '[walk-in-cooler]': { value: '[walk-in-cooler]', label: '워크인 쿨러'},
  '[residential-space]': { value: '[residential-space]', label: '주거공간'},
  '[security-system]': { value: '[security-system]', label: '보안 시스템'},
  '[private-washroom]': { value: '[private-washroom]', label: '전용 화장실'},
};

export const CommercialAdditionalFacilities = {
  '[negotiable-price]': { value: '[negotiable-price]', label: '가격협상 가능'},
  '[changeable-business]': { value: '[changeable-business]', label: '타업종 변경가능'},
  '[full-training]': { value: '[full-training]', label: '풀 트레이닝 가능'},
  '[no-demolition-clause]': { value: '[no-demolition-clause]', label: '데몰리션 조항 없음'},
};
// ------
export const OpenhouseTypes = {
  '하우스': { value: '하우스', label: '하우스'},
  '타운하우스': { value: '타운하우스', label: '타운하우스'},
  '콘도/아파트': { value: '콘도/아파트', label: '콘도/아파트'},
  '기타': { value: '기타', label: '기타'},
}

export const OpenhouseSpaceUnits = {
  'sqft': { value: 'sqft', label: 'Sqft'},
  'acre': { value: 'acre', label: 'Acre'},
};
// ------
export const RealEstateProvinces = {
  '1': { value: '1', label: 'BC'},
  '4': { value: '4', label: 'AB'},
  '7': { value: '7', label: 'SK'},
};

export const RealEstateCities = {
  '1': { value: '1', label: '밴쿠버', enName: 'Vancouver'},
  '8': { value: '8', label: '리치몬드', enName: 'Richmond'},
  '25': { value: '25', label: '뉴웨스민스터', enName: 'New Westminster'},
  '5': { value: '5', label: '	코퀴틀람', enName: 'Coquitlam'},
  '4': { value: '4', label: '	버나비', enName: 'Burnaby'},
  '14': { value: '14', label: '포트코퀴틀람', enName: 'Port Coquitlam'},
  '2': { value: '2', label: '포트무디', enName: 'Port Moody'},
  '7': { value: '7', label: '써리', enName: 'Surrey'},
  '10': { value: '10', label: '랭리', enName: 'Langley'},
  '6': { value: '6', label: '노스밴쿠버', enName: 'North Vancouver'},
  '11': { value: '11', label: '웨스트밴쿠버', enName: 'West Vancouver'},
  '21': { value: '21', label: '메이플릿지', enName: 'Maple Ridge'},
  '24': { value: '24', label: '핏메도우', enName: 'White Rock'},
  '19': { value: '19', label: '화이트락', enName: 'White Rock'},
  '9': { value: '9', label: '	델타', enName: 'Delta'},
  '12': { value: '12', label: '아보츠포드', enName: 'Abbotsford'},
  '13': { value: '13', label: '칠리왁', enName: 'Chilliwack'},
  '15': { value: '15', label: '나나이모', enName: 'Nanaimo'},
  '16': { value: '16', label: '빅토리아', enName: 'Victoria'},
  '17': { value: '17', label: '켈로나', enName: 'Kelowna'},
  '18': { value: '18', label: '캠룹스', enName: 'Kamloops'},
  '20': { value: '20', label: '미션', enName: 'Mission'},
  '22': { value: '22', label: '스쿼미쉬', enName: 'Squamish'},
  '23': { value: '23', label: '휘슬러', enName: 'Whistler'},
  '40': { value: '40', label: '기타', enName: 'Other'},
};

export const CityList = [
  { value: '1', label: 'Burnaby' },
  { value: '2', label: 'Coquitlam' },
  { value: '3', label: 'Port Moody' },
  { value: '4', label: 'Surrey' },
  { value: '5', label: 'Vancouver' },
  { value: '6', label: 'North Vancouver' },
  { value: '7', label: 'West Vancouver' },
  { value: '8', label: 'Maple Ridge' },
  { value: '9', label: 'Richmond' },
  { value: '10', label: 'White Rock' },
  { value: '11', label: 'Langley' },
  { value: '12', label: 'Delta' },
  { value: '14', label: 'Port Coquitlam' },
  { value: '15', label: 'New Westminster' },
  { value: '16', label: 'Pitt Meadow' },
  { value: '17', label: 'Jasper' },
  { value: '19', label: 'Abbotsford' },
  { value: '18', label: 'Lower Mainland' },
  { value: '13', label: 'Other' },
];

export const AddressCountry = [
  { value: 'Canada', label: 'Canada' },
  { value: 'S.Korea', label: 'S.Korea' },
  { value: 'USA', label: 'USA' },
];

export const AddressRegion = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
];

export const AddressCity = [
  { value: '100mile', label: '100 Mile House' },
  { value: 'abbo', label: 'Abbotsford' },
  { value: 'alder', label: 'Aldergrove' },
  { value: 'alexisc', label: 'AlexisCreek' },
  { value: 'bby', label: 'Burnaby' },
  { value: 'bella', label: 'Bella Coola' },
  { value: 'campb', label: 'Campbell River' },
  { value: 'charotte', label: 'Charlotte' },
  { value: 'chema', label: 'Chemainus' },
  { value: 'chilli', label: 'Chilliwack' },
  { value: 'clin', label: 'Clinton' },
  { value: 'coq', label: 'Coquitlam' },
  { value: 'croft', label: 'Crofton' },
  { value: 'dawson', label: 'Dawson Creek' },
  { value: 'delta', label: 'Delta' },
  { value: 'duncan', label: 'Duncan' },
  { value: 'fortL', label: 'Fort Langley' },
  { value: 'fsjohn', label: 'FortStJohn' },
  { value: 'gib', label: 'Gibson' },
  { value: 'Harrison', label: 'Harrison Mills' },
  { value: 'HHS', label: 'H Springs' },
  { value: 'Hope', label: 'Hope' },
  { value: 'Kamlo', label: 'Kamloops' },
  { value: 'kelowna', label: 'Kelowna' },
  { value: 'ladner', label: 'Ladner' },
  { value: 'lake e', label: 'Lake Errock' },
  { value: 'langley', label: 'Langley' },
  { value: 'map', label: 'Maple Ridge' },
  { value: 'merritt', label: 'Merritt' },
  { value: 'mission', label: 'Mission' },
  { value: 'mober', label: 'Moberly Lake' },
  { value: 'nanaimo', label: 'Nanaimo' },
  { value: 'nelson', label: 'Nelson' },
  { value: 'new west', label: 'New Westminster' },
  { value: 'nvan', label: 'North Vancouver' },
  { value: 'penderls', label: 'PenderIsland' },
  { value: 'pentic', label: 'Penticton' },
  { value: 'pit', label: 'Pitt Meadow' },
  { value: 'poco', label: 'Port Coquitlam' },
  { value: 'pomo', label: 'Port Moody' },
  { value: 'porth', label: 'Port Hardy' },
  { value: 'portS.', label: 'Port St. John' },
  { value: 'Powell', label: 'Powell River' },
  { value: 'prince', label: 'Prince George' },
  { value: 'quabeach', label: 'Qualicum Beach' },
  { value: 'revel', label: 'Revelstoke' },
  { value: 'rich', label: 'Richmond' },
  { value: 'Rosedale', label: 'Rosedale' },
  { value: 'Salmonar', label: 'SalmonArm' },
  { value: 'SHAWN', label: 'Shawnigan Lake' },
  { value: 'summld', label: 'Summerland' },
  { value: 'surrey', label: 'Surrey' },
  { value: 'terrace', label: 'Terrace' },
  { value: 'ucle', label: 'Ucluelet' },
  { value: 'Vale', label: 'Valemount' },
  { value: 'van', label: 'Vancouver' },
  { value: 'vander', label: 'Vanderhoof' },
  { value: 'vernon', label: 'Vernon' },
  { value: 'Vic', label: 'Victoria' },
  { value: 'whisl', label: 'Whistler' },
  { value: 'white', label: 'White Rock' },
  { value: 'william', label: 'William Lake' },
  { value: 'wono', label: 'Wonowon' },
  { value: 'wvan', label: 'West Vancouver' },
];

export const NewsAdType = {
  'ilbo': { value: 'ilbo', label: '배너'},
  'popup': { value: 'popup', label: '팝업'},
};

export const NewsAdTypeList = [
  { value: 'ilbo', label: '배너'},
];

export const NewsAdPositionLV1 = {
  '101': { value: '101', label: '전체페이지'},
};

export const NewsAdPositionLV1List = [
  { value: '101', label: '전체페이지'},
];

export const NewsAdPositionLV2 = {
  '101': {
    value: '101',
    label: '메뉴-상단',
    subList: [
      { value: '1', label: '메뉴-상단 1'},
      { value: '2', label: '메뉴-상단 2'},
      { value: '3', label: '메뉴-상단 3'},
      { value: '4', label: '메뉴-상단 4'},
      { value: '10', label: '메뉴-상단 5'},
      { value: '0', label: '메뉴-상단 0(X)'},
      { value: '5', label: '메뉴-상단 5(X)'},
      { value: '7', label: '메뉴-상단 7(X)'},
      { value: '8', label: '메뉴-상단 8(X)'},
      { value: '12', label: '메뉴-상단 12(X)'},
      { value: '13', label: '메뉴-상단 13(X)'},
      { value: '6', label: '둥둥이-상'},
      { value: '9', label: '둥둥이-중'},
      { value: '30', label: '둥둥이-하'},
    ]
  },
  '102': {
    value: '102',
    label: '오른쪽',
    subList: [
      { value: '1', label: '오른쪽 1'},
      { value: '2', label: '오른쪽 2'},
      { value: '3', label: '오른쪽 3'},
      { value: '4', label: '오른쪽 4'},
      { value: '5', label: '오른쪽 5'},
      { value: '6', label: '오른쪽 6'},
      { value: '7', label: '오른쪽 7'},
      { value: '8', label: '오른쪽 8'},
      { value: '9', label: '오른쪽 9'},
      { value: '10', label: '오른쪽 10'},
      { value: '11', label: '오른쪽 11'},
      { value: '12', label: '오른쪽 12'},
      { value: '13', label: '오른쪽 13'},
      { value: '14', label: '오른쪽 14'},
      { value: '15', label: '오른쪽 15'},
      { value: '16', label: '오른쪽 16'},
      { value: '17', label: '오른쪽 17'},
      { value: '18', label: '오른쪽 18'},
      { value: '19', label: '오른쪽 19'},
      { value: '20', label: '오른쪽 20'},
      { value: '21', label: '오른쪽 21'},
      { value: '22', label: '오른쪽 22'},
      { value: '23', label: '오른쪽 23'},
      { value: '24', label: '오른쪽 24'},
      { value: '25', label: '오른쪽 25'},
      { value: '26', label: '오른쪽 26'},
      { value: '27', label: '오른쪽 27'},
      { value: '29', label: '오른쪽 29'},
      { value: '30', label: '오른쪽 30'},
      { value: '31', label: '오른쪽 31'},
      { value: '32', label: '오른쪽 32'},
      { value: '33', label: '오른쪽 33'},
      { value: '34', label: '오른쪽 34'},
      { value: '50', label: '오른쪽 50'},
      { value: '52', label: '오른쪽 52'},
    ]
  },
  '103': {
    value: '103',
    label: '메인-중앙',
    subList: [
      { value: '1', label: '메인-중앙 1'},
      { value: '2', label: '메인-중앙 2'},
      { value: '3', label: '메인-중앙 3'},
      { value: '4', label: '메인-중앙 4'},
      { value: '5', label: '메인-중앙 5'},
      { value: '6', label: '메인-중앙 6'},
      { value: '7', label: '메인-중앙 7'},
      { value: '8', label: '메인-중앙 8'},
      { value: '9', label: '메인-중앙 9'},
      { value: '10', label: '메인-중앙 10'},
      { value: '11', label: '메인-중앙 11'},
      { value: '12', label: '메인-중앙 12'},
      { value: '13', label: '메인-중앙 13'},
      { value: '14', label: '메인-중앙 14'},
      { value: '17', label: '메인-중앙 17'},
      { value: '20', label: '메인-중앙 20'},
      { value: '21', label: '메인-중앙 21'},
      { value: '50', label: '메인-중앙 50'},
      { value: '51', label: '메인-중앙 51'},
      { value: '52', label: '메인-중앙 52'},
      { value: '53', label: '메인-중앙 53'},
      { value: '54', label: '메인-중앙 54'},
      { value: '55', label: '메인-중앙 55'},
      { value: '56', label: '메인-중앙 56'},
      { value: '57', label: '메인-중앙 57'},
    ]
  },
  '104': {
    value: '104',
    label: '메인-하단(X)',
    subList: [
      { value: '0', label: '메인-하단 0'},
      { value: '3', label: '메인-하단 3'},
      { value: '4', label: '메인-하단 4'},
      { value: '5', label: '메인-하단 5'},
      { value: '6', label: '메인-하단 6'},
      { value: '50', label: '메인-하단 50'},
    ]
  },
  '105': {
    value: '105',
    label: '컨텐츠',
    subList: [
      { value: '0', label: '0'},
    ]
  },
  '106': {
    value: '106',
    label: '메뉴-하단 3번째',
    subList: [
      { value: '1', label: '메뉴-하단 3번째 1'},
      { value: '2', label: '메뉴-하단 3번째 2'},
      { value: '3', label: '메뉴-하단 3번째 3'},
      { value: '6', label: '메뉴-하단 3번째 6'},
    ]
  },
  '107': {
    value: '107',
    label: '메뉴-하단 4번째',
    subList: [
      { value: '0', label: '메뉴-하단 4번째 0'},
      { value: '1', label: '메뉴-하단 4번째 1'},
      { value: '2', label: '메뉴-하단 4번째 2'},
      { value: '3', label: '메뉴-하단 4번째 3'},
    ]
  },
  '108': {
    value: '108',
    label: 'enews(X)',
    subList: [{ value: '0', label: 'enews(X) 0'},]
  },
  '109': {
    value: '109',
    label: 'enews_1(X)',
    subList: [{ value: '0', label: 'enews_1(X) 0'},]
  },
  '110': {
    value: '110',
    label: 'enews_2(X)',
    subList: [{ value: '0', label: 'enews_2(X) 0'},]
  },
  '111': {
    value: '111',
    label: '메인-팝업',
    subList: [{ value: '0', label: '메인-팝업 0'},]
  },
  '120': {
    value: '120',
    label: 'news_detail(X)',
    subList: [{ value: '1', label: 'news_detail(X) 1'},]
  },
};

export const NewsAdPositionLV2List = [
  { value: '101', label: '메뉴-상단'},
  { value: '102', label: '오른쪽'},
  { value: '103', label: '메인중앙'},
  { value: '104', label: '메인하단(X)'},
  { value: '105', label: '컨텐츠'},
  { value: '106', label: '메뉴-하단 3번째'},
  { value: '107', label: '메뉴-하단 4번째'},
  { value: '108', label: 'enews(X)'},
  { value: '109', label: 'enews_1(X)'},
  { value: '110', label: 'enews_2(X)'},
  { value: '111', label: '메인-팝업'},
  { value: '120', label: 'news_detail(X)'},
];

export const EduAdType = {
  'edu': { value: 'edu', label: '배너'},
};

export const EduAdTypeList = [
  { value: 'edu', label: '배너'},
];

export const EduAdPositionLV1 = {
  '201': { value: '201', label: '메인페이지'},
  '202': { value: '202', label: '교육NEWS'},
  '203': { value: '203', label: '초중고학교'},
  '204': { value: '204', label: '어학연수'},
  '205': { value: '205', label: 'COLLEGE'},
  '206': { value: '206', label: 'UNIVERSITY'},
  '207': { value: '207', label: '완벽취업대비'},
  '208': { value: '208', label: '에듀게시판'},
};

export const EduAdPositionLV1List = [
  { value: '201', label: '메인페이지'},
  { value: '202', label: '교육NEWS'},
  { value: '203', label: '초중고학교'},
  { value: '204', label: '어학연수'},
  { value: '205', label: 'COLLEGE'},
  { value: '206', label: 'UNIVERSITY'},
  { value: '207', label: '완벽취업대비'},
  { value: '208', label: '에듀게시판'},
];

export const EduAdPositionLV2 = {
  '201': { value: '201', label: '상단'},
  '202': { value: '202', label: '오른쪽'},
  '203': { value: '203', label: '중앙1'},
  '204': { value: '204', label: '중앙2'},
  '205': { value: '205', label: 'main_fly'},
  '206': { value: '206', label: 'new_s_center'},
  '207': { value: '207', label: 'new_b_center'},
  '208': { value: '208', label: 'new_sub_center'},
};

export const EduAdPositionLV2List = [
  { value: '201', label: '상단'},
  { value: '202', label: '오른쪽'},
  { value: '203', label: '중앙1'},
  { value: '204', label: '중앙2'},
  { value: '205', label: 'main_fly'},
  { value: '206', label: 'new_s_center'},
  { value: '207', label: 'new_b_center'},
  { value: '208', label: 'new_sub_center'},
];

export const CommunityAdType = {
  'community': { value: 'community', label: '배너'},
};

export const CommunityAdTypeList = [
  { value: 'community', label: '배너'},
];

export const CommunityAdPositionLV1 = {
  '301': { value: '301', label: '전체페이지'},
  '302': { value: '302', label: '카페-알림장(X)'},
  '303': { value: '303', label: '카페-아웃도어(X)'},
  '304': { value: '304', label: '카페-미즈카페(X)'},
  '305': { value: '305', label: '카페-포토사랑(X)'},
  '306': { value: '306', label: '카페-골프(X)'},
  '307': { value: '307', label: '카페-음식남녀(X)'},
  '308': { value: '308', label: '카페-여행이야기(X)'},
  '309': { value: '309', label: '카페-컴닥터(X)'},
  '310': { value: '310', label: '전문가-이민(X)'},
  '311': { value: '311', label: '전문가-유학(X)'},
  '312': { value: '312', label: '전문가-조기유학(X)'},
  '313': { value: '313', label: '전문가-자동차(X)'},
  '314': { value: '314', label: '전문가-한방(X)'},
  '315': { value: '315', label: '전문가-약이야기(X)'},
};

export const CommunityAdPositionLV1List = [
  { value: '301', label: '커뮤니티-전체'},
  { value: '302', label: '카페-알림장(X)'},
  { value: '303', label: '카페-아웃도어(X)'},
  { value: '304', label: '카페-미즈카페(X)'},
  { value: '305', label: '카페-포토사랑(X)'},
  { value: '306', label: '카페-골프(X)'},
  { value: '307', label: '카페-음식남녀(X)'},
  { value: '308', label: '카페-여행이야기(X)'},
  { value: '309', label: '카페-컴닥터(X)'},
  { value: '310', label: '전문가-이민(X)'},
  { value: '311', label: '전문가-유학(X)'},
  { value: '312', label: '전문가-조기유학(X)'},
  { value: '313', label: '전문가-자동차(X)'},
  { value: '314', label: '전문가-한방(X)'},
  { value: '315', label: '전문가-약이야기(X)'},
];

export const CommunityAdPositionLV2 = {
  '301': { value: '301', label: '오른쪽'},
  '302': { value: '302', label: '컨텐츠상단'},
  '303': { value: '303', label: '컨텐츠하단'},
  '304': { value: '304', label: '로고-오른쪽'},
  '305': { value: '305', label: 'main_1'},
  '306': { value: '306', label: 'main_2'},
  '307': { value: '307', label: 'main_3'},
  '313': { value: '313', label: 'main_4'},
  '308': { value: '308', label: 'main_line(X)'},
  '309': { value: '309', label: 'main_bottom(X)'},
  '310': { value: '310', label: 'main_fly(X)'},
  '311': { value: '311', label: '팝업'},
  '312': { value: '312', label: 'main_flash(X)'},
};

export const CommunityAdPositionLV2List = [
  { value: '301', label: '오른쪽'},
  { value: '302', label: '컨텐츠상단'},
  { value: '303', label: '컨텐츠하단(X)'},
  { value: '304', label: '로고-오른쪽'},
  { value: '305', label: 'main_1'},
  { value: '306', label: 'main_2'},
  { value: '307', label: 'main_3'},
  { value: '313', label: 'main_4'},
  { value: '308', label: 'main_line(X)'},
  { value: '309', label: 'main_bottom(X)'},
  { value: '310', label: 'main_fly(X)'},
  { value: '311', label: '팝업'},
  { value: '312', label: 'main_flash(X)'},
];

export const YellowpageAdType = {
  'directory': { value: 'directory', label: '배너'},
};

export const YellowpageAdTypeList = [
  { value: 'directory', label: '배너'},
];

export const YellowpageAdPositionLV1 = {
  '401': { value: '401', label: '업소록 전체'},
};

export const YellowpageAdPositionLV1List = [
  { value: '401', label: '업소록 전체'},
];

export const YellowpageAdPositionLV2 = {
  '401': { value: '401', label: 'Right'},
  '402': { value: '402', label: 'Top1'},
  '403': { value: '403', label: 'Top2'},
  '404': { value: '404', label: '팝업'},
};

export const YellowpageAdPositionLV2List = [
  { value: '401', label: 'Right'},
  { value: '402', label: 'Top1'},
  { value: '403', label: 'Top2'},
  { value: '404', label: '팝업'},
];

export const MarketAdType = [
  { value: 'banner', label: '배너'},
  { value: 'line', label: '유료-줄'},
  { value: 'mobile', label: '모바일'},
  { value: 'flash', label: '플래쉬-줄'},
  { value: 'popup', label: '팝업'},
  { value: 'movie', label: '동영상'},
];

export const MarketAdBannerPosition = {
  'news_top1' :{ value: 'news_top1', label: '뉴스상단1(사용X)'},
  'top' :{ value: 'top', label: '상위(사용X)'},
  'top1' :{ value: 'top1', label: '상위1'},
  'top2' :{ value: 'top2', label: '상위2'},
  'right' :{ value: 'right', label: '오른쪽 '},
  'doong' :{ value: 'doong', label: '둥둥이(사용X)'},
  'doong1' :{ value: 'doong1', label: '둥둥이(상)개별'},
  'doong2' :{ value: 'doong2', label: '둥둥이(상)전체(X)'},
  'doong3' :{ value: 'doong3', label: '둥둥이(하)개별'},
  'doong4' :{ value: 'doong4', label: '둥둥이(하)전체(X)'},
  'inner' :{ value: 'inner', label: '게시물사이(사용X)'},
  'lflash' :{ value: 'lflash', label: '왼쪽플래쉬(사용X)'},
  'left' :{ value: 'left', label: '왼쪽1'},
  'left2' :{ value: 'left2', label: '왼쪽2'},
  'left3' :{ value: 'left3', label: '왼쪽3'},
  'center' :{ value: 'center', label: '장터중앙1'},
  'center2' :{ value: 'center2', label: '장터중앙2(사용X)'},
  'center3' :{ value: 'center3', label: '장터중앙3'},
  'bottom' :{ value: 'bottom', label: '하단(사용X)'},
  'tvcenter' :{ value: 'tvcenter', label: 'TV중앙(사용X)'},
  'tvright' :{ value: 'tvright', label: 'TV오른쪽(사용X)'},
  'tvleft' :{ value: 'tvleft', label: 'TV왼쪽(사용X)'},
  'tvdoong' :{ value: 'tvdoong', label: 'TV둥둥이(사용X)'},
  'imintop' :{ value: 'imintop', label: '이민탑'},
  'iminright' :{ value: 'iminright', label: '이민오른쪽'},
  'g_sellnbuy' :{ value: 'g_sellnbuy', label: '사고팔고'},
  'g_job' :{ value: 'g_job', label: '구인구직'},
  'g_tutor' :{ value: 'g_tutor', label: '학원/튜터'},
  'g_car' :{ value: 'g_car', label: '자동차매매'},
  'g_main' :{ value: 'g_main', label: '메인(X)'},
  'g_homestay' :{ value: 'g_homestay', label: '민박/홈스테이(X)'},
  'g_comm_center' :{ value: 'g_comm_center', label: '커뮤니티 센터 긴배너(X)'},
  'g_news_center' :{ value: 'g_news_center', label: '뉴스상세 긴배너(X)'},
  'g_tv_center' :{ value: 'g_tv_center', label: 'TV 긴배너(X)'},
  'g_realestate' :{ value: 'g_realestate', label: '부동산 전체(X)'},
}

export const MarketAdBannerPositionData = {
  '0': {
    value: '0',
    label: '메인',
    subList: [
      // { value: 'news_top1', label: '뉴스상단1(m사용X)'},
      // { value: 'top', label: '상위(사용X)'},
      { value: 'top1', label: '상위1'},
      { value: 'top2', label: '상위2'},
      { value: 'right', label: '오른쪽 '},
      // { value: 'doong', label: '둥둥이(사용X)'},
      { value: 'doong1', label: '둥둥이(상)개별'},
      // { value: 'doong2', label: '둥둥이(상)전체(X)'},
      { value: 'doong3', label: '둥둥이(하)개별'},
      // { value: 'doong4', label: '둥둥이(하)전체(X)'},
      // { value: 'inner', label: '게시물사이(사용X)'},
      // { value: 'lflash', label: '왼쪽플래쉬(사용X)'},
      { value: 'left', label: '왼쪽1'},
      { value: 'left2', label: '왼쪽2'},
      { value: 'left3', label: '왼쪽3'},
      { value: 'center', label: '장터중앙1'},
      // { value: 'center2', label: '장터중앙2(사용X)'},
      { value: 'center3', label: '장터중앙3'},
      // { value: 'bottom', label: '하단(사용X)'},
      // { value: 'tvcenter', label: 'TV중앙(사용X)'},
      // { value: 'tvright', label: 'TV오른쪽(사용X)'},
      // { value: 'tvleft', label: 'TV왼쪽(사용X)'},
      // { value: 'tvdoong', label: 'TV둥둥이(사용X)'},
    ]
  },
  '1': {
    value: '1',
    label: '사고팔고',
    subList: [
      { value: 'right', label: '오른쪽 '},
      { value: 'doong1', label: '둥둥이(상)개별'},
      { value: 'doong3', label: '둥둥이(하)개별'},
    ]
  },
  '7': {
    value: '7',
    label: '구인구직',
    subList: [
      { value: 'right', label: '오른쪽 '},
      { value: 'doong1', label: '둥둥이(상)개별'},
      { value: 'doong3', label: '둥둥이(하)개별'},
    ]
  },
  '6': {
    value: '6',
    label: '학원/튜터링',
    subList: [
      { value: 'right', label: '오른쪽 '},
      { value: 'doong1', label: '둥둥이(상)개별'},
      { value: 'doong3', label: '둥둥이(하)개별'},
    ]
  },
  '2': {
    value: '2',
    label: '자동차매매',
    subList: [
      { value: 'right', label: '오른쪽 '},
      { value: 'doong1', label: '둥둥이(상)개별'},
      { value: 'doong3', label: '둥둥이(하)개별'},
    ]
  },
  '30': {
    value: '30',
    label: '이민',
    subList: [
      { value: 'imintop', label: '상위'},
      { value: 'iminright', label: '오른쪽'},
    ]
  },
  '40': {
    value: '40',
    label: '목록-상단',
    subList: [
      { value: 'g_sellnbuy', label: '사고팔고'},
      { value: 'g_job', label: '구인구직'},
      { value: 'g_tutor', label: '학원/튜터링'},
      { value: 'g_car', label: '자동차매매'},
    ]
  },
  '5': {
    value: '5',
    label: '홈스테이(X)',
    subList: [
    ]
  },
  '9': {
    value: '9',
    label: '쿠폰존(X)',
    subList: []
  },
}

export const MarketAdLinePosition = {
  'line_buysell': { value: 'line_buysell', label: '사고팔고' },
  'line_tutor': { value: 'line_tutor', label: '학원/튜터' },
  'line_job': { value: 'line_job', label: '구인구직' },
  'line_car': { value: 'line_car', label: '자동차(x)' },
  'line_homestay': { value: 'line_homestay', label: '민박/홈스테이(x)' },
}

export const MarketAdLinePositionData = {
  '1': {
    value: '1',
    label: '사고팔고',
    subList: [
      { value: 'line_buysell', label: '사고팔고'},
    ]
  },
  '2': {
    value: '2',
    label: '자동차(x)',
    subList: [
    ]
  },
  '5': {
    value: '5',
    label: '민박/홈스테이(x)',
    subList: [
    ]
  },
  '6': {
    value: '6',
    label: '학원/튜터',
    subList: [
      { value: 'line_tutor', label: '학원/튜터'},
    ]
  },
  '7': {
    value: '7',
    label: '구인구직',
    subList: [
      { value: 'line_job', label: '구인구직'},
    ]
  },
}

export const MarketAdMobilePosition = {
  'news_top': { value: 'news_top', label: '뉴스상단(X)' },
  'news_top1': { value: 'news_top1', label: '뉴스상단1' },
  'news_top2': { value: 'news_top2', label: '뉴스상단2' },
  'news_top3': { value: 'news_top3', label: '뉴스상단3' },
  'news_middle1': { value: 'news_middle1', label: '뉴스중앙1' },
  'news_middle2': { value: 'news_middle2', label: '뉴스중앙2' },
  'news_special': { value: 'news_special', label: '뉴스특집' },
  'news_column': { value: 'news_column', label: '뉴스칼럼' },
  'news_bottom': { value: 'news_bottom', label: '뉴스하단' },
  'news_right_menu_da': { value: 'news_right_menu_da', label: '메뉴하단배너'},
  'market_top': { value: 'market_top', label: '장터상단(x)'},
  'market_middle': { value: 'market_middle', label: '장터중앙(x)'},
  'market_middle_one': { value: 'market_middle_one', label: '장터중앙단독(x)'},
  'market_bottom': { value: 'market_bottom', label: '장터하단(x)'},
  'market_popup': { value: 'market_popup', label: '장터팝업(x)'},
  'market_popup_ad': { value: 'market_popup_ad', label: '팝업배너'},
  'market_top_ra': { value: 'market_top_ra', label: 'Top-롤링배너'},
  'market_homebutton_da': { value: 'market_homebutton_da', label: '홈버튼-띠배너'},
  'market_community_da': { value: 'market_community_da', label: '커뮤니티-띠배너'},
  'market_recommend_ra': { value: 'market_recommend_ra', label: '추천-롤링배너'},
  'market_restaurant_ra': { value: 'market_restaurant_ra', label: '추천맛집-롤링배너'},
  'market_pick_da': { value: 'market_pick_da', label: '밴마켓 Pick-배너'},
  'market_tutor_da': { value: 'market_tutor_da', label: '학원-띠배너'},
  'market_car_da': { value: 'market_car_da', label: 'Bottom-띠배너'},
  'market_bar_ad': { value: 'market_bar_ad', label: '메뉴바-배너'},
  'market_right_menu_da': { value: 'market_right_menu_da', label: '메뉴하단배너'},
  'buysell_top': { value: 'buysell_top', label: '사고팔고-상단(x)'},
  'buysell_bottom': { value: 'buysell_bottom', label: '사고팔고-하단(x)'},
  'buysell_top_ra': { value: 'buysell_top_ra', label: 'TOP-롤링배너'},
  'jobs_top': { value: 'jobs_top', label: '구인구직-상단(x)'},
  'jobs_bottom': { value: 'jobs_bottom', label: '구인구직-하단(x)'},
  'job_top_ra': { value: 'job_top_ra', label: 'TOP-롤링배너'},
  'food_top': { value: 'food_top', label: '식당-상단(x)'},
  'food_bottom': { value: 'food_bottom', label: '식당-하단(x)'},
  'tutor_top': { value: 'tutor_top', label: '학원-상단(x)'},
  'tutor_bottom': { value: 'tutor_bottom', label: '학원-하단(x)'},
  'tutor_top_ra': { value: 'tutor_top_ra', label: 'TOP-롤링배너'},
  'car_top': { value: 'car_top', label: '자동차-상단(x)'},
  'car_bottom': { value: 'car_bottom', label: '자동차-하단(x)'},
  'car_top_ra': { value: 'car_top_ra', label: 'TOP-롤링배너'},
  'car_da': { value: 'car_da', label: '띠배너'},
  'homestay_top': { value: 'homestay_top', label: '홈스테이상단(x)'},
  'homestay_bottom': { value: 'homestay_bottom', label: '홈스테이하단(x)'},
  'rent_top': { value: 'rent_top', label: '렌트상단(x)'},
  'rent_bottom': { value: 'rent_bottom', label: '렌트하단(x)'},
  'sale_top': { value: 'sale_top', label: '주거매매상단(x)'},
  'sale_bottom': { value: 'sale_bottom', label: '주거매매하단(x)'},
  'saleb_top': { value: 'saleb_top', label: '비즈매매상단(x)'},
  'saleb_bottom': { value: 'saleb_bottom', label: '비즈매매하단(x)'},
  'renthomestay_top_ra': { value: 'renthomestay_top_ra', label: 'TOP-롤링배너'},
  'realtysale_top_ra': { value: 'realtysale_top_ra', label: 'TOP-롤링배너'},
  'community_top': { value: 'community_top', label: '커뮤니티상단(x)'},
  'community_bottom': { value: 'community_bottom', label: '커뮤니티하단(x)'},
  'community_top_ra': { value: 'community_top_ra', label: 'TOP-롤링배너'},
  'yellowpage_top_ra': { value: 'yellowpage_top_ra', label: 'TOP-롤링배너'},
};

export const MarketAdMobilePositionData = {
  'news': {
    value: 'news',
    label: '뉴스홈',
    subList : [
      { value: 'news_top1', label: '뉴스상단1'},
      { value: 'news_top2', label: '뉴스상단2'},
      { value: 'news_top3', label: '뉴스상단3'},
      { value: 'news_middle1', label: '뉴스중앙1'},
      { value: 'news_middle2', label: '뉴스중앙2'},
      { value: 'news_special', label: '뉴스특집'},
      { value: 'news_column', label: '뉴스칼럼'},
      { value: 'news_bottom', label: '뉴스하단'},
      { value: 'news_right_menu_da', label: '메뉴하단배너'},
    ],
  },
  'market': {
    value: 'market',
    label: '마켓홈',
    subList : [
      // { value: 'market_top', label: '장터상단(x)'},
      // { value: 'market_middle', label: '장터중앙(x)'},
      // { value: 'market_middle_one', label: '장터중앙단독(x)'},
      // { value: 'market_bottom', label: '장터하단(x)'},
      // { value: 'market_popup', label: '장터팝업(x)'},
      { value: 'market_popup_ad', label: '팝업배너'},
      { value: 'market_top_ra', label: 'Top-롤링배너'},
      { value: 'market_homebutton_da', label: '홈버튼-띠배너'},
      { value: 'market_community_da', label: '커뮤니티-띠배너'},
      { value: 'market_recommend_ra', label: '추천-롤링배너'},
      { value: 'market_restaurant_ra', label: '추천맛집-롤링배너'},
      { value: 'market_pick_da', label: '밴마켓 Pick-배너'},
      { value: 'market_tutor_da', label: '학원-띠배너'},
      { value: 'market_car_da', label: 'Bottom-띠배너'},
      { value: 'market_bar_ad', label: '메뉴바-배너'},
      { value: 'market_right_menu_da', label: '메뉴하단배너'},
    ],
  },
  'buysell': {
    value: 'buysell',
    label: '사고팔고',
    subList : [
      // { value: 'buysell_top', label: '사고팔고-상단(x)'},
      // { value: 'buysell_bottom', label: '사고팔고-하단(x)'},
      { value: 'buysell_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  'job': {
    value: 'job',
    label: '구인구직',
    subList : [
      // { value: 'jobs_top', label: '구인구직-상단(x)'},
      // { value: 'jobs_bottom', label: '구인구직-하단(x)'},
      { value: 'job_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  // 'food': {
  //   value: 'food',
  //   label: '식당(x)',
  //   subList : [
  //     { value: 'food_top', label: '식당-상단(x)'},
  //     { value: 'food_bottom', label: '식당-하단(x)'},
  //   ],
  // },
  'tutor': {
    value: 'tutor',
    label: '학원',
    subList : [
      // { value: 'tutor_top', label: '학원-상단(x)'},
      // { value: 'tutor_bottom', label: '학원-하단(x)'},
      { value: 'tutor_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  'car': {
    value: 'car',
    label: '자동차',
    subList : [
      // { value: 'car_top', label: '자동차-상단(x)'},
      // { value: 'car_bottom', label: '자동차-하단(x)'},
      { value: 'car_top_ra', label: 'TOP-롤링배너'},
      { value: 'car_da', label: '띠배너'},
    ],
  },
  // 'homestay': {
  //   value: 'homestay',
  //   label: '홈스테이(x)',
  //   subList : [
  //     { value: 'homestay_top', label: '홈스테이상단(x)'},
  //     { value: 'homestay_bottom', label: '홈스테이하단(x)'},
  //   ],
  // },
  // 'rent': {
  //   value: 'rent',
  //   label: '렌트(x)',
  //   subList : [
  //     { value: 'rent_top', label: '렌트상단(x)'},
  //     { value: 'rent_bottom', label: '렌트하단(x)'},
  //   ],
  // },
  // 'sale': {
  //   value: 'sale',
  //   label: '주거매매(x)',
  //   subList : [
  //     { value: 'sale_top', label: '주거매매상단(x)'},
  //     { value: 'sale_bottom', label: '주거매매하단(x)'},
  //   ],
  // },
  // 'saleb': {
  //   value: 'saleb',
  //   label: '비즈매매(x)',
  //   subList : [
  //     { value: 'saleb_top', label: '비즈매매상단(x)'},
  //     { value: 'saleb_bottom', label: '비즈매매하단(x)'},
  //   ],
  // },
  'renthomestay': {
    value: 'renthomestay',
    label: '렌트&민박',
    subList : [
      { value: 'renthomestay_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  'realtysale': {
    value: 'realtysale',
    label: '부동산',
    subList : [
      { value: 'realtysale_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  'community': {
    value: 'community',
    label: '커뮤니티',
    subList : [
      // { value: 'community_top', label: '커뮤니티상단(x)'},
      // { value: 'community_bottom', label: '커뮤니티하단(x)'},
      { value: 'community_top_ra', label: 'TOP-롤링배너'},
    ],
  },
  'yellowpage': {
    value: 'yellowpage',
    label: '업소록',
    subList : [
      { value: 'yellowpage_top_ra', label: 'TOP-롤링배너'},
    ],
  },
};

export const MarketAdFlashPosition = {
  'flash_buynsell': { value: 'flash_buynsell', label: '사고팔고' },
  'flash_job': { value: 'flash_job', label: '구인구직' },
  'flash_tutor': { value: 'flash_tutor', label: '학원/튜터' },
  'flash_car': { value: 'flash_car', label: '자동차(x)' },
  'flash_homestay': { value: 'flash_homestay', label: '민박/홈스테이(x)' },
  'flash_community': { value: 'flash_community', label: '커뮤니티' },
  'flash_realty': { value: 'flash_realty', label: '부동산(x)' },
  'flash_tv': { value: 'flash_tv', label: 'TV(x)' },
  'flash_new1': { value: 'flash_new1', label: '뉴스1(x)' },
  'flash_new2': { value: 'flash_new2', label: '뉴스2(x)' },
  'flash_new3': { value: 'flash_new3', label: '뉴스3(x)' },
  'flash_new4': { value: 'flash_new4', label: '뉴스4(x)' },
  'flash_new5': { value: 'flash_new5', label: '뉴스5(x)' },
}

export const MarketAdFlashPositionData = {
  'buynsell': {
    value: 'buynsell',
    label: '사고팔고',
    subList: [
      { value: 'flash_buynsell', label: '사고팔고'},
    ],
  },
  'job': {
    value: 'job',
    label: '구인구직',
    subList: [
      { value: 'flash_job', label: '구인구직'},
    ],
  },
  'tutor': {
    value: 'tutor',
    label: '학원/튜터',
    subList: [
      { value: 'flash_tutor', label: '학원/튜터'},
    ],
  },
  // 'car': {
  //   value: 'car',
  //   label: '자동차(x)',
  //   subList: [
  //     { value: 'flash_car', label: '자동차(x)'},
  //   ],
  // },
  // 'homestay': {
  //   value: 'homestay',
  //   label: '민박/홈스테이(x)',
  //   subList: [
  //     { value: 'flash_homestay', label: '민박/홈스테이(x)'},
  //   ],
  // },
  'community': {
    value: 'community',
    label: '커뮤니티',
    subList: [
      { value: 'flash_community', label: '커뮤니티'},
    ],
  },
  // 'realty': {
  //   value: 'realty',
  //   label: '부동산(x)',
  //   subList: [
  //     { value: 'flash_realty', label: '부동산(x)'},
  //   ],
  // },
  // 'tv': {
  //   value: 'tv',
  //   label: 'TV(x)',
  //   subList: [
  //     { value: 'flash_tv', label: 'TV(x)'},
  //   ],
  // },
  // 'new1': {
  //   value: 'new1',
  //   label: '뉴스1(x)',
  //   subList: [
  //     { value: 'flash_new1', label: '뉴스1(x)'},
  //   ],
  // },
  // 'new2': {
  //   value: 'new2',
  //   label: '뉴스2(x)',
  //   subList: [
  //     { value: 'flash_new2', label: '뉴스2(x)'},
  //   ],
  // },
  // 'new3': {
  //   value: 'new3',
  //   label: '뉴스3(x)',
  //   subList: [
  //     { value: 'flash_new3', label: '뉴스3(x)'},
  //   ],
  // },
  // 'new4': {
  //   value: 'new4',
  //   label: '뉴스4(x)',
  //   subList: [
  //     { value: 'flash_new4', label: '뉴스4(x)'},
  //   ],
  // },
  // 'new5': {
  //   value: 'new5',
  //   label: '뉴스5(x)',
  //   subList : [
  //     { value: 'flash_new5', label: '뉴스5(x)'},
  //   ],
  // },
};

export const MarketAdPopupPosition = {
  'main-popup1': { value: 'main-popup1', label: '메인-팝업'},
  'job-popup2': { value: 'job-popup2', label: '구인구직-팝업(x)'},
  'tutor-popup1': { value: 'tutor-popup1', label: '학원튜터링-팝업(x)'},
};

export const MarketAdPopupPositionData = {
  'main': {
    value: 'main',
    label: '메인',
    subList: [
      { value: 'main-popup1', label: '팝업'},
    ],
  },
  // 'job': {
  //   value: 'job',
  //   label: '구인구직',
  //   subList: [
  //     { value: 'job-popup1', label: '팝업'},
  //   ],
  // },
  // 'tutor': {
  //   value: 'tutor',
  //   label: '학원튜터링',
  //   subList: [
  //     { value: 'tutor-popup1', label: '팝업'},
  //   ],
  // },
};

export const MarketAdMoviePosition = {
  'movie-main': { value: 'movie-main', label: '메인'},
  'movie-buyselldetail': { value: 'movie-buyselldetail', label: '사고팔고상세'},
  'movie-job': { value: 'movie-job', label: '구인구직'},
  'movie-car': { value: 'movie-car', label: '자동차'},
};

export const MarketAdMoviePositionData = {
  'main': {
    value: 'main',
    label: '메인',
    subList: [
      { value: 'movie-main', label: '메인'},
    ],
  },
  'job': {
    value: 'job',
    label: '구인구직',
    subList: [
      { value: 'movie-job', label: '구인구직'},
    ],
  },
  'car': {
    value: 'car',
    label: '자동차',
    subList: [
      { value: 'movie-car', label: '자동차'},
    ],
  },
  'buysell': {
    value: 'buysell',
    label: '사고팔고',
    subList: [
      { value: 'movie-buyselldetail', label: '사고팔고상세'},
    ],
  },
};

export const NewsReadCodes = {
  '0': { value: '0', label: '모두-금지'},
  '1': { value: '1', label: '모두-읽기'},
  '2': { value: '2', label: 'Member-이상'},
  '3': { value: '3', label: 'Manager-이상'},
  '10': { value: '10', label: 'Admin-이상'},
};

export const NewsWriteCodes = {
  '0': { value: '0', label: '모두-금지'},
  '1': { value: '1', label: '모두-쓰기'},
  '2': { value: '2', label: 'Member-이상'},
  '3': { value: '3', label: 'Manager-이상'},
  '10': { value: '10', label: 'Admin-이상'},
};

export const ExportCategoryList = [
  { value: 1, boardId: 4, label: '교육' },
  { value: 2, boardId: 11, label: '유학' },
  { value: 3, boardId: 5, label: '부동산' },
  { value: 4, boardId: 6, label: '이민수속' },
  { value: 5, boardId: 7, label: '자동차' },
  { value: 6, boardId: 18, label: '회계' },
  { value: 7, boardId: 19, label: '한방' },
  { value: 8, boardId: 21, label: '이민정착' },
  { value: 9, boardId: 22, label: '피아노' },
  { value: 10, boardId:	23, label: '컴퓨터/IT' },
  { value: 11, boardId:	24, label: '모기지' },
  { value: 15, boardId:	31, label: '건축(설계/퍼밋/시공)' },
  { value: 13, boardId:	54, label: '수출입ㆍ통관' },
  { value: 14, boardId:	30, label: 'IELTS' },
  { value: 16, boardId:	32, label: '핸디맨' },
  { value: 17, boardId:	34, label: '이민법률' },
  { value: 18, boardId:	35, label: '골프' },
  { value: 19, boardId:	38, label: '온라인스쿨' },
  { value: 20, boardId:	37, label: '애완동물' },
  { value: 21, boardId:	51, label: '보험' },
  { value: 22, boardId:	57, label: '치과' },
  { value: 23, boardId:	64, label: '클리닉/의학' },
];

export const ExportCategories = {
  1: { value: 1, boardId: 4, label: '교육' },
  2: { value: 2, boardId: 11, label: '유학' },
  3: { value: 3, boardId: 5, label: '부동산' },
  4: { value: 4, boardId: 6, label: '이민수속' },
  5: { value: 5, boardId: 7, label: '자동차' },
  6: { value: 6, boardId: 18, label: '회계' },
  7: { value: 7, boardId: 19, label: '한방' },
  8: { value: 8, boardId: 21, label: '이민정착' },
  9: { value: 9, boardId: 22, label: '피아노' },
  10: { value: 10, boardId:	23, label: '컴퓨터/IT' },
  11: { value: 11, boardId:	24, label: '모기지' },
  15: { value: 15, boardId:	31, label: '건축(설계/퍼밋/시공)' },
  13: { value: 13, boardId:	54, label: '수출입ㆍ통관' },
  14: { value: 14, boardId:	30, label: 'IELTS' },
  16: { value: 16, boardId:	32, label: '핸디맨' },
  17: { value: 17, boardId:	34, label: '이민법률' },
  18: { value: 18, boardId:	35, label: '골프' },
  19: { value: 19, boardId:	38, label: '온라인스쿨' },
  20: { value: 20, boardId:	37, label: '애완동물' },
  21: { value: 21, boardId:	51, label: '보험' },
  22: { value: 22, boardId:	57, label: '치과' },
  23: { value: 23, boardId:	64, label: '클리닉/의학' },
}

export const ExportActiveType = {
  0: { value: 0, label: '비활성' },
  1: { value: 1, label: '활성' },
  2: { value: 2, label: '신청중' },
};

export const ExportOption2 = {
  '0': { value: '0', label: '일반' },
  '1': { value: '1', label: '프리미엄' },
};

export const PostActive = {
  '0': '닫기',
  '1': '열기',
  '2': '대기',
  '9': '삭제',
};

export const YNCodes = {
  'Y': { value: 'Y', label: 'Y'},
  'N': { value: 'N', label: 'N'},
};

export const Cities = {
  '5' :{ value: '5',  label: '밴쿠버', enName: 'Vancouver', },
  '9' :{ value: '9',  label: '리치몬드', enName: 'Vancouver', },
  '15':{ value: '15', label: '뉴웨스터민스터', enName: 'Vancouver', },
  '2' :{ value: '2',  label: '코퀴틀람', enName: 'Vancouver', },
  '1' :{ value: '1',  label: '버나비', enName: 'Vancouver', },
  '14':{ value: '14', label: '포트코퀴틀람', enName: 'Vancouver', },
  '3' :{ value: '3',  label: '포트무디', enName: 'Vancouver', },
  '4' :{ value: '4',  label: '써리', enName: 'Vancouver', },
  '11':{ value: '11', label: '랭리', enName: 'Vancouver', },
  '6' :{ value: '6',  label: '노스밴쿠버', enName: 'Vancouver', },
  '7' :{ value: '7',  label: '웨스트밴쿠버', enName: 'Vancouver', },
  '8' :{ value: '8',  label: '메이플릿지', enName: 'Vancouver', },
  '16':{ value: '16', label: '핏메도우', enName: 'Vancouver', },
  '10':{ value: '10', label: '화이트락', enName: 'Vancouver', },
  '12':{ value: '12', label: '델타', enName: 'Vancouver', },
  '19':{ value: '19', label: '아보츠포드', enName: 'Vancouver', },
  '13':{ value: '13', label: '기타', enName: 'Vancouver', },
  // { value: '17', label: '재스퍼', enName: 'Vancouver', },
  // { value: '20', label: '킬로나 ', enName: 'Vancouver', },
  // '5' :{ label: '밴쿠버',         value: 'Vancouver'},
  // '9' :{ label: '리치몬드',       value: 'Richmond'},
  // '15':{ label: '뉴웨스터민스터',  value: 'New Westminster'},
  // '2' :{ label: '코퀴틀람',       value: 'Coquitlam'},
  // '1' :{ label: '버나비',         value: 'Burnaby'},
  // '14':{ label: '포트코퀴틀람',   value: 'Port Coquitlam'},
  // '3' :{ label: '포트무디',       value: 'Port Moody'},
  // '4' :{ label: '써리',           value: 'Surrey'},
  // '11':{ label: '랭리',           value: 'Langley'},
  // '6' :{ label: '노스밴쿠버',      value: 'North Vancouver'},
  // '7' :{ label: '웨스트밴쿠버',    value: 'West Vancouver'},
  // '8' :{ label: '메이플릿지',      value: 'Maple Ridge'},
  // '16':{ label: '핏메도우',        value: 'Pitt Meadow'},
  // '10':{ label: '화이트락',        value: 'White Rock'},
  // '12':{ label: '델타',            value: 'Delta'},
  // '19':{ label: '아보츠포드',      value: 'Abbotsford'},
  // '17':{ label: '재스퍼',          value: 'Jasper'},
  // // '18':{ key:'18', label: 'Lower Mainland', value: 'Lower Mainland'},
  // '20':{ label: '킬로나 ',         value: 'Kelowna'},
  // '13':{ label: '기타',            value: 'Other'},
};
